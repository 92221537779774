import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Edit from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import PatientsAPI from '../../api/patients';
import { showMessage, showError, confirmAction, setCurrentScreen } from '../../actions/uiActions';
import PatientForm from '../../forms/Patient';
import LesionMap from '../../components/LesionMap';
import Lesion from '../../forms/Lesion';
import Patient from '../../forms/Patient';
import ImageZoom from '../../components/ImageZoom';
import LesionRecordTypeSelection from '../../components/LesionRecordTypeSelection';
import QRCodeDialog from '../../components/QRCodeDialog';
import Body from '../../components/Body';
import { printPhotoType } from '../../utils/Formatter';
import PandadocDownload from '../../components/PandadocDownload';

class PatientContainer extends Component {
  state =
    {
      mode: 'default',
      loading: true,
      patient: null,
      leftX: 0,
      leftY: 0,
      lesion: null,
      position: 'front',
      canvas_drawn: false,
      newLesion: null,
      imageIndex: 0,
      lesionRecords: [],
      record: null,
      deletingLesion: false,
      deletingRecord: false,
      deletingPatient: false,
      zoom: null,
      variant: 'all',
      selectRecordType: false,
      newRecordType: '',
      showQrCode: false,
      template: null,
    }

  componentWillMount() {
    this.props.setCurrentScreen('Patient Profile', true);
  }

  mapWidth = () => {
    if (window.innerWidth >= 1200) return 500;
    if (window.innerWidth >= 1024) return 450;
    if (window.innerWidth >= 768) return 350;
    return 300;
  }

  componentDidMount() {
    this.load(this.props.match.params.lesion ? this.props.match.params.lesion : '');
  }

  zoom = (photo) => {
    this.setState({ zoom: photo });
  }

  updateData = (data) => {

  }

  get hasPrevDiagramImage() {
    return this.state.imageIndex - 1 >= 0;
  }

  get hasNextDiagramImage() {
    return this.state.imageIndex + 3 < this.state.patient.lesions.length;
  }

  get DiagramImages() {
    return this.state.patient.lesions.slice(this.state.imageIndex, this.state.imageIndex + 3);
  }

  setLesion = (lesion) => {
    if (lesion) {
      let imageIndex = this.state.patient.lesions.indexOf(lesion);
      if (imageIndex - 3 < 0) imageIndex = 0;
      else if (imageIndex + 3 > this.state.patient.lesions.length) imageIndex = Math.max(0, this.state.patient.lesions.length - 3);
      this.setState({ lesion, position: lesion.region, record: null, lesionRecords: [], imageIndex });
      PatientsAPI.getLesion(lesion.id).then((records) => {
        const lesionRecords = [];
        records.forEach((record) => {
          if (lesionRecords.find((recordYear) => recordYear.year == record.year)) {
            lesionRecords[lesionRecords.indexOf(lesionRecords.find((recordYear) => recordYear.year == record.year))].records.push(record);
          } else {
            lesionRecords.push(
              {
                year: record.year,
                records: [record],
              },
            );
          }
          this.setState({ lesionRecords, record: lesionRecords[0].records[0] });
        });
      }, (error) => this.props.showError()).catch((error) => this.props.showError());
    }
  }

  load = (lesionId = '') => {
    this.setState({ loading: true });
    PatientsAPI.get(this.props.match.params.id).then((patient) => {
      this.setState({ loading: false, patient }, () => {
        this.setLesion(lesionId ? patient.lesions.find((lesion) => lesion.id == lesionId) : patient.lesions[0]);
        const canvas = this.refs.lesion_diagram;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          const img = this.refs.body_image;
          ctx.drawImage(img, 35, 0, 90, 300);
          this.setState({ canvas_drawn: true });
        }
      });
    }, (error) => {
      this.props.showError();
      this.props.history.goBack();
    }).catch((error) => {
      this.props.showError();
      this.props.history.goBack();
    });
  }

  rgbToHex(r, g, b) {
    if (r > 255 || g > 255 || b > 255) throw 'Invalid color component';
    return ((r << 16) | (g << 8) | b).toString(16);
  }

  dropLession = (e) => {
    if (this.state.busy) return;
    const x = ((this.state.leftX - 8) * 100) / e.target.clientWidth;
    const y = ((this.state.leftY - 8) * 100) / e.target.clientHeight;

    const ctx = this.refs.lesion_diagram.getContext('2d');
    const p = ctx.getImageData(this.state.leftX, this.state.leftY, 1, 1).data;
    const hex = `#${(`000000${this.rgbToHex(p[0], p[1], p[2])}`).slice(-6)}`;
    if (hex !== '#000000') this.setState({ newLesion: { x, y, position: this.state.position }, mode: 'create' });
  }

  deleteRecord = () => {
    this.props.confirmAction('You are going to delete the clicnical record permanently. Do you want to proceed?', () => {
      this.setState({ deletingRecord: true });
      PatientsAPI.deleteRecord(this.state.record.id).then((success) => {
        this.props.showMessage(`The ${this.state.record.type == 'pathology' ? 'pathology' : 'clinical'} record has been deleted`);
        this.setState({ deletingRecord: false });
        this.load(this.state.lesion.id);
      }, (error) => {
        this.setState({ deletingRecord: false });
        this.props.showError();
      }).catch((error) => {
        this.setState({ deletingRecord: false });
        this.props.showError();
      });
    }, 'Confim Record Deletion', 'Confirm', 'Cancel');
  }

  delete = () => {
    this.props.confirmAction('You are going to delete this patient and all its lesions history permanently. Do you want to proceed?', () => {
      this.setState({ deletingPatient: true });
      PatientsAPI.delete(this.state.patient.id).then((success) => {
        this.props.showMessage('The patient has been deleted');
        this.props.history.goBack();
      }, (error) => {
        this.setState({ deletingLesion: false });
        this.props.showError();
      }).catch((error) => {
        this.setState({ deletingLesion: false });
        this.props.showError();
      });
    }, 'Confim Patient Deletion', 'Confirm', 'Cancel');
  }

  deleteLesion = () => {
    this.props.confirmAction('You are going to delete this lesion and all its records permanently. Do you want to proceed?', () => {
      this.setState({ deletingLesion: true });
      PatientsAPI.deleteLesion(this.state.lesion.id).then((success) => {
        this.props.showMessage('The lesion has been deleted');
        this.setState({ deletingLesion: false });
        this.load();
      }, (error) => {
        this.setState({ deletingLesion: false });
        this.props.showError();
      }).catch((error) => {
        this.setState({ deletingLesion: false });
        this.props.showError();
      });
    }, 'Confim Lesion Deletion', 'Confirm', 'Cancel');
  }

  deleteConfirm = () => {
    this.props.confirmAction('Please indicate whether you want to delete the Current Record or the entire Lesion History', this.deleteRecord, 'Confirm Action', 'Delete Current Record', 'Delete Lesion History', this.deleteLesion);
  }

  getLesionNumber = (lesion = null) => {
    lesion = !lesion ? this.state.lesion : lesion;
    if (!lesion) return '';

    return `${lesion.id.toString().length}${lesion.id}${lesion.lesion_date || '1565760440'}`;
  }

  displayValue = (display, condition, match, value, fieldValue) => {
    switch (match) {
      case 'eq':
        if (typeof fieldValue === 'object' && typeof value === 'object') {
          return condition === 'and' ? display && !value.find((fValue) => !fieldValue.find((val) => val == fValue)) : display || !value.find((fValue) => !fieldValue.find((val) => val == fValue));
        }
        return condition === 'and' ? display && value == fieldValue : display || value == fieldValue;
      case 'neq':
        if (typeof fieldValue === 'object' && typeof value === 'object') {
          return condition === 'and' ? display && value.find((fValue) => !fieldValue.find((val) => val == fValue)) : display || value.find((fValue) => (!fieldValue.find((val) => val == fValue)));
        }
        return condition === 'and' ? display && value != fieldValue : display || value != fieldValue;
      case 'gt':
        return condition === 'and' ? display && value < fieldValue : display || value < fieldValue;
      case 'lt':
        return condition === 'and' ? display && value > fieldValue : display || value > fieldValue;
      case 'contains':
        return condition === 'and' ? display && fieldValue.includes(value) : display || fieldValue.includes(value);
      case 'ncontains':
        return condition === 'and' ? display && !fieldValue.includes(value) : display || !fieldValue.includes(value);
      default:
        return display;
    }
  }

  canShow = (field) => {
    if (!field.settings || !field.settings.rules || field.settings.rules.length === 0) {
      return true;
    }
    let display = true;
    field.settings.rules.forEach((rule) => {
      const ruleField = this.state.record.template.fields.find((f) => f.settings.id == rule.field);

      switch (ruleField.type) {
        case 'alpha':
        case 'email':
        case 'phone':
        case 'single-choice':
        case 'multiple-choice':
        case 'fitzpatrick':
        case 'tags':
        case 'number':
          display = this.displayValue(display, rule.condition, rule.match, rule.value, ruleField.value);
          break;
        case 'boolean':
          display = this.displayValue(display, rule.condition, rule.match, rule.value === 'yes', ruleField.value);
          break;
        case 'date':
          if (ruleField.value) {
            display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'YYYY-MM-DD').format('X'), moment(ruleField.value, 'YYYY-MM-DD').format('X'));
          } else {
            display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'YYYY-MM-DD').format('X'), 0);
          }

          break;
        case 'result':
          if (ruleField.value) {
            display = this.displayValue(display, rule.condition, rule.match, rule.value, (ruleField.value && (ruleField.value.result == 1 || ruleField.value.result.toLowerCase().includes('high'))) ? 1 : 0);
          }

          break;
      }
    });
    return display;
  }

  renderCustomRecord = () => {
    return [<ul className="clinical-record">
      {this.state.record.template.fields.filter((field) => field.type !== 'attachment' && field.type !== 'image' && field.type !== 'pandadoc' && field.type !== 'html' && this.canShow(field)).map((field, index) => <li key={`custom-field-${index}`}>
        <label>{field.name}:</label><b>{field.type !== 'long-text' && field.type !== 'multiple-choice' && ((field.type !== 'result' && field.value) || (field.type === 'result' && (field.value.drn)) || '-')}</b>
        {(field.type === 'long-text' || field.type === 'multiple-choice') && <p><b>{field.value || '-'}</b></p>}
      </li>)}
      {this.state.record.template.fields.filter((field) => field.type === 'pandadoc').map((field, index) => <li key={`pandadoc-field-${index}`}>
        <label>{field.name}:</label>
        <PandadocDownload recordId={this.state.record.id} {...field} />
      </li>)}
    </ul>, this.state.record.template.fields.filter((field) => field.value && field.type === 'attachment').map((field, index) => <div key={`attachment-${index}`}>
      <h6>{field.name}</h6>
      <ul className="attachments">
        {field.value.map((attachment, i) => <li key={`attachment-file-${i}`}><a href={attachment.file} target="_blank" rel="noopener noreferrer">{attachment.file_name}</a></li>)}
      </ul>
    </div>), this.state.record.template.fields.filter((field) => field.value && field.type === 'image').map((field, index) => <div key={`record-image-${index}`}><label>{field.name}</label><div className="row">

      {field.value.map((photo, i) => <div key={`photo-${i}`} className="col-6" style={{ marginBottom: '20px' }}>

        <div onClick={() => this.zoom(photo)} className="lesion-image-container">
          <img src={photo.url} />
          <div className="row">

            <div className="col-12">
              <b>Date</b>
              <p>{photo.date}</p>
            </div>
          </div>
        </div></div>)}
    </div>
    </div>)];
  }

  renderLesionRecord = () => {
    return <>{this.state.record.type != 'pathology' && <ul className="clinical-record">
      <li><label>Where was patient evaluated?:</label> <b>{!this.state.record.location ? 'UNKNWON' : (this.state.record.location === 'clinic' ? 'Clinic' : (this.state.record.location === 'tele-consult' ? 'Tele-Consult' : 'Other'))}</b>
      </li>
      <li><label>DermaSensor device result:</label> <b>{this.state.record.result ? this.state.record.result.result : 'UNKNOWN'}</b>
      </li>
      <li><label>Initial Impression:</label> <b>{this.state.record.diagnosis || 'UNKWNOWN'}</b>
      </li>
      <li>
        <label>Notes/Comments</label>
        <p><b>{this.state.record.notes || 'NONE'}</b></p>
      </li>
    </ul>}
      {this.state.record.type == 'pathology' && <ul className="clinical-record">
        <li><label>Extraction Method:</label> <b>{!this.state.record.location ? 'UNKNOWN' : (this.state.record.action === 'followup' ? 'MOHS' : (this.state.record.location === 'excise' ? 'Excise' : 'Biopsy'))}</b>
        </li>
        <li><label>Date of Extraction:</label> <b>{moment(this.state.record.biopsy_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</b>
        </li>
        <li><label>Results received date:</label> <b>{this.state.record.results_date || 'UNKNOWN'}</b>
        </li>
        <li>
          <label>Diagnosis</label>
          <b>{this.state.record.final_diagnosis ||
            'None'}</b>
        </li>

      </ul>
      }</>;
  }

  renderPhotos = () => {
    return this.state.record.photos.map((photo) => <div key={`photo-${photo.id}`} className="col-6" style={{ marginBottom: '20px' }}>
      <div onClick={() => this.zoom(photo)} className="lesion-image-container">
        <img src={photo.url} />
        <div className="row">
          <div className="col-6 offset-1">
            <b>Type</b>
            <p>{printPhotoType(photo.data.type, true)}</p>
          </div>
          <div className="col-5">
            <b>Date</b>
            <p>{photo.date}</p>
          </div>
        </div>
      </div>
    </div>);
  }

  renderAttachments = () => {
    return this.state.record && this.state.record.type == 'pathology' && this.state.record.attachments.length > 0 && <div className="col-12 margin-top20px">
      <h6>Attachments</h6>
      <ul className="attachments">
        {this.state.record.attachments.map((attachment, index) => <li key={`attachment-${index}`}><a href={attachment.file} target="_blank" rel="noopener noreferrer">{attachment.file_name}</a></li>)}
      </ul>
    </div>;
  }

  render() {
    const lesionBiopsed = !!this.state.lesionRecords.find((record) => record.records.find((recordInfo) => recordInfo.action == 'biopsy' || recordInfo.action == 'excise'));

    return <div>
      {this.state.showQrCode && <QRCodeDialog onClose={() => this.setState({ showQrCode: false })} title={`Lesion #${this.getLesionNumber()}`} value={this.getLesionNumber()} />}
      {this.state.zoom && <ImageZoom data={this.state.zoom.data} onClose={(update, data) => {
        if (update) {
          PatientsAPI.updateRecordImageData(this.state.record.id, this.state.zoom.id, data).then((success) => { }, (error) => console.log(error)).catch((error) => console.log(error));
          const newRecord = {
            ...this.state.record,
            photos: this.state.record.photos.map((photo) => {
              if (photo.id === this.state.zoom.id) {
                photo.data = data;
              }
              return photo;
            }),
          };

          this.setState({
            record: newRecord,
            lesionRecords: this.state.lesionRecords.map((lesionRecord) => {
              lesionRecord.records = lesionRecord.records.map((r) => {
                if (r.id === newRecord.id) {
                  return newRecord;
                }
                return r;
              });
              return lesionRecord;
            }),
            zoom: null,
          });
        } else this.setState({ zoom: null });
      }} image={this.state.zoom.url} />}
      {this.state.selectRecordType && <LesionRecordTypeSelection onSelection={(type, template = null) => this.setState({ variant: type, selectRecordType: false, template })} onClose={() => this.setState({ selectRecordType: false, mode: 'default', newLesion: null })} />}
      {(this.state.mode == 'create' || this.state.mode == 'edit' || this.state.mode == 'record') && !this.state.selectRecordType && <Lesion variant={this.state.variant || 'clinic'} template={this.state.template || (this.state.record && this.state.record.template) || null} onRecordUpdated={(record, completed) => {
        const newRecords = this.state.lesionRecords.map((lesionRecord) => {
          lesionRecord.records = lesionRecord.records.map((r) => {
            if (r.id === record.id) {
              return record;
            }
            return r;
          });
          return lesionRecord;
        });
        this.setState({
          record,
          lesionRecords: newRecords,
          patient: {
            ...this.state.patient,
            lesions: this.state.patient.lesions.map((lesion) => {
              if (lesion.id === this.state.lesion.id) {
                lesion.photo = record.photos.length > 0 ? record.photos[record.photos.length - 1].url : null;
              }
              return lesion;
            }),
          },
          showQrCode: completed,
        });
      }} onRecordAdded={(id, completed) => {
        this.setLesion(this.state.lesion);
        this.setState({ showQrCode: completed });
      }} onLesionAdded={(id, completed) => {
        this.load(id);
        this.setState({ showQrCode: completed });
      }} mode={this.state.mode} onClose={() => this.setState({ mode: 'default', newLesion: null })} id={this.props.match.params.id} record={this.state.record} lesion={this.state.mode == 'create' ? this.state.newLesion : this.state.lesion} />}
      {this.state.mode == 'patient' && <Patient edit={true} patient={this.state.patient} onClose={() => this.setState({ mode: 'default' })} onUpdate={(patient) => this.setState({ patient: { ...this.state.patient, firstname: patient.firstname, lastname: patient.lastname, phone: patient.phone } })} />}
      <div className="wide animated fadeIn"><div className="line-break"></div><div className="row"><div className="col-12">
        {!this.state.loading && <div className="card" style={{ border: 'none' }}>
          <header>

            <ul className="patient-info">
              <li><h1 style={{ color: '#333' }}>Patient Info {(this.state.deletingPatient && <div className="spinner-grow spinner-grow-sm text-primary" role="status"></div>) || [<Tooltip title="Edit Patient Info"><button className="tiny-edit" onClick={() => this.setState({ mode: 'patient' })}></button></Tooltip>, <Tooltip title="Delete Patient Info"><button className="tiny-delete" onClick={this.delete}></button></Tooltip>]}</h1></li>
              <li>
                <label>Firstname</label>
                {this.state.patient.firstname}
              </li>
              <li>
                <label>Lastname</label>
                {this.state.patient.lastname}
              </li>

            </ul>

          </header>

          <div className="row" style={{ border: 'solid 1px #CCC', paddingTop: '20px', paddingBottom: '20px', borderRadius: '5px' }}>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-margin">
              <h6>Lesions Diagram</h6>
              <p className="notes">
                Click/Tap on a lesion to view the records, or click/tap anywhere in the diagram to add a new lesion.
                    </p>
              <div className="row">
                <div className="col-12 text-center">
                  <Body controls={true} lesion={this.state.newLesion} viewOnly={true} onLesionAdded={(dot) => this.setState({ newLesion: dot, selectRecordType: true, mode: 'create' })} lesions={(this.state.newLesion ? [this.state.newLesion] : []).concat(this.state.patient.lesions.map((lesion) => {
                    const point = lesion.lesion_point;
                    point.lesion_id = lesion.id;
                    point.lesion = lesion;
                    point.id = lesion.id;
                    return point;
                  }))} activeDot={this.state.lesion ? this.state.lesion.id : null} onLesionSelected={(dot) => this.setLesion(dot.lesion)} />
                </div>
              </div>
              <div className="line-break"></div>
              {this.state.patient.lesions.length > 0 && [<h6 key={'images-title'} className="navigable">Diagram Images

                    <button className="next" onClick={() => this.setState({ imageIndex: this.state.imageIndex + 1 })} disabled={!this.hasNextDiagramImage}></button>
                <button className="prev" onClick={() => this.setState({ imageIndex: this.state.imageIndex - 1 })} disabled={!this.hasPrevDiagramImage}></button>
              </h6>,
              <ul key={'diagram-images'} className="diagram-images mobile-margin">
                {this.DiagramImages.map((lesion) => {
                  const icon = lesion.photo || lesion.thumbnail;
                  return <li key={`lesion-${lesion.id}`} onClick={() => this.setLesion(lesion)} className={this.state.lesion && lesion.id === this.state.lesion.id ? 'active' : ''}>
                    <div className="image-container">
                      {icon && <img src={icon} />}
                    </div>
                    <h1>
                      Lesion #
                      <button className="link" style={{ color: lesionBiopsed ? 'red' : 'default', fontWeight: lesionBiopsed ? 'bold' : 'default' }} onClick={() => this.setState({ showQrCode: true })}>
                        {this.getLesionNumber(lesion)}
                      </button>
                    </h1>
                    {lesion.location}
                    <div className="date">
                      <div>Last Update</div>
                      {lesion.last_update}
                    </div>

                  </li>;
                })}

              </ul>]}
            </div>
            {this.state.patient.lesions.length === 0 && <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <h4>Register a Lesion</h4>
              <p>Click/tap on the location where the patient has a lesion in order to add a new record and view the patient lesion history.</p>
              <p>Once you register a lesion, you'll be able to see here the lesion history of the patient.</p>
            </div>}
            {this.state.patient.lesions.length > 0 && this.state.lesion && <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <h6 className="navigable">Lesion History <Tooltip title="Add Record"><Button onClick={() => {
                this.setState({ mode: 'record', selectRecordType: true });
              }}><AddIcon />Add Record</Button></Tooltip></h6>
              <div className="lesion-title">
                Lesion #<button style={{ color: lesionBiopsed ? 'red' : 'default', fontWeight: lesionBiopsed ? 'bold' : 'default' }} className="link" onClick={() => this.setState({ showQrCode: true })}>{this.getLesionNumber()}</button> {(this.state.deletingLesion && <div className="spinner-grow spinner-grow-sm text-primary" role="status"></div>) || <Tooltip title="Delete Lesion"><button onClick={this.deleteConfirm} className="tiny-delete"></button></Tooltip>} | {this.state.lesion.location}
                {this.state.lesionRecords.length > 0 && <ul className="lesion-records">

                  {this.state.lesionRecords.map((records) => <li onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ record: records.records[0] });
                  }} key={records.year} className={records.year == this.state.record.year ? 'active' : ''}>
                    <b>{records.year}</b>
                    <ol>
                      {records.records.map((record) => <li onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ record });
                      }} key={record.id} className={record.id == this.state.record.id ? 'current' : ''}>{record.date}</li>)}
                    </ol>
                  </li>)}
                </ul>}
              </div>
              <div className="line-break"></div>
              {!this.state.record && <div>
                <p className="notes text-center">Loading lesion records...</p>
                <LinearProgress />
              </div>}
              {this.state.record && <div className="row">
                <div className="col-12">
                  <h6>{(this.state.record.template && this.state.record.template.name) || (this.state.record.type == 'pathology' ? 'Pathology Record' : 'Clinical Record')} {(this.state.deletingRecord && <div className="spinner-grow spinner-grow-sm text-primary" role="status"></div>) || [<Tooltip title="Edit Record"><button onClick={() => this.setState({ mode: 'edit', variant: this.state.record.type })} className="tiny-edit"></button></Tooltip>]}</h6>

                  {(this.state.record.template && this.state.record.template.fields.length > 0) ? this.renderCustomRecord() : this.renderLesionRecord()}
                </div>
                <div className="line-break"></div>
                {this.renderPhotos()}

                {this.renderAttachments()}

              </div>}

            </div>}
          </div>
        </div>}
        {this.state.loading && <div className="row"><div className="col-6 offset-3">
          <p className="text-center">Loading patient information...</p>
          <LinearProgress /></div></div>}
      </div></div></div>
      <img ref="body_image" src="/assets/images/body.svg" style={{ display: 'none' }} />
      <div className="line-break"></div>
    </div>;
  }
}
const mapStateToProps = (state) => {
  return state;
};
PatientContainer.propTypes =
{
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps, { showMessage, showError, confirmAction, setCurrentScreen })(PatientContainer));
