import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import Cancel from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
import { showMessage, showError } from '../../actions/uiActions';
import { isValidEmail } from '../../utils/Validators';

class PandadocSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pandadoc_api_key: props.pandadoc_api_key || '',
      pandadoc_template_id: props.pandadoc_template_id || '',
      pandadoc_folder_id: props.pandadoc_folder_id || '',
      pandadoc_representative_email: props.pandadoc_representative_email || '',
    };
  }

    save=() => {
      if (!this.state.pandadoc_api_key) {
        return this.props.showError('Please enter Pandadoc API Key.');
      } if (!this.state.pandadoc_template_id) {
        return this.props.showError('Please enter Pandadoc Template ID.');
      } if (!this.state.pandadoc_folder_id) {
        return this.props.showError('Please enter Pandadoc Folder ID.');
      } if (this.state.pandadoc_representative_email && !isValidEmail(this.state.pandadoc_representative_email)) {
        return this.props.showError('Please enter a valid email address for the representative role.');
      }
      return this.props.onConfirm(this.state);
    }

    render() {
      return <Dialog maxWidth="xs" fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" open={true}
        >
        <DialogTitle id="alert-dialog-title">Pandadoc eSign Settings
        <IconButton onClick={this.props.onClose} className="pull-right">
          <Cancel />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                <div className="form-group nice">
                    <label>Pandadoc API Key</label>
                    <input type="text" className="form-control" value={this.state.pandadoc_api_key} placeholder="Enter Pandadoc API Key" onChange={(e) => this.setState({ pandadoc_api_key: e.target.value })} />
                </div>
                <div className="form-group nice">
                    <label>Pandadoc Template ID</label>
                    <input type="text" className="form-control" value={this.state.pandadoc_template_id} placeholder="Enter Pandadoc Template ID" onChange={(e) => this.setState({ pandadoc_template_id: e.target.value })} />
                </div>
                <div className="form-group nice">
                    <label>Pandadoc Folder ID</label>
                    <input type="text" className="form-control" value={this.state.pandadoc_folder_id} placeholder="Enter Pandadoc Folder ID" onChange={(e) => this.setState({ pandadoc_folder_id: e.target.value })} />
                </div>
                <div className="form-group nice">
                    <label>Representative Role Email Address (optional)</label>
                    <input type="email" className="form-control" value={this.state.pandadoc_representative_email} placeholder="Enter Email Address" onChange={(e) => this.setState({ pandadoc_representative_email: e.target.value })} />
                </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.save} color="primary" autoFocus>
            Save
          </Button>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
        </Dialog>;
    }
}

export default connect(null, { showMessage, showError })(PandadocSettings);
