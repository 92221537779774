import { LOGOUT, AUTHENTICATION_SUCCESS, AUTHENTICATE, AUTHENTICATION_FAILED } from '../actions/types';

const initialState =
{
  logged: false,
  auth_failed: false,
  account: null,
  resetting_password: false,
  reset_error: false,
  reset_success: false,
  authenticating: true,
  mode: 'default',
  reset_request_error: false,
  reset_request_success: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        account: null,
        mode: 'default',
        logged: false,
        auth_failed: false,
        authenticating: false,
        reset_request_error: false,
        reset_request_success: false,
      };
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        mode: 'default',
        logged: true,
        auth_failed: false,
        account: action.payload,
        authenticating: false,
      };
    case AUTHENTICATE:
      return {
        ...state,
        logged: false,
        auth_failed: false,
        account: null,
      };
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        logged: false,
        auth_failed: true,
        account: null,
      };
    default:
      return state;
  }
}
