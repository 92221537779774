import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { connect } from 'react-redux';
import { showMessage, showError } from '../../actions/uiActions';
import IndicatorButton from '../../components/IndicatorButton';
import PatientsApi from '../../api/patients';

const ExportPatients = (props) => {
  const [busy, setBusy] = useState(false);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const download = async () => {
    if (!start || !moment(start, 'YYYY-MM-DD').isValid()) {
      return props.showError('Please enter a valid start date.');
    } if (!end || !moment(end, 'YYYY-MM-DD').isValid()) {
      return props.showError('Please enter an end date.');
    } if (moment(start, 'YYYY-MM-DD').format('X') > moment(end, 'YYYY-MM-DD').format('X')) {
      return props.showError('Start date cannot be after end date.');
    }
    setBusy(true);
    try {
      await PatientsApi.exportPatients(start, end);
      props.onClose();
    } catch (error) {
      props.showError('There has been an error exporting the list of patients, please try again.');
    } finally {
      setBusy(false);
    }
  };

  return <Dialog fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">
            Export Patients list to CSV
        </DialogTitle>
        <DialogContent>
            <div className="row">
                <div className="col-6">
                <div className="form-group nice">
                    <label>Start Date</label>
                    <input value={start} onChange={(e) => setStart(e.target.value)} disabled={busy} type="date" className="form-control" />
                </div>
                </div>
                <div className="col-6">
                <div className="form-group nice">
                    <label>End Date</label>
                    <input value={end} onChange={(e) => setEnd(e.target.value)} disabled={busy} type="date" className="form-control" />
                </div>
                </div>
            </div>
        </DialogContent>
        {<DialogActions style={{ display: 'flex', paddingBottom: '30px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <IndicatorButton disabled={busy} variant="contained" onClick={download} className="btn-material btn-primary" autoFocus>
            Download CSV
          </IndicatorButton>
          {!busy && <Button variant="outlined" onClick={props.onClose} color="primary">
            Cancel
          </Button>}

        </DialogActions>}
        </Dialog>;
};
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, { showMessage, showError })(ExportPatients);
