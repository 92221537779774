import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import keycode from 'keycode';
import { withStyles } from '@material-ui/core/styles';

function renderInput(inputProps, keyDown) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
      <TextField
        onKeyUp={keyDown}
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
      <MenuItem
        {...itemProps}
        key={suggestion.label}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.label}
      </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

class AutoComplete extends Component {
    state = {
      inputValue: '',
      selectedItem: [],
      suggestions: [],
    };

    componentWillMount() {
      if (this.props.value && this.props.value.length) this.setState({ selectedItem: this.props.value });
    }

    componentWillReceiveProps(newProps) {
      if (newProps.value && newProps.value.length) this.setState({ selectedItem: newProps.value });
    }

      getSuggestions=(value) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0
          ? []
          : this.state.suggestions.filter((suggestion) => {
            const keep =
                count < 5 && suggestion.label.toLowerCase().includes(value.toLowerCase()) && !this.state.selectedItem.includes(suggestion.label);

            if (keep) {
              count += 1;
            }

            return keep;
          });
      }

      handleKeyDown = (event) => {
        const { inputValue, selectedItem } = this.state;
        if (selectedItem.length && !inputValue.length && keycode(event) === 'backspace') {
          this.setState({
            selectedItem: selectedItem.slice(0, selectedItem.length - 1),
          });
        }
      };

      handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
        if (this.props.autocomplete) {
          this.props.autocomplete(event.target.value).then((suggestions) => {
            this.setState({ suggestions });
          });
        }
      };

      handleEnter= (event) => {
        if (this.props.editable && event.keyCode === 13 && event.target.value) this.handleChange(event.target.value);
      }

      handleChange = (item) => {
        let { selectedItem } = this.state;

        if (selectedItem.indexOf(item) === -1) {
          selectedItem = [...selectedItem, item];
          this.props.onSelection(item);
        }

        this.setState({
          inputValue: '',
          selectedItem,
        });
      };

      handleDelete = (item) => () => {
        this.setState((state) => {
          const selectedItem = [...state.selectedItem];
          selectedItem.splice(selectedItem.indexOf(item), 1);
          this.props.onDelete(item);
          return { selectedItem };
        });
      };

      render() {
        const { classes } = this.props;
        const { inputValue, selectedItem } = this.state;

        return (
          <Downshift
            id="downshift-multiple"
            inputValue={inputValue}
            onChange={this.handleChange}
            selectedItem={selectedItem}
          >
            {({
              getInputProps,
              getItemProps,
              isOpen,
              inputValue: inputValue2,
              selectedItem: selectedItem2,
              highlightedIndex,
            }) => (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  InputProps: getInputProps({
                    startAdornment: selectedItem.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        className={classes.chip}
                        onDelete={this.handleDelete(item)}
                      />
                    )),
                    onChange: this.handleInputChange,
                    onKeyDown: this.handleKeyDown,
                    placeholder: this.props.placeholder,
                  }),
                  label: this.props.label,
                }, this.handleEnter)}
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {this.getSuggestions(inputValue2).map((suggestion, index) => renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.label }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                    }))}
                  </Paper>
                ) : null}
              </div>
            )}
          </Downshift>
        );
      }
}
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});
AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AutoComplete);
