import { CURRENT_SCREEN, PAGE_LOADING, SHOW_MESSAGE, HIDE_MESSAGE, CONFIRM_ACTION, ACTION_CONFIRMED_OR_CANCELED } from '../actions/types';

const initialState =
{
  message: '',
  title: '',
  text: '',
  ok: '',
  cancel: '',
  loading: false,
  screen: 'Capture Lesion',
  back: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CURRENT_SCREEN:
      return {
        ...state,
        ...action.payload,
      };
    case PAGE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SHOW_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case HIDE_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case CONFIRM_ACTION:
      return {
        ...state,
        title: action.payload.title,
        text: action.payload.text,
        ok: action.payload.ok,
        cancel: action.payload.cancel,
        onConfirm: action.payload.onConfirm,
        onCancel: action.payload.onCancel,
      };
    case ACTION_CONFIRMED_OR_CANCELED:
      return {
        ...state,
        title: '',
        text: '',
        ok: '',
        cancel: '',
        onConfirm: null,
        onCancel: null,
      };
    default:
      return state;
  }
}
