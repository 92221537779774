// Auth action types
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATING = 'AUTHENTICATING';
// UI Action types
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const CONFIRM_ACTION = 'CONFIRM_ACTION';
export const ACTION_CONFIRMED_OR_CANCELED = 'ACTION_CONFIRMED_OR_CANCELED';
export const PAGE_LOADING = 'PAGE_LOADING';
export const CURRENT_SCREEN = 'CURRENT_SCREEN';
