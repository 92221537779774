import React, { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ZoomIn, ZoomOut, ZoomOutMap } from '@material-ui/icons';

const ImageViewer = (props) => {
  return <TransformWrapper
    defaultScale={1}
    defaultPositionX={0}
    defaultPositionY={0}
    limitToBounds={true}
    limitToWrapper={true}
    centerContent={true}
  >
    {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
      if (props.disabled) {
        try {
          resetTransform();
        } catch (error) {}
      }
      return <React.Fragment>
        {!props.disabled && <div className="image-tools">
          <button onClick={zoomIn}><ZoomIn /></button>
          <button onClick={zoomOut}><ZoomOut /></button>
          <button onClick={resetTransform}><ZoomOutMap /></button>
        </div>}

        <div style={{ width: '500px' }} className="image-view-container">
        <TransformComponent>
          <img src={props.src} />
        </TransformComponent>
        </div>

      </React.Fragment>;
    }}
  </TransformWrapper>;
};
export default ImageViewer;
