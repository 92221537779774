import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Delete, Edit, FileCopy, CloudDownload } from '@material-ui/icons';
import { CircularProgress, IconButton } from '@material-ui/core';
import TemplateForm from '../../forms/Template';
import PaginatedTable from '../../components/PaginatedTable';
import { showMessage, showError, confirmAction, setCurrentScreen } from '../../actions/uiActions';
import TemplatesAPI from '../../api/templates';
import ExportRecord from '../../forms/ExportRecord';

class Templates extends Component {
    state=
    {
      mode: 'default',
      loading: true,
      templates: [],
      count: 1,
      page: 0,
      search: '',
      template: null,
      copy: null,
    }

    componentWillMount() {
      this.props.setCurrentScreen('Record Templates List', false);
      this.refresh();
    }

    refresh=() => {
      TemplatesAPI.load(this.state.page, this.state.search).then((result) => {
        this.setState({ loading: false, templates: result.templates, count: result.count, page: result.page });
      }, (error) => {
        this.props.showError();
        this.setState({ loading: false, templates: [], count: 0, page: 0 });
      }).catch((error) => {
        this.props.showError();
        this.setState({ loading: false, templates: [], count: 0, page: 0 });
      });
    }

    delete=(template, confirm = true) => {
      if (confirm) {
        return this.props.confirmAction(`You are going to delete the record template permanently.${template.records > 0 ? 'Please take into account that there are patient records using this template that will also be deleted which could lead to data loss or corruption.' : ''} Do you want to proceed?`, () => this.delete(template, false), 'Confirm Template Deletion', 'Delete Template');
      }
      this.setState({
        templates: this.state.templates.map((t) => {
          if (t.id === template.id) {
            t.deleting = true;
          }
          return t;
        }),
      }, async () => {
        try {
          await TemplatesAPI.delete(template.id);
          this.props.showMessage('The template has been deleted.');
          return this.refresh();
        } catch (error) {
          this.setState({
            templates: this.state.templates.map((t) => {
              if (t.id === template.id) {
                t.deleting = false;
              }
              return t;
            }),
          }, this.props.showError);
        }
      });
    }

    copy=(template) => {
      this.setState({ mode: 'add', copy: template });
    }

    render() {
      return <div>{(this.state.mode == 'add' || this.state.mode === 'edit') && <TemplateForm template={this.state.template} copy={this.state.copy} edit={this.state.template !== null} onClose={() => this.setState({ mode: 'default', template: null, copy: null })} onNewTemplate={() => {
        this.refresh();
        this.setState({ mode: 'default', template: null, copy: null });
      }} />}
        {this.state.mode === 'export' && <ExportRecord onClose={() => this.setState({ mode: 'default', template: null })} template={this.state.template} />}
        <section className="wide animated fadeIn">
        <Tooltip title="Create New Template" placement="top" className="floating-button">
        <Fab onClick={() => this.setState({ mode: 'add' })} color="secondary" aria-label="Add" >
            <AddIcon />
        </Fab>
        </Tooltip><div className="line-break"></div>
        <div className="row padding"><div className="col-12"><Paper><PaginatedTable className="light" loading={this.state.loading} columns={[{ name: 'Template Name', properties: { key: 1 } }, { name: 'Template Fields', properties: { key: 2, className: 'text-center' } }, { name: 'Records', properties: { key: 2, className: 'text-center' } }, { name: 'Options', properties: { key: 2, className: 'text-center' } }]} rows={this.state.templates.map((template) => {
          return {
            properties:
                    {
                      key: template.id,
                    },
            columns: [{ value: template.template, properties: { key: `${template.id}-1` } }, { value: template.fields, properties: { key: `${template.id}-2`, className: 'text-center' } }, { value: template.records, properties: { key: `${template.id}-3`, className: 'text-center' } }, { value: <div>
                        {!template.deleting ? <>
                            <Tooltip title="Export Records to CSV">
                        <IconButton onClick={() => this.setState({ mode: 'export', template })}>
                            <CloudDownload />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Use as a template">
                            <IconButton onClick={() => this.copy(template)}>
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Template">
                        <IconButton onClick={() => this.setState({ mode: 'edit', template })}>
                            <Edit />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Template">
                        <IconButton onClick={() => this.delete(template)}>
                            <Delete />
                        </IconButton>
                        </Tooltip></> : <CircularProgress size={16} />}
                    </div>,
            properties: { key: `${template.id}-4`, className: 'text-center' } }],
          };
        })} emptyMessage="No templates have been created" count={this.state.count} page={this.state.page} /></Paper></div>
            <div className="line-break"></div></div>
            </section></div>;
    }
}
const mapStateToProps = (state) => {
  return state;
};
Templates.propTypes =
    {
      showMessage: PropTypes.func.isRequired,
      showError: PropTypes.func.isRequired,
      confirmAction: PropTypes.func.isRequired,
    };
export default withRouter(connect(mapStateToProps, { showMessage, showError, setCurrentScreen, confirmAction })(Templates));
