export default {
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
  // environment: 'development',
  // environment: 'production',

  api_endpoint__development: 'http://localhost:5004',
  api_endpoint__production: 'https://companion.dermasensor.com',
  api_endpoint__stage: 'http://34.204.201.249:4150',

  freshchat_token__development: 'bb703365-fadc-4db1-8a1d-06d2e012f499',
  freshchat_token__production: 'bb703365-fadc-4db1-8a1d-06d2e012f499',
  freshchat_token__stage: 'bb703365-fadc-4db1-8a1d-06d2e012f499',

  accounts_app_url__development: 'http://localhost:3000',
  accounts_app_url__production: 'https://accounts.dermasensor.com',
  accounts_app_url__stage: 'http://ds-stage-account-app.s3-website-us-east-1.amazonaws.com',

  app_public_key__development: '637e0492a99149d3fc591543491698',
  app_public_key__production: '00a20403d83b0e74f0771555465825364',
  app_public_key__stage: '4444',

  app_url__development: 'http://localhost:3001',
  app_url__production: 'https://app.dermasensor.com',
  app_url__stage: 'http://ds-stage-patients-app.s3-website-us-east-1.amazonaws.com',

  get(key) {
    return this[`${key}__${this.environment}`];
  },
};
