import Http from '../utils/Http';
import Auth from './auth';

export default {
  load(page = 0, from = '', to = '', device = 0, search) {
    return new Promise((resolve, reject) => {
      Http.get(`/scans/${page}/${from || '0'}/${to || '0'}/${device || '0'}?search=${search}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  all() {
    return new Promise((resolve, reject) => {
      Http.get('/devices').then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
};
