import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmAction = (props) => <Dialog
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description" open={true}
>
<DialogTitle id="alert-dialog-title">{props.title || 'Confirm Action'}</DialogTitle>
<DialogContent>
  <DialogContentText id="alert-dialog-description">
    {props.children}
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button variant="outlined" onClick={props.onConfirm} color="primary" autoFocus>
    {props.confirm || 'Confirm'}
  </Button>
  <Button onClick={props.onCancel} color="primary">
    {props.cancel || 'Cancel'}
  </Button>
</DialogActions>
</Dialog>;

export default ConfirmAction;
