import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

const WysiwygEditor = (props) => {
  const editor = useRef(null);

  return <JoditEditor
    ref={editor}
    value={props.value}
    readonly={props.disabled}
    config={{
      height: props.height || 400,
      minHeight: props.minHeight || 400,
    }}
    tabIndex={1} // tabIndex of textarea
    onBlur={props.onChange} // preferred to use only this option to update the content for performance reasons
    onChange={() => {}}
    />;
};
export default WysiwygEditor;
