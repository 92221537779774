export const isValidEmail = (email) => {
  return !!email.match(/^[a-z0-9A-Z]+[a-zA-Z0-9.\-_]*@[a-zA-Z0-9.\-_]+.[a-z0-9A-Z]{2,}$/);
};
export const isValidURL = (url) => {
  return !!url.match(/^http(s)?:\/\/[a-zA-Z0-9-]+.*\.[a-zA-Z0-9]+$/);
};
export const isValidPassword = (password) => {
  return !!((password.length >= 8 && password.match(/[A-Z]+/) && password.match(/[0-9]+/) && password.match(/[a-z]+/)));
};
export const isAlphaNumeric = (text) => {
  return !!text.match(/^[a-zA-Z0-9-_]+$/);
};
export const isValidIP = (ip) => {
  return !!ip.match(/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(,[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})*/);
};
export const isValidPhone = (phone) => {
  return phone.toString().length >= 10 && phone.toString().match(/^[0-9]+$/);
};
export const isValidRegExp = (regExp) => {
  try {
    const exp = new RegExp(regExp);
    return true;
  } catch (error) {
    return false;
  }
};
export const hasMinLength = (text, length) => {
  return text.length >= length;
};
export const isInteger = (number) => {
  return !!(typeof number !== 'undefined' && number != null && number.toString().match(/^[0-9]+$/));
};
export const isCurrency = (number) => {
  return parseFloat(number.toString()) && number >= 0;
};
export const isDateInput = (date, minYear = 1900) => {
  if (!date) return false;
  if (date.toString().match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
    const dateInfo = date.toString().split('-');
    if (parseInt(dateInfo[1]) > 0 && dateInfo[1] <= 12 && parseInt(dateInfo[2]) > 0 && parseInt(dateInfo[2]) <= 31 && parseInt(dateInfo[0]) >= minYear) return true;
    return false;
  }
  return false;
};
export const isValidFormula = (formula, variables) => {
  let finalFormula = formula;
  variables.forEach((variable) => finalFormula = finalFormula.replace(new RegExp(variable, 'g'), 0));
  try {
    const result = eval(finalFormula);
    return result != NaN;
  } catch (exception) {
    return false;
  }
};
export default
{
  isValidEmail,
  isValidPassword,
  hasMinLength,
  isValidPhone,
  isValidURL,
  isValidIP,
  isInteger,
  isCurrency,
  isValidRegExp,
  isAlphaNumeric,
  isDateInput,
  isValidFormula,
};
