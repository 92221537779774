import React, { Component } from 'react';
import QRCode from 'react-qr-code';
import ReactToPrint from 'react-to-print';
import DialogComponent from '../DialogComponent';

class QRCodeDialog extends Component {
  render() {
    return <DialogComponent onClose={this.props.onClose} fullWidth={true} title={this.props.title}>
        <div className="row">
            <div className="col-12 text-center">
            <QRCode ref={(el) => (this.componentRef = el)} value={this.props.value} size={256} />,
            </div>

        </div>
        <div className="line-break"></div>
        <ReactToPrint
          trigger={() => <button className="btn btn-primary btn-block">Print QR Code</button>}
          content={() => this.componentRef}
        />
        </DialogComponent>;
  }
}
export default QRCodeDialog;
