import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

const DialogComponent = (props) => {
  return <Dialog fullWidth={props.fullWidth || false} fullScreen={props.fullScreen || false} open={true} size={props.size || 'sm'}>
    <DialogTitle>{props.title}
    {!props.disabled && <button className="dialog-close" onClick={props.onClose}>Cancel</button>}
    </DialogTitle>
    <DialogContent>
    {props.children}
    </DialogContent>
    <DialogActions>
        {props.actions && props.actions.map((action, index) => <Button key={`action-${index}`} variant={action.default ? 'outlined' : 'default'} onClick={action.onClick} color={action.default ? 'primary' : 'default'}>
    {action.title}
  </Button>)}
    </DialogActions>
    </Dialog>;
};
export default DialogComponent;
