import FileDownload from 'js-file-download';
import Http from '../utils/Http';
import Auth from './auth';

export default {
  exportPatients: async (start, end) => {
    try {
      // const result = await Http.download(`/patients/export/${start}/${end}`);
      const result = await Http.get(`/patients/export/${start}/${end}`);

      const file = new Blob([result.data], { type: 'text/csv;charset=utf-8;' });
      FileDownload(file, `patients-${start.replaceAll('-', '.')}-${end.replaceAll('-', '.')}.csv`);

      // FileDownload(
      //   result.data,
      //   `patients-${start.replaceAll('-', '.')}-${end.replaceAll('-', '.')}.csv`,
      // );
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  setProfileTemplate(defaultFields, customFields) {
    return new Promise(async (resolve, reject) => {
      try {
        await Http.put('/patients/template', { defaultFields, customFields });
        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });
  },
  getProfileTemplate() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Http.get('/patients/template');
        return resolve(response.data.data);
      } catch (error) {
        return reject(error);
      }
    });
  },
  getList() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Http.get('/all/patients');
        return resolve(response.data.data);
      } catch (error) {
        return reject(error);
      }
    });
  },
  load(page = 0, search = '', source = 'all', sortBy, sortDirection) {
    return new Promise((resolve, reject) => {
      Http.get(`/patients/${page}/${source}/${search}?sortBy=${sortBy}&sortDirection=${sortDirection}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  find(query = '', getLesions = false) {
    return new Promise((resolve, reject) => {
      Http.get(`/patients/find/${query}/${getLesions ? 'expanded' : 'simple'}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  register(patient) {
    return new Promise((resolve, reject) => {
      Http.post('/patients', patient).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  update(id, patient) {
    return new Promise((resolve, reject) => {
      Http.put(`/patients/${id}`, patient).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      Http.patch(`/patients/${id}`, {}).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  addLesion(id, lesion, location, evaluation, device, diagnosis, photos, notes, final_diagnosis, biopsy_date, results_date, treatment, action, attachments, type, tags) {
    return new Promise((resolve, reject) => {
      Http.post('/patients/lesions', { id, lesion, location, evaluation, device, diagnosis, notes, final_diagnosis, biopsy_date, results_date, treatment, action, attachments, photos, type, tags }).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  addCustomLesion(id, lesion, location, fields) {
    return new Promise((resolve, reject) => {
      Http.post('/patients/lesions', { id, lesion, location, fields }).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  updateRecordImageData(id, photo, data) {
    return new Promise((resolve, reject) => {
      Http.put(`/patients/records/${id}/${photo}`, { data }).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  getRecordsTags(query) {
    return new Promise((resolve, reject) => {
      Http.get(`/pathology/tags/${query}`).then((result) => {
        if (!result.data.error) resolve(result.data.data);
        else resolve([]);
      }, (error) => resolve([])).catch((error) => resolve([]));
    });
  },
  addRecord(id, location, evaluation, device, diagnosis, photos, notes, final_diagnosis, biopsy_date, results_date, treatment, action, attachments, type, tags) {
    return new Promise((resolve, reject) => {
      Http.post('/patients/records', { id, location, evaluation, device, diagnosis, photos, notes, final_diagnosis, biopsy_date, results_date, treatment, action, attachments, type, tags }).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  addCustomRecord(id, location, fields) {
    return new Promise((resolve, reject) => {
      Http.post('/patients/records', { id, location, fields }).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  updateRecord(id, evaluation, device, diagnosis, photos, notes, final_diagnosis, biopsy_date, results_date, treatment, action, attachments, type, tags) {
    return new Promise((resolve, reject) => {
      Http.put(`/patients/records/${id}`, { evaluation, device, diagnosis, photos, notes, final_diagnosis, biopsy_date, results_date, treatment, action, attachments, type, tags }).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  updateCustomRecord(id, fields, template) {
    return new Promise((resolve, reject) => {
      Http.put(`/patients/records/${id}`, { fields, template }).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  deleteRecord(id) {
    return new Promise((resolve, reject) => {
      Http.patch(`/patients/records/${id}`, {}).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  deleteLesion(id) {
    return new Promise((resolve, reject) => {
      Http.patch(`/patients/lesions/${id}`, {}).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  uploadImage(formData) {
    return new Promise((resolve, reject) => {
      Http.uploadFormData('/patients/images/upload', formData).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  uploadAttachments(formData) {
    return new Promise((resolve, reject) => {
      Http.uploadFormData('/patients/attachments/upload', formData).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  get(id) {
    return new Promise((resolve, reject) => {
      Http.get(`/patient/${id}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  lockPatients(lock) {
    return new Promise((resolve, reject) => {
      Http.put(`/patients/lock/${lock}`).then((result) => {
        if (result && !result.data.error) resolve(true);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  getLesion(id) {
    return new Promise((resolve, reject) => {
      Http.get(`/patients/lesion/${id}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  getEsignUrl: async (fieldId, email, firstName, lastName) => {
    try {
      const result = await Http.post(`/patients/esign/${fieldId}`, {
        email,
        first_name: firstName,
        last_name: lastName,
      });
      return result.data.data;
    } catch (error) {
      throw error;
    }
  },
  verifyEsignature: async (fieldId, email, documentId) => {
    try {
      const result = await Http.put(`/patients/esign/${fieldId}`, {
        email,
        documentId,
      });
      return result.data.data;
    } catch (error) {
      throw error;
    }
  },
  getRecordEsignUrl: async (fieldId, email, firstName, lastName) => {
    try {
      const result = await Http.post(`/record/esign/${fieldId}`, {
        email,
        first_name: firstName,
        last_name: lastName,
      });
      return result.data.data;
    } catch (error) {
      throw error;
    }
  },
  verifyRecordEsignature: async (fieldId, email, documentId) => {
    try {
      const result = await Http.put(`/record/esign/${fieldId}`, {
        email,
        documentId,
      });
      return result.data.data;
    } catch (error) {
      throw error;
    }
  },
  downloadRecordEsign: async (recordId, fieldId, fieldName) => {
    try {
      const result = await Http.download(`/record/${recordId}/esign/${fieldId}`);
      FileDownload(
        result.data,
        `${fieldName}-${Date.now()}.pdf`,
      );
      return true;
    } catch (error) {
      throw error;
    }
  },
};
