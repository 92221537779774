export const printPhotoType = (type, typeOnly = false) => {
  switch (type) {
    case 'clinical':
      return `Clinical${!typeOnly ? ' Photo' : ''}`;
    case 'dermatoscopic':
      return `Dermatoscopic${!typeOnly ? ' Photo' : ''}`;
    case 'distance':
      return `Distance${!typeOnly ? ' Photo' : ''}`;
    case 'lesion':
      return `Lesion${!typeOnly ? ' Photo' : ''}`;
    default:
      return 'Captured/Attached Photo';
  }
};
