import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Dialog from '@material-ui/core/Dialog';

const AfterCaptureOptions = (props) => {
  return <Dialog fullWidth={true} open={true}>
        <DialogTitle>Lesion Captured
        </DialogTitle>
        <DialogContent>
        <p className="details">
            The lesion has been captured successfully, please indicate what would you like to do now.
        </p>
        <button onClick={props.onViewPatient} className="btn btn-block btn-branded">
            View Patient Record
        </button>
        <div className="line-break"></div>
        <button onClick={props.onNewLesion} className="btn btn-block btn-branded">
            Capture a new lesion for the patient
        </button>
        <div className="line-break"></div>
        <button onClick={props.onReset} className="btn btn-block btn-branded">
            Capture a lesion for another patient
        </button>
        <div className="line-break"></div>
        <button onClick={props.onPatients} className="btn btn-block btn-branded">
            View Patients List
        </button>
        <div className="line-break"></div>
        </DialogContent>
                </Dialog>;
};
export default AfterCaptureOptions;
