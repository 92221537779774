import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import SettingsIcon from '@material-ui/icons/Settings';

import PatientFields from '../../forms/PatientFields';
import ExportPatients from '../../forms/ExportPatients';
import PatientForm from '../../forms/Patient';
import PaginatedTable from '../../components/PaginatedTable';
import { showMessage, showError, confirmAction, setCurrentScreen } from '../../actions/uiActions';
import PatientsAPI from '../../api/patients';
// import TemplatesAPI from '../../api/templates';

class PatientsList extends Component {
  state = {
    mode: 'default',
    loading: true,
    patients: [],
    count: 1,
    page: 0,
    mode: 'default',
    tab: 0,
    full_name: false,
    locked: false,
    searchingString: '',
    sortBy: 'name',
    sortDirection: 'asc',
  }

  componentDidMount() {
    this.props.setCurrentScreen('Patients List', false);
    this.refresh();
  }

  goToPage = (page) => {
    this.setState({ page, loading: true }, async () => {
      try {
        const result = await PatientsAPI.load(
          this.state.page,
          this.state.searchingString,
          this.state.tab == 0
            ? 'all'
            : (this.state.tab == 1 ? 'clinic' : 'referral'),
          this.state.sortBy,
          this.state.sortDirection,
        );
        this.setState({
          patients: result.patients,
          count: result.count,
          page: result.page,
          locked: result.locked,
        });
      } catch (error) {
        this.props.showError();
        this.setState({ patients: [], count: 0, page: 0 });
      } finally {
        this.setState({ loading: false });
      }
    });
  }

  refresh = () => {
    this.goToPage(0);
  }

  toggleLock = async () => {
    this.setState({ locked: !this.state.locked }, async () => {
      try {
        await PatientsAPI.lockPatients(this.state.locked ? 'yes' : 'no');
      } catch (errr) {
        this.props.showError();
        this.setState({ locked: !this.state.locked });
      }
    });
  }

  getName = (patient) => {
    if (this.state.full_name) return `${patient.firstname} ${patient.lastname}`;
    return `${patient.firstname} ${patient.lastname}`.split(' ').map((name) => name.substring(0, 1)).join('. ');
  }

  hadleSearchInputKeyDown = ({ key }) => {
    if (key === 'Enter') {
      this.goToPage(this.state.page);
    }
  }

  handleSort = (sortBy, sortDirection) => {
    this.setState({
      sortBy,
      sortDirection,
    }, () => this.goToPage(this.state.page));
  }

  render() {
    return (
      <div>
        <StyledGrid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search"
            value={this.state.searchingString}
            onChange={(ev) => this.setState({ searchingString: ev.target.value })}
            onKeyDown={this.hadleSearchInputKeyDown}
          />
        </StyledGrid>

        {this.state.mode == 'add' && (
          <PatientForm onClose={() => this.setState({ mode: 'default' })} onNewPatient={() => {
            this.refresh();
            this.setState({ mode: 'default' });
          }} />
        )}
        {this.state.mode === 'settings' && this.props.auth.account.settings && (this.props.auth.account.settings.defaultFields || this.props.auth.account.settings.patientTemplates) && <PatientFields onClose={() => this.setState({ mode: 'default' })} />}
        {this.state.mode === 'export' && <ExportPatients onClose={() => this.setState({ mode: 'default' })} />}
        <section className="wide animated fadeIn">
          {this.props.auth.account.settings && (this.props.auth.account.settings.defaultFields || this.props.auth.account.settings.patientTemplates) && <Tooltip title="Edit Patient Profile Template" placement="top" className="settings-button">
            <Fab onClick={() => this.setState({ mode: 'settings' })} color="default" aria-label="Settings" >
              <SettingsIcon />
            </Fab>
          </Tooltip>}
          <Tooltip title="Add New Patient" placement="top" className="floating-button">
            <Fab onClick={() => this.setState({ mode: 'add' })} color="secondary" aria-label="Add" >
              <AddIcon />
            </Fab>
          </Tooltip><div className="line-break"></div>
          <Paper>
            <AppBar className="no-margin" position="static" color="default">

              <div style={{ marginLeft: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.locked}
                      onChange={this.toggleLock}
                      value="patients_lock"
                      color="primary"
                    />
                  }
                  label="Do not share my patients with other physicians"
                />
                <div>
                  <Tooltip style={{ marginRight: '20px' }} title="Export Patients to CSV">
                    <IconButton onClick={() => this.setState({ mode: 'export' })}>
                      <CloudDownload />
                    </IconButton>
                  </Tooltip>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.full_name}
                        onChange={(e) => this.setState({ full_name: e.target.checked })}
                        value="full_name"
                        color="primary"
                      />
                    }
                    label="Show Patient Full Name"
                  />
                </div>

              </div>

            </AppBar>
            <div className="row padding">
              <div className="col-12">
                <Paper>
                  <PaginatedTable
                    className="light"
                    loading={this.state.loading}
                    onSort={this.handleSort}
                    currentSortBy={this.state.sortBy}
                    currentSortDirection={this.state.sortDirection}
                    columns={[
                      {
                        name: 'ID',
                        properties: { key: 0, style: { width: '1px' } },
                        sortBy: 'id',
                      },
                      {
                        name: 'Patient Name',
                        properties: { key: 1 },
                        sortBy: 'name',
                      },
                      {
                        name: 'Creation Date',
                        properties: { key: 3 },
                      },
                      {
                        name: 'Last Modified',
                        properties: { key: 4 },
                      },
                    ]}
                    rows={this.state.patients.map((patient) => ({
                      properties: {
                        className: 'clickable-row',
                        key: patient.id,
                        onClick: () => this.props.history.push(`/patient/${patient.id}`),
                      },
                      columns: [
                        { value: patient.id, properties: { key: 'id' } },
                        { value: this.getName(patient), properties: { key: `${patient.id}-1` } },
                        { value: patient.created_on, properties: { key: `${patient.id}-4` } },
                        { value: patient.updated_on, properties: { key: `${patient.id}-5` } },
                      ],
                    }))}
                    emptyMessage="No patients have been registered"
                    count={this.state.count}
                    page={this.state.page}
                    goToPage={this.goToPage}
                  />
                </Paper>
              </div>

              <div className="line-break">
              </div>
            </div>
          </Paper>
        </section></div>
    );
  }
}

const StyledGrid = styled(Grid)`
  margin: 0 2%;
  margin-bottom: -20px;
`;

PatientsList.propTypes = {
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};

const connectFunction = connect(
  (state) => { return state; },
  { showMessage, showError, setCurrentScreen, confirmAction },
);

export default withRouter(connectFunction(PatientsList));
