import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { FITZPATRICK_SCALE } from '../../utils/data';
import './style.css';
import { showMessage, showError } from '../../actions/uiActions';

import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const TemplateFieldRules = (props) => {
  const getDefaultMatchAndValue = () => {
    const field = props.fields.find((field) => field.settings.id == selectedFieldId);
    switch (field.type) {
      case 'boolean':
        return { match: 'eq', value: 'yes' };
      case 'number':
      case 'date':
      case 'alpha':
      case 'email':
      case 'phone':
      case 'tags':
        return { match: 'eq', value: '' };
      case 'single-choice':
        return { match: 'eq', value: field.options.split(',')[0].trim() };
      case 'multiple-choice':
        return { match: 'eq', value: [field.options.split(',')[0].trim()] };
      case 'result':
        return { match: 'eq', value: '1' };
      case 'fitzpatrick':
        return { match: 'eq', value: ['i'] };
    }
  };

  const [selectedFieldId, setSelectedFieldId] = useState(props.fields[0].settings.id);

  const defaultMatch = getDefaultMatchAndValue();
  const [match, setMatch] = useState('');
  const [value, setValue] = useState('');
  const [rules, setRules] = useState(props.field.settings.rules || []);
  const [condition, setCondition] = useState('and');

  useEffect(() => {
    const defaultMatch = getDefaultMatchAndValue();
    setMatch(defaultMatch.match);
    setValue(defaultMatch.value);
  }, [selectedFieldId]);

  useEffect(() => {
    document.querySelector('div[role="dialog"]').style.display = 'none';
    return () => document.querySelector('div[role="dialog"]').style.display = 'block';
  }, []);

  const addRule = () => {
    if (!value) {
      return props.showError('Please enter a value to match.');
    }
    const newRule = {
      field: selectedFieldId,
      match,
      value,
      condition,
    };
    setRules([...rules, newRule]);
  };

  const getInputType = (fieldType) => {
    switch (fieldType) {
      case 'number':
        return 'number';
      case 'date':
        return 'date';
      case 'email':
        return 'email';
      case 'phone':
        return 'tel';
      default:
        return 'text';
    }
  };

  const renderAnswerInput = () => {
    const field = props.fields.find((field) => field.settings.id == selectedFieldId);
    switch (field.type) {
      case 'fitzpatrick':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <select multiple={true} value={value} onChange={(e) => {
                const newValue = Array.from(e.target.selectedOptions, (option) => option.value);
                setValue(newValue);
              }} className="form-control">
                {Object.keys(FITZPATRICK_SCALE).map((key, index) => <option value={key} key={`fitzpatrick-option-${index}`}>{FITZPATRICK_SCALE[key]}</option>)}
              </select>
            </div>
          </div>
        </div>;
      case 'single-choice':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <select multiple={false} value={value} onChange={(e) => setValue(e.target.value)} className="form-control">
                {field.options.split(',').map((option, index) => <option value={option.trim()} key={`field-choice-option-${index}`}>{option.trim()}</option>)}
              </select>
            </div>
          </div>
        </div>;
      case 'multiple-choice':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <select multiple={true} value={value} onChange={(e) => {
                const newValue = Array.from(e.target.selectedOptions, (option) => option.value);
                setValue(newValue);
              }} className="form-control">
                {field.options.split(',').map((option, index) => <option value={option.trim()} key={`field-choice-option-${index}`}>{option.trim()}</option>)}
              </select>
            </div>
          </div>
        </div>;
      case 'alpha':
      case 'email':
      case 'phone':
      case 'tags':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
                <option value="contains">Contains</option>
                <option value="ncontains">Does not contain</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <input type={getInputType(field.type)} value={value} onChange={(e) => setValue(e.target.value)} className="form-control" />
            </div>
          </div>
        </div>;
      case 'boolean':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <select value={value} onChange={(e) => setValue(e.target.value)} className="form-control">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
        </div>;
      case 'result':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <select value={value} onChange={(e) => setValue(e.target.value)} className="form-control">
                <option value="1">High Risk</option>
                <option value="0">Low Risk</option>
              </select>
            </div>
          </div>
        </div>;
      case 'date':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
                <option value="gt">Greater Than</option>
                <option value="lt">Lower Than</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <input disabled={false} value={value} onChange={(e) => setValue(e.target.value)} placeholder="Enter date" type="date" className="form-control" />
            </div>
          </div>
        </div>;
      case 'number':
        return <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Match</label>
              <select value={match} onChange={(e) => setMatch(e.target.value)} className="form-control">
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
                <option value="gt">Greater Than</option>
                <option value="lt">Lower Than</option>
              </select>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Value</label>
              <input disabled={false} value={value} onChange={(e) => setValue(e.target.value)} placeholder="Enter value" type="number" className="form-control" />
            </div>
          </div>
        </div>;
    }
  };
  const submit = () => {
    if (rules.length === 0) {
      return props.showError('Please add at least one rule.');
    }
    return props.onSubmit(rules);
  };
  const renderForm = () => {
    return <li>
      <div className="text-center">
        {rules.length > 0 && <input checked={condition === 'and'} type="checkbox" onChange={(e) => setCondition(e.target.checked ? 'and' : 'or')} />}
      </div>
      <div className="form-group">
        <label>Field Name</label>
        <select className="form-control" onChange={(e) => setSelectedFieldId(e.target.value)}>
          {props.fields.map((field, index) => <option value={field.settings.id}>{field.name}</option>)}
        </select>
      </div>
      {renderAnswerInput()}
      <button onClick={addRule} className="btn btn-outline-primary btn-block">Add Rule</button>
    </li>;
  };

  const renderRule = (field, rule) => {
    let match = '';
    switch (rule.match) {
      case 'eq':
        match = 'equals';
        break;
      case 'neq':
        match = 'not equals';
        break;
      case 'gt':
        match = 'greater than';
        break;
      case 'gt':
        match = 'lower than';
        break;
      case 'contains':
        match = 'Contains';
        break;
      case 'ncontains':
        match = 'Does not contain';
        break;
    }
    let value = rule.value;
    if (field.type === 'multiple-choice') {
      value = rule.value.join(', ');
    } else if (field.type === 'fitzpatrick') {
      value = rule.value.map((value) => FITZPATRICK_SCALE[value]).join(', ');
    }
    return <><span className="match">{match}</span> <span className="value">{value}</span></>;
  };

  const renderRules = () => {
    return rules.map((rule, index) => {
      const field = props.fields.find((field) => field.settings.id == rule.field);
      return <li className="rule" key={`rule-${index}`}>
        <IconButton className="delete" onClick={() => setRules(rules.filter((r, p) => p !== index))}><Delete /></IconButton>
        {index > 0 && <div className="condition">{rule.condition}</div>}
        <div className="form-group">
          <label>{field.name}</label>
          <p>{renderRule(field, rule)}</p>
        </div>
      </li>;
    });
  };

  return ReactDOM.createPortal(<div className="field-rules-container">
    <div className="field-rules-content">
      <button onClick={props.onCancel} className="cancel">Cancel</button>
      <h1>{props.field.name}</h1>
      <p>Rules for the field <b>{props.field.name}</b> to be visible.</p>
      <ul>
        {renderRules()}
        {renderForm()}

      </ul>
      <button onClick={submit} className="btn btn-primary btn-block">Save Rules</button>
    </div>

  </div>, document.querySelector('body'));
};

export default connect(null, { showMessage, showError })(TemplateFieldRules);
