import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class Alert extends Component {
    state = {
      open: true,
    };

      handleClick = () => {
        this.setState({ open: true });
      };

      handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        this.setState({ open: false });
        if (this.props.onClose) this.props.onClose();
      };

      render() {
        return <Snackbar
        className="alert-message"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.open}
            autoHideDuration={6000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<div><p>{this.props.children}</p><IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className="close-icon"
                onClick={this.handleClose}
            >
                <CloseIcon />
            </IconButton></div>}
            action={[]}
  />;
      }
}
export default Alert;
