import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CenterFocusWeak from '@material-ui/icons/CenterFocusWeak';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Dashboard from '@material-ui/icons/Dashboard';
import Group from '@material-ui/icons/Group';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Security from '@material-ui/icons/Security';
import SettingsCell from '@material-ui/icons/SettingsCell';
import Extension from '@material-ui/icons/Extension';
import FilterNone from '@material-ui/icons/FilterNone';
import ArrowBack from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import { Route, Switch, withRouter } from 'react-router';
import ProfilePictureComponent from '../../components/ProfilePicture';
import { logout } from '../../actions/authActions';
import Auth from '../../api/auth';
import AppConfig from '../../config/env';

class DashboardComponent extends Component {
    state=
    {
      anchorEl: null,
      drawer: false,
    }

    toggleDrawer = (open) => () => {
      this.setState({
        drawer: open,
      });
    };

    handleMenu = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

      handleClose = () => {
        this.setState({ anchorEl: null });
      };

      render() {
        const open = Boolean(this.state.anchorEl);
        return <div>
          <AppBar position="static">

            <Toolbar className="main-toolbar">
            {this.props.ui.back && <IconButton onClick={this.props.history.goBack}><ArrowBack /></IconButton>}
            <h1>{this.props.ui.screen}</h1>
              <div className="space-filler"></div>
              <NavLink activeClassName="active" className="navigation-button" to="/capture">
              <IconButton className="navigation-button">
                <CenterFocusWeak/> <span className="mobile-hidden">Capture Lesion</span>
              </IconButton>
              </NavLink>
              <NavLink activeClassName="active" className="navigation-button" to="/patients">
              <IconButton className="navigation-button">
                <Group /> <span className="mobile-hidden">Patients List</span>
              </IconButton>
              </NavLink>
              <NavLink activeClassName="active" className="navigation-button" to="/templates">
              <IconButton className="navigation-button">
                <FilterNone /> <span className="mobile-hidden">Record Templates</span>
              </IconButton>
              </NavLink>
                <IconButton aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit">
                    <ProfilePictureComponent className="avatar" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                  getContentAnchorEl={null}
                >
                  <MenuItem onClick={this.handleClose}>
                  <a href={`${AppConfig.ACCOUNTS_APP_URL}/profile`}>
                  <ListItemIcon className="icon">
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText className="text" inset primary="Profile" />
                  </a>
                  </MenuItem>
                  <MenuItem onClick={this.props.logout}>
                  <ListItemIcon className="icon">
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText className="text" inset primary="Logout" />
                  </MenuItem>
                </Menu>
            </Toolbar>
          </AppBar>
        </div>;
      }
}
const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
    logout,
    ui: state.ui,
  };
};
DashboardComponent.propTypes =
    {
      account: PropTypes.object.isRequired,
      logout: PropTypes.func,
    };
export default withRouter(connect(mapStateToProps, { logout })(DashboardComponent));
