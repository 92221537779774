import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LesionMap from '../../components/LesionMap';
import PatientSelection from '../../components/PatientSelection';
import ButtonImageUploader from '../../components/ButtonImageUploader';
import PatientsApi from '../../api/patients';
import { showMessage, showError, confirmAction, setCurrentScreen } from '../../actions/uiActions';
import ImageGallery from '../../components/ImageGallery';
import ScansSelection from '../../components/ScansSelection';
import IndicatorButton from '../../components/IndicatorButton';
import AfterCaptureOptions from '../../components/AfterCaptureOptions';
import Body from '../../components/Body';
import { printPhotoType } from '../../utils/Formatter';

class LesionRecord extends Component {
  state = {
    patient: null,
    mode: 'default',
    photos: [],
    scan: null,
    lesion: null,
    busy: false,
    position: 'front',
    lesionId: '',
  }

  mapWidth = () => {
    if (window.innerWidth >= 1024) return 500;
    if (window.innerWidth >= 768) return 350;
    return 300;
  }

  componentWillMount() {
    if (document.location.href.indexOf('auth') > 0) this.props.history.push('/');
    this.props.setCurrentScreen('Capture Lesion', false);
  }

  save = () => {
    if (!this.state.patient) this.props.showError('Please select a patient');
    else if (!this.state.lesion) this.props.showError('Please indicate the lesion location');
    else {
      this.setState({ busy: true });
      PatientsApi.addLesion(this.state.patient.id, this.state.lesion, '', '', this.state.scan ? this.state.scan : '', '', this.state.photos).then((lesionId) => {
        this.setState({ busy: false, mode: 'after', lesionId });
      }, (error) => {
        this.props.showError();
        this.setState({ busy: false });
      }).catch((error) => {
        this.props.showError();
        this.setState({ busy: false });
      });
    }
  }

  onPreExistingLesion = (lesion) => {
    this.props.confirmAction('Would you like to view the patient records for this lesion?', () => this.props.history.push(`/patient/${this.state.patient.id}/${lesion.id}`));
  }

  render() {
    return <div className="animated fadeIn">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="col-12">
                  {!this.state.patient && <button disabled={this.state.busy} className="btn btn-block btn-branded" onClick={() => this.setState({ mode: 'patient' })}>Select or Register Patient</button>}
                  {this.state.patient && <div className="dotted-container">
                    <div className="row">
                      <div className="col-6">
                        <label>Patient Name</label>
                        {this.state.patient.name}
                      </div>
                      <div className="col-4">
                        <label>Phone Number</label>
                        {this.state.patient.phone}
                      </div>
                      <div className="col-2 text-right">
                        {!this.state.busy && <button onClick={() => this.setState({ patient: null })} className="delete">
                          x
                                    </button>}
                      </div>
                    </div>
                  </div>}
                </div>
                <div className="line-break"></div>
                <div className="col-12 mobile-margin text-center">
                  <h6 className="text-left">Select Lesion Location</h6>
                  <p className="details">
                    Tap/Click to add a pin at the lesion location.
                            </p>
                  <Body disabled={this.state.busy} onLesionAdded={(lesion) => this.setState({ lesion })} lesions={this.state.lesion ? [this.state.lesion] : []} mode="add" />
                  {/* <LesionMap onLesionSelected={this.onPreExistingLesion} disabled={this.state.busy} onLesionAdded={(lesion)=>this.setState({lesion})} lesions={this.state.patient?this.state.patient.lesions:[]} size={this.mapWidth()} /> */}
                </div>
              </div>
              <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1 col-md-6 col-12">
                <div className="col-12">
                  <div className="card inner-card">
                    <h6>Use DermaSensor Device</h6>
                    <p>
                      Select a capture from your DermaSensor device to associate with this lesion.
                                </p>
                    {!this.state.scan && <button disabled={this.state.busy} onClick={() => this.setState({ mode: 'scan' })} className="btn btn-block btn-branded">Use device</button>}
                    {this.state.scan && <div className="dotted-container">
                      <div className="row">
                        <div className="col-4">
                          <label>Date taken</label>
                          {this.state.scan.date}
                        </div>
                        <div className="col-4">
                          <label>Result</label>
                          {this.state.scan.result}

                        </div>
                        <div className="col-4">
                          <label>DRN</label>
                          {this.state.scan.drn}
                          {!this.state.busy && <button className="delete float-right" onClick={() => this.setState({ scan: null })}>x</button>}
                        </div>

                      </div>
                    </div>}
                  </div>
                </div>
                <div className="line-break"></div>
                <div className="col-12">
                  <div className="card inner-card">
                    <h6>Capture Photos</h6>
                    <p>
                      Use the DermaSensor App to capture photos attach them to this lesion.
                                </p>
                    {this.state.photos.map((photo, index) => <div key={`photo-${index}`} className="dotted-container" style={{ marginBottom: '20px' }}>
                      <div className="row">
                        <div className="col-3">
                          <img className="btn-image-preview" src={photo.thumbnail || photo.url} alt="Preview" />
                        </div>
                        <div className="col-6">{printPhotoType(photo.data.type)} <b>(Today)</b>
                        </div>
                        <div className="col-3 text-center">
                          {!this.state.busy && <button className="delete" onClick={() => {
                            this.setState({ photos: this.state.photos.filter((p) => photo.id !== p.id) });
                          }}>x</button>}
                        </div>
                      </div>
                    </div>)}
                    <button disabled={this.state.busy} onClick={() => this.setState({ mode: 'image' })} className="btn btn-block btn-branded">Select Photos</button>

                  </div>
                </div>

                <div className="divider"></div>
                <IndicatorButton onClick={this.save} disabled={this.state.busy} className="btn btn-block btn-branded2">Save and Add Notes</IndicatorButton>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="line-break"></div>
      {this.state.mode === 'patient' && <PatientSelection lesions={true} onPatientSelected={(patient) => {
        this.setState({ patient, mode: 'default' });
      }} onClose={() => this.setState({ mode: 'default' })} />}
      {(this.state.mode === 'image' || this.state.mode === 'imaged') && <ImageGallery multiple={true} onImagesSelected={(images) => {
        this.setState({
          photos: [...this.state.photos, ...images],
          mode: 'default',
        });
      }} onClose={() => this.setState({ mode: 'default' })} />}
      {this.state.mode === 'scan' && <ScansSelection onClose={() => this.setState({ mode: 'default' })} onScanSelected={(scan) => this.setState({ mode: 'default', scan })} />}
      {this.state.mode === 'after' && <AfterCaptureOptions
        onViewPatient={() => this.setState({ mode: 'default' }, () => this.props.history.push(`/patient/${this.state.patient.id}/${this.state.lesionId}`))}
        onNewLesion={() => this.setState({ mode: 'default', lesion: null, dermatoscopic: null, clinical: null, scan: null })}
        onReset={() => this.setState({ mode: 'default', lesion: null, patient: null, dermatoscopic: null, clinical: null, scan: null })}
        onPatients={() => this.setState({ mode: 'default' }, () => this.props.history.push('/patients'))}
      />}
    </div>;
  }
}
const mapStateToProps = (state) => {
  return state;
};
LesionRecord.propTypes =
{
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps, { showMessage, showError, confirmAction, setCurrentScreen })(LesionRecord));
