import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import DialogComponent from '../DialogComponent';
import TemplatesAPI from '../../api/templates';

class LesionRecordTypeSelection extends Component {
    state={
      templates: [],
      loading: true,
    }

    async componentDidMount() {
      try {
        const templates = await TemplatesAPI.getList();
        this.setState({ templates });
      } catch (error) {} finally {
        this.setState({ loading: false });
      }
    }

    render() {
      return <DialogComponent onClose={this.props.onClose} actions={[]} title="Add record" fullWidth={true}>
        <p>Please indicate what type of record would you like to add to the lesion.</p>
        {this.state.loading ? <div className="text-center"><CircularProgress /></div> : <>{this.state.templates.map((template, index) => <button key={`template-${index}`} onClick={() => this.props.onSelection('custom', template)} className="btn btn-primary btn-large btn-block">Add {template.name}</button>)}{this.props.auth.account.settings && this.props.auth.account.settings.defaultRecords && <><button onClick={() => this.props.onSelection('clinic')} className="btn btn-primary btn-large btn-block">Add Pathology Record</button>
        <button onClick={() => this.props.onSelection('pathology')} className="btn btn-primary btn-large btn-block">Add Clinical Record</button></>}</>}
    </DialogComponent>;
    }
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, {})(LesionRecordTypeSelection);
