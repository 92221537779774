import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import FreshChat from 'react-freshchat';
import DashboardComponent from './Dashboard';
import ApplicationLoaderComponent from '../components/ApplicationLoader';
import { verifyAuthentication } from '../actions/authActions';
import Auth from '../api/auth';
import CommonUIComponent from '../components/CommonUI';
import AuthContainer from './Auth';
import { history } from '../store';
import PatientsListComponent from './PatientsList';
import PatientContainer from './Patient';
import LesionMap from '../components/LesionMap';
import LesionRecord from './LessionRecord';
import Config from '../config/env';
import Templates from './Templates';

class RootContainer extends Component {
  state = {
    auth_verified: false,
  }

  componentWillMount() {
    const locationInfo = document.location.href.replace('://', ':').split('/');
    const isSigned = Auth.appIsSigned();
    if (locationInfo[1] != 'auth' && !isSigned) {
      Auth.signApp().then((success) => this.props.verifyAuthentication(), (error) => { }).catch((error) => { });
    } else if (locationInfo[1] != 'auth') {
      this.props.verifyAuthentication();
    }
  }

  render() {
    return <ConnectedRouter history={history}>
      <div>
        {this.props.authenticating && <ApplicationLoaderComponent />}
        {!this.props.logged && <Route path="/auth/:token" exact component={AuthContainer} />}
        {this.props.logged && <div className="app">
          <DashboardComponent />
          <section>
            <Switch>
              <Route path="/patient/:id/:lesion" exact component={PatientContainer} />
              <Route path="/patient/:id" exact component={PatientContainer} />
              <Route path="/patients" exact component={PatientsListComponent} />
              <Route path="/templates" exact component={Templates} />
              <Route exact path="/capture" component={LesionRecord} />
              <Redirect to="/patients" />
            </Switch>
          </section>
        </div>}
        <CommonUIComponent />
        {this.props.logged && (
          <FreshChat
            token={Config.get('freshchat_token')}
            email={this.props.account.email}
            first_name={this.props.account.firstname}
            onInit={(widget) => {
              widget.user.setProperties({
                email: this.props.account.email,
                first_name: this.props.account.firstname,
                last_name: this.props.account.lastname,
                phone: this.props.account.phone,
              });
            }}
          />
        )}
      </div>

    </ConnectedRouter>;
  }
}
const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
    logged: state.auth.logged,
    authenticating: state.auth.authenticating,
    verifyAuthentication,
  };
};

RootContainer.propTypes =
{
  logged: PropTypes.bool,
  authenticating: PropTypes.bool,
  verifyAuthentication: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { verifyAuthentication })(RootContainer);
