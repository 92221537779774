import { PAGE_LOADING, SHOW_MESSAGE, HIDE_MESSAGE, CONFIRM_ACTION, ACTION_CONFIRMED_OR_CANCELED, CURRENT_SCREEN } from './types';

export function showMessage(text) {
  return function (dispatch) {
    dispatch(
      {
        type: SHOW_MESSAGE,
        payload: text,
      },
    );
  };
}
export function setCurrentScreen(screen, back = false) {
  return function (dispatch) {
    dispatch(
      {
        type: CURRENT_SCREEN,
        payload: { screen, back },
      },
    );
  };
}
export function showError(text) {
  return function (dispatch) {
    dispatch(
      {
        type: SHOW_MESSAGE,
        payload: text || 'There has been an error processing your request, please refresh the page and try again.',
      },
    );
  };
}
export function hideMessage(text) {
  return function (dispatch) {
    dispatch(
      {
        type: HIDE_MESSAGE,
        payload: null,
      },
    );
  };
}
export function confirmAction(text, onConfirm, title, ok, cancel, onCancel = null) {
  return function (dispatch) {
    dispatch(
      {
        type: CONFIRM_ACTION,
        payload: { title: title || 'Confirm action', text: text || 'Do you want to complete the action?', ok: ok || 'Yes', cancel: cancel || 'No', onConfirm, onCancel },
      },
    );
  };
}
export function actionConfirmedOrCanceled() {
  return function (dispatch) {
    dispatch(
      {
        type: ACTION_CONFIRMED_OR_CANCELED,
        payload: null,
      },
    );
  };
}

export function showPageLoader() {
  return function (dispatch) {
    dispatch(
      {
        type: PAGE_LOADING,
        payload: true,
      },
    );
  };
}
export function hidePageLoader() {
  return function (dispatch) {
    dispatch(
      {
        type: PAGE_LOADING,
        payload: false,
      },
    );
  };
}
