import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ProfilePictureComponent extends Component {
  render() {
    return <img src="/assets/images/user.svg" className={this.props.className}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
  };
};
ProfilePictureComponent.propTypes =
{
  account: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, null)(ProfilePictureComponent);
