import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showMessage, showError, hideMessage, actionConfirmedOrCanceled } from '../../actions/uiActions';
import ConfirmAction from '../ConfirmAction';
import Alert from '../Alert';
import ApplicationLoader from '../ApplicationLoader';

class CommonUIComponent extends Component {
  render() {
    return (<div>
            {this.props.loading && <ApplicationLoader />}
            {this.props.message && <Alert onClose={this.props.hideMessage}>{this.props.message}</Alert>}
            {this.props.title && this.props.text && this.props.ok && this.props.cancel && <ConfirmAction confirm={this.props.ok} cancel={this.props.cancel} title={this.props.title} onCancel=
            {
                () => {
                  this.props.actionConfirmedOrCanceled();
                  if (this.props.onCancel) this.props.onCancel();
                }

            } onConfirm={() => {
              this.props.actionConfirmedOrCanceled();
              this.props.onConfirm();
            }}>{this.props.text}</ConfirmAction>}
        </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.ui.message,
    title: state.ui.title,
    text: state.ui.text,
    ok: state.ui.ok,
    cancel: state.ui.cancel,
    onConfirm: state.ui.onConfirm,
    onCancel: state.ui.onCancel,
    loading: state.ui.loading,
  };
};
CommonUIComponent.propTypes =
    {
      showMessage: PropTypes.func.isRequired,
      showError: PropTypes.func.isRequired,
      hideMessage: PropTypes.func.isRequired,
      actionConfirmedOrCanceled: PropTypes.func.isRequired,
    };
export default connect(mapStateToProps, { showMessage, showError, hideMessage, actionConfirmedOrCanceled })(CommonUIComponent);
