import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { showMessage, showError } from '../../actions/uiActions';
import TemplatesAPI from '../../api/templates';
import PatientsAPI from '../../api/patients';
import IndicatorButton from '../../components/IndicatorButton';

const ExportRecord = (props) => {
  const [loading, setLoading] = useState(true);
  const [busy, setBusy] = useState(false);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [patients, setPatients] = useState([]);
  const [id, setId] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const list = await PatientsAPI.getList();
        setPatients(list);
        setLoading(false);
      } catch (error) {
        props.showError('There was an error loading the list of patients.');
        return props.onClose();
      }
    })();
  }, []);

  const download = async () => {
    if (!start || !moment(start, 'YYYY-MM-DD').isValid()) {
      return props.showError('Please enter a valid start date.');
    } if (!end || !moment(end, 'YYYY-MM-DD').isValid()) {
      return props.showError('Please enter an end date.');
    } if (moment(start, 'YYYY-MM-DD').format('X') > moment(end, 'YYYY-MM-DD').format('X')) {
      return props.showError('Start date cannot be after end date.');
    }
    setBusy(true);
    try {
      await TemplatesAPI.downloadTemplateRecords(props.template.id, props.template.template, start, end, id);
      props.onClose();
    } catch (error) {
      props.showError('There has been an error exporting the records, please try again.');
    } finally {
      setBusy(false);
    }
  };

  const renderForm = () => {
    return <div className="row">
        <div className="col-6">
        <div className="form-group nice">
            <label>Start Date</label>
            <input value={start} onChange={(e) => setStart(e.target.value)} disabled={busy} type="date" className="form-control" />
        </div>
        </div>
        <div className="col-6">
        <div className="form-group nice">
            <label>End Date</label>
            <input value={end} onChange={(e) => setEnd(e.target.value)} disabled={busy} type="date" className="form-control" />
        </div>
        </div>
        <div className="col-12">
        <div className="form-group nice">
            <label>Patient Name</label>
            <select className="form-control" value={id} onChange={(e) => setId(e.target.value)}>
                <option value="">All Patients</option>
                {patients.map((patient) => <option key={`patient-id-${patient.id}`} value={patient.id}>{patient.name}</option>)}
                </select>
        </div>
        </div>
    </div>;
  };

  return <Dialog fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">
            Export Template Records to CSV
        </DialogTitle>
        <DialogContent>
            {loading ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> : renderForm()}
        </DialogContent>
        {!loading && <DialogActions style={{ display: 'flex', paddingBottom: '30px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <IndicatorButton disabled={busy} variant="contained" onClick={download} className="btn-material btn-primary" autoFocus>
            Download CSV
          </IndicatorButton>
          {!busy && <Button variant="outlined" onClick={props.onClose} color="primary">
            Cancel
          </Button>}

        </DialogActions>}
        </Dialog>;
};
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, { showMessage, showError })(ExportRecord);
