import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ReactDOM from 'react-dom';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUpload from '@material-ui/icons/CloudUpload';

class FileUpload extends Component {
  state =
    {
      uploading: false,
      file: '',
      fileName: '',
    };

  componentWillMount() {
    if (this.props.value) this.setState(this.props.value);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value) this.setState(newProps.value);
  }

  openFileDialog = () => {
    const fileInputDom = ReactDOM.findDOMNode(this.refs.fileUpload);
    fileInputDom.click();
  }

  handleFile = (event) => {
    if (event.target.files && event.target.files.length) {
      this.setState({ fileName: event.target.files[0].name, uploading: true });
      const formData = new FormData();
      formData.append(this.props.name, event.target.files[0], event.target.files[0].name);
      this.props.onUpload(formData).then((uploadedFile) => {
        this.setState({ uploading: false });
        this.props.onChange({ file: uploadedFile, file_name: this.state.fileName });
      }, (error) => {
        this.setState({ file: '', fileName: '', uploading: false });
        this.props.onError();
      }).catch((error) => {
        this.setState({ file: '', fileName: '', uploading: false });
        this.props.onError();
      });
    }
  }

  handleDelete = () => {
    if (!this.props.disabled) {
      this.setState({ file: '', fileName: '' });
      this.props.onChange(null);
    }
  }

  render() {
    return (
      <div>
        {this.state.uploading && <div className="fileupload-button disabled"><CircularProgress className="progress-indicator" size={24} color={this.props.color} />Uploading file...</div>}
        {this.state.file && <Chip clickable={!this.props.disabled} className="overflowchip" onClick={() => {
          if (!this.props.disabled && this.props.onClick) this.props.onClick();
        }}
          label={this.state.fileName}
          onDelete={this.handleDelete}
          variant="outlined"
        />}
        {!this.state.uploading && !this.state.file && <div><div className="fileupload-button"
          variant={this.props.variant}
          color={this.props.color}
          onClick={this.openFileDialog}>
          <CloudUpload className="margin-rigth10" />
          {this.props.label}</div>
          <input
            type='file'
            multiple={this.props.multi}
            ref='fileUpload'
            style={{ display: 'none' }}
            accept={this.props.accept}
            onChange={this.handleFile} />
        </div>}
      </div>
    );
  }
}

export default FileUpload;
