import Http from '../utils/Http';
import Auth from './auth';

export default
{
  load(page = 0, from = '', to = '') {
    return new Promise((resolve, reject) => {
      Http.get(`/gallery/${page}/${from || '0'}/${to || '0'}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  uploadImage(formData) {
    return new Promise((resolve, reject) => {
      Http.uploadFormData('/gallery/upload', formData).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
};
