import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducer';
import uiReducer from './uiReducer';

export default (history) => combineReducers(
  {
    router: connectRouter(history),
    auth: authReducer,
    ui: uiReducer,
  },
);
