import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ReactDOM from 'react-dom';
import Delete from '@material-ui/icons/Delete';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';

class ButtonImageUploader extends Component {
  state =
    {
      uploading: false,
      files: [],
      file: '',
      fileName: '',
      image: '',
    };

  componentWillMount() {
    if (this.props.value && this.props.value.hasOwnProperty('file') && this.props.value.hasOwnProperty('image') && this.props.value.hasOwnProperty('fileName')) this.setState({ image: this.props.value.image, file: this.props.value.file, fileName: this.props.value.fileName });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value && newProps.value.hasOwnProperty('file') && newProps.value.hasOwnProperty('image') && newProps.value.hasOwnProperty('fileName')) this.setState({ image: newProps.value.image, file: newProps.value.file, fileName: newProps.value.fileName });
  }

  openFileDialog = () => {
    if (!this.state.uploading) {
      const fileInputDom = ReactDOM.findDOMNode(this.refs.fileUpload);
      fileInputDom.click();
    }
  }

  handleFile = (event) => {
    if (event.target.files && event.target.files.length) {
      const imageId = Date.now();
      this.setState({ fileName: event.target.files[0].name, uploading: true, id: imageId });

      const formData = new FormData();
      formData.append(this.props.upload_name ? this.props.upload_name : this.props.name, event.target.files[0], event.target.files[0].name);

      this.props.onUpload(formData).then((uploadedFile) => {
        this.setState({ uploading: false, file: uploadedFile });
        this.props.onChange({ file: uploadedFile, fileName: this.state.fileName, image: this.state.image });
      }, (error) => {
        this.setState({ file: '', image: null, fileName: '', uploading: false });
        this.props.onError();
      }).catch((error) => {
        this.setState({ file: '', fileName: '', image: null, uploading: false });
        this.props.onError();
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleDelete = () => {
    this.setState({ file: '', fileName: '' });
  }

  render() {
    return (
      <div>
        {!this.state.image && <div><button disabled={this.props.disabled} onClick={this.openFileDialog} className="btn btn-block btn-branded">{this.props.children || 'Select image...'}</button><input
          type='file'
          accept="image/*"
          multiple={this.props.multi}
          ref='fileUpload'
          style={{ display: 'none' }}
          onChange={this.handleFile} /></div>}
        {this.state.image && <div className="dotted-container">
          <div className="row">
            <div className="col-3">
              <img className={`btn-image-preview ${this.state.uploading && 'partial'}`} src={this.state.image} alt="Preview" />
            </div>
            <div className="col-6">
              {this.state.uploading ? 'Uploading image...' : 'Image uploaded susccessfully!'}
            </div>
            <div className="col-3 text-center">
              {!this.state.uploading && !this.props.disabled && <button className="delete" onClick={() => {
                this.setState({ image: null, file: '', fileName: '' });
                if (this.props.onDelete) this.props.onDelete();
              }}>x</button>}
              {this.state.uploading && <div className="spinner-border text-primary spinner-border-sm" role="status">
              </div>}
            </div>
          </div>
        </div>}

      </div>
    );
  }
}

export default ButtonImageUploader;
