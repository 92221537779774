import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, Menu, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import PropTypes from 'prop-types';
import { AttachFile } from '@material-ui/icons';
import Validators from '../../utils/Validators';
import AutoComplete from '../AutoComplete';
import FileUpload from '../FileUpload';
import PatientsApi from '../../api/patients';
import { showMessage, showError, confirmAction, setCurrentScreen } from '../../actions/uiActions';
import 'rc-slider/assets/index.css';
import { printPhotoType } from '../../utils/Formatter';
import ScansSelection from '../ScansSelection';
import ImageGallery from '../ImageGallery';
import { FITZPATRICK_SCALE } from '../../utils/data';

const SliderWithTooltip = createSliderWithTooltip(Slider);

class TemplateField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      mode: 'default',
      anchorEl: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleInputChange = (e) => {
    if (e.target.value === '' && this.props.type !== 'boolean') {
      this.setState({ value: e.target.value });
      return this.props.onChange(e);
    }
    switch (this.props.type) {
      case 'multiple-choice':
        if (e.target.checked) {
          this.setState({ value: [...(this.state.value instanceof Array) ? this.state.value : [], e.target.value] }, () => {
            console.log('Updated state:', this.state.value);
            this.props.onChange({ target: { value: this.state.value } });
          });
        } else {
          this.setState({ value: this.state.value.filter((option) => option !== e.target.value) }, () => this.props.onChange({ target: { value: this.state.value } }));
        }

        break;
      case 'number':
        if (/^[0-9]+(\.[0-9]+)?/.test(e.target.value)) {
          this.setState({ value: e.target.value });
          return this.props.onChange(e);
        } if (/^[0-9]+(\.([0-9]*))?/.test(e.target.value)) {
          this.setState({ value: e.target.value });
        }
        break;
      case 'email':
        if (Validators.isValidEmail(e.target.value)) {
          return this.props.onChange(e);
        }
        this.setState({ value: e.target.value });
        break;
      case 'date':
        if (moment(e.target.value, 'YYYY-MM-DD').isValid()) {
          return this.props.onChange(e);
        }
        this.setState({ value: e.target.value });
        break;
      case 'phone':

        if (Validators.isValidPhone(e.target.value)) {
          this.setState({ value: e.target.value });
          return this.props.onChange(e);
        } if (/^[0-9]+/.test(e.target.value)) {
          return this.setState({ value: e.target.value });
        }
        break;
      case 'alpha':
        if (Validators.isAlphaNumeric(e.target.value)) {
          this.setState({ value: e.target.value });
          return this.props.onChange(e);
        }
        break;
      case 'boolean':

        this.setState({ value: e.target.checked });
        return this.props.onChange({ target: { name: e.target.name, value: e.target.checked } });

      default:
        this.setState({ value: e.target.value });
        return this.props.onChange(e);
    }
  }

  toggleMenu = (e) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : e.currentTarget,
    });
  }

  selectMenuOption = (value) => {
    this.props.onChange({ target: { value } });
    this.toggleMenu();
  }

  renderInput = () => {
    switch (this.props.type) {
      case 'fitzpatrick':
        return <div><Button className={`menu-button  fitzpatrick-skin-${this.props.value}`} aria-controls="simple-menu" aria-haspopup="true" onClick={this.toggleMenu}>
          {(this.props.value && `Type ${this.props.value.toUpperCase()}`) || this.props.placeholder || 'Select...'}
        </Button>
          <Menu
            className="custom-menu"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.setState({ anchorEl: null })}
          >
            {Object.keys(FITZPATRICK_SCALE).map((key, index) => <MenuItem key={`fitzpatrick-scale-${index}`} value={key} onClick={() => this.selectMenuOption(key)} className={`custom-menu-item fitzpatrick-skin-${key}`}>Type {key.toUpperCase()}</MenuItem>)}

          </Menu>
        </div>;
      case 'result':
        console.log('Vaue:', this.state.value);

        return !this.state.value ? <button disabled={this.props.disabled} onClick={() => this.setState({ mode: 'scan' })} className="btn btn-block btn-branded">Use device</button> : <div className="dotted-container">
          <div className="row">
            <div className="col-6">
              <label>Date taken</label>
              {this.state.value.date}
            </div>
            <div className="col-3">
              <label>Result</label>
              {this.state.value.result}
            </div>
            <div className="col-3">
              <label>DRN</label>
              {this.state.value.drn}
              {!this.state.busy && <button className="delete float-right" onClick={() => this.setState({ value: null }, () => this.props.onChange({ target: { value: '' } }))}>x</button>}
            </div>

          </div>
        </div>;
      case 'attachment':

        return <div className="row">
          {((this.state.value instanceof Array) ? this.state.value : []).map((attachment, index) => <div key={`attachment-${index}`} className="col-6">
            <div className="fileupload-button disabled file">
              <div className="row">
                <div className="col-2">
                  <AttachFile />
                </div>
                <div className="col-8 file-name">
                  {attachment.file_name}
                </div>
                <div className="col-2">
                  <button onClick={() => this.setState({ value: this.state.value.filter((a) => a.file != attachment.file) }, () => this.props.onChange({ target: { value: this.state.value, name: this.props.name } }))}>X</button>
                </div>
              </div>

            </div>
          </div>)}
          <div className=" col-6">
            <FileUpload name="file" onError={this.props.showError} onUpload={PatientsApi.uploadAttachments} onChange={(file) => this.setState({ value: (this.state.value && this.state.value instanceof Array) ? [...this.state.value, file] : [file] }, () => this.props.onChange({ target: { value: this.state.value, name: this.props.name } }))} label="Select file..." />
          </div>
        </div>;
      case 'tags':
        return <AutoComplete editable={!this.props.disabled} label={''} placeholder="Type tag and press enter" onSelection={(tag) => this.setState({ value: (this.state.value && this.state.value instanceof Array) ? [...this.state.value, tag] : [tag] }, () => this.props.onChange({ target: { value: this.state.value, name: this.props.name } }))} onDelete={(tag) => this.setState({ value: this.state.value.filter((item) => item != tag) }, () => this.props.onChange({ target: { value: this.state.value, name: this.props.name } }))} value={this.state.value} />;
      case 'multiple-choice':
        return this.props.options.split(',').map((option, index) => {
          return <div className="row"><div className="col-12">
            <FormControlLabel key={`option-${index}`}
              control={
                <Switch
                  checked={this.state.value && this.state.value instanceof Array && this.state.value.map((val) => val.trim().toLowerCase()).includes(option.trim().toLowerCase())}
                  onChange={this.handleInputChange}
                  value={option.trim()}
                  color="primary"
                />
              }
              label={option}
            /></div></div>;
        });
        break;
      case 'single-choice':
        return <select className="form-control" name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name}>
          <option value="">Select...</option>
          {this.props.options.split(',').map((option, index) => <option key={`field-${this.props.id}-option-${index}`} value={option.trim()}>{option}</option>)}
        </select>;
      case 'long-text':
        return <textarea className="form-control" rows={4} name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name} />;
      case 'text':
      case 'alpha':
        return <input className="form-control" type="text" name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name} />;
      case 'number':
        return <input className="form-control" type="number" name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name} />;
      case 'email':
        return <input className="form-control" type="email" name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name} />;
      case 'phone':
        return <input className="form-control" type="tel" name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name} />;
      case 'date':
        return <input className="form-control" type="date" name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name} />;
      case 'time':
        return <input className="form-control" type="time" name={this.props.id} value={this.state.value} onChange={this.handleInputChange} disabled={this.props.disabled} placeholder={this.props.name} />;
      case 'scale':

        return <SliderWithTooltip
          onChange={(value) => this.handleInputChange({ target: { name: this.props.id, value } })}
          min={parseInt(this.props.settings.min)}
          max={parseInt(this.props.settings.max)}
        />;
      case 'html':
        return <div className="html-text-block" dangerouslySetInnerHTML={{ __html: this.props.settings.html }} />;
      case 'image':
        return <>{((this.state.value instanceof Array) ? this.state.value : []).map((photo) => <div key={`photo-${photo.id}`} className="dotted-container" style={{ marginBottom: '10px' }}>
          <div className="row">
            <div className="col-3">

              <img className="btn-image-preview" src={photo.thumbnail || photo.url} alt="Preview" />
            </div>
            <div className="col-6">{printPhotoType('photo')} <b>({(photo.exists && photo.date) || 'Today'})</b>
            </div>
            <div className="col-3 text-center">
              {!this.state.busy && <button className="delete" onClick={() => {
                this.setState({
                  value: this.state.value.filter((p) => p.id !== photo.id),
                });
              }}>x</button>}
            </div>
          </div>
        </div>)}
          <button disabled={this.state.busy} onClick={() => this.setState({ mode: 'image' })} className="btn btn-block btn-branded">Capture/Upload photo</button></>;
      case 'boolean':
        return <FormControlLabel
          control={
            <Switch
              checked={this.state.value}
              onChange={this.handleInputChange}
              value={this.state.value}
              color="primary"
            />
          }
          label={this.props.name}
        />;
    }
  }

  render() {
    return <div className="form-group nice">
      <label>{this.props.name}</label>
      {this.renderInput()}
      {this.state.mode === 'image' && <ImageGallery multiple={true} onImagesSelected={(images) => {
        this.setState({
          value: [...(this.state.value instanceof Array ? this.state.value : []), ...images],
          mode: 'default',
        }, () => this.props.onChange({ target: { value: this.state.value } }));
      }} onClose={() => this.setState({ mode: 'default' })} />}
      {this.state.mode === 'scan' && ReactDOM.createPortal(<ScansSelection onClose={() => this.setState({ mode: 'default' })} onScanSelected={(scan) => this.setState({ mode: 'default', value: scan }, () => this.props.onChange({ target: { value: scan } }))} />, document.getElementById('root'))}
    </div>;
  }
}

const mapStateToProps = (state) => {
  return state;
};
TemplateField.propTypes =
{
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { showMessage, showError, confirmAction, setCurrentScreen })(TemplateField);
