import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const PaginatedTable = (props) => {
  const onSort = (sortBy) => {
    let sortDirection = 'asc';
    if (sortBy === props.currentSortBy) {
      sortDirection = props.currentSortDirection === 'asc' ? 'desc' : 'asc';
    }

    props.onSort(sortBy, sortDirection);
  };

  return (
    <Table className={props.className}>
      <TableHead>
        <TableRow>
          {props.columns.map((column) => (
            // eslint-disable-next-line react/jsx-key
            <TableCell {...column.properties} sortDirection={props.currentSortDirection}>
              {column.sortBy ? (
                <TableSortLabel
                  direction={props.currentSortDirection}
                  active={column.sortBy === props.currentSortBy}
                  onClick={() => onSort(column.sortBy)}
                >
                  {column.name}
                </TableSortLabel>
              )
                : column.name
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!props.loading && props.rows.map((row) => <TableRow {...row.properties}>{row.columns.map((column) => <TableCell {...column.properties}>
          {column.value}
        </TableCell>)}
        </TableRow>)}
        {!props.loading && props.rows.length === 0 && <TableRow key="1">
          <TableCell colSpan={props.columns.length} align="center">
            {props.emptyMessage ? props.emptyMessage : 'No records found'}
          </TableCell>
        </TableRow>}
        {props.loading && <TableRow key="2">
          <TableCell colSpan={props.columns.length} align="center">
            <LinearProgress className="progressbar" color="primary" />
          </TableCell>
        </TableRow>}
      </TableBody>
      {props.count > 0 && <TableFooter>
        <TableRow>
          <TablePagination
            style={{ display: 'flex' }}
            rowsPerPageOptions={props.pageOptions ? props.pageOptions : [25]}
            colSpan={3}
            count={props.count}
            rowsPerPage={props.pageSize ? props.pageSize : 25}
            page={props.page ? props.page : 0}
            SelectProps={{
              native: true,
            }}
            onChangePage={(event, page) => props.goToPage(page)}
            onChangeRowsPerPage={(event) => {
              if (props.pageOptions && props.pageOptions.length > 1) props.onRowsPerPage(event.target.value);
            }}
          />
        </TableRow>
      </TableFooter>}
    </Table>
  );
};
export default PaginatedTable;
