import Http from '../utils/Http';
import AppConfig from '../config/env';

export default {
  account: null,
  isAuthorized(permission) {
    try {
      return this.account && (this.account.master || this.account[permission]);
    } catch (error) {
      return false;
    }
  },
  requireAuthorization(permission) {
    if (!this.account.master && !this.account[permission]) {
      this.logout();
      document.location.href = '/';
    }
  },
  appIsSigned() {
    return !!localStorage.getItem('dscore-signature');
  },
  login(token) {
    return new Promise((resolve, reject) => {
      Http.post('/auth/login', { token }).then((result) => {
        if (result && !result.data.error) {
          localStorage.setItem('dscore-token', result.data.data.token);
          localStorage.setItem('dscore-id', result.data.data.id);
          resolve(result.data.data.account);
        } else reject(false);
      }, (error) => {
        console.log(error);
        reject(false);
      }).catch((error) => {
        console.log(error);
        reject(false);
      });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      Http.post('/auth/logout').then((result) => {
        if (result && !result.data.error) resolve(true);
        else reject(false);
      }, (error) => {
        console.log(error);
        reject(false);
      }).catch((error) => {
        console.log(error);
        reject(false);
      });
    });
  },
  signApp() {
    return new Promise((resolve, reject) => {
      if (this.appIsSigned()) resolve(true);
      else {
        Http.get('/').then((result) => {
          if (result && !result.data.error) {
            localStorage.setItem('dscore-signature', result.data.data['dscore-client']);
            resolve(true);
          } else reject(false);
        }, (error) => reject(false)).catch((error) => reject(false));
      }
    });
  },
  authenticate() {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem('dscore-token') || !localStorage.getItem('dscore-id') || !localStorage.getItem('dscore-signature')) {
        reject(false);
      } else {
        Http.post('/auth/user').then((result) => {
          if (result.data.error) {
            reject(false);
          } else {
            this.account = result.data.data;
            resolve(result.data.data);
          }
        }, (error) => {
          reject(false);
        }).catch((error) => {
          reject(false);
        });
      }
    });
  },
};
