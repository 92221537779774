import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IndicatorButton from '../../components/IndicatorButton';
import ImageUpload from '../../components/ImageUpload';
import Validators from '../../utils/Validators';
import { showMessage, showError } from '../../actions/uiActions';
import PatientsApi from '../../api/patients';

class LesionRecord extends Component {
    state=
    {
      busy: false,
      location: '',
      valid_location: true,
      evaluation: 'other',
      device: 'low-risk',
      diagnosis: '',
      notes: '',
      clinical: '',
      dermatoscopic: '',
    }

    componentWillMount() {
      console.log(this.props);
    }

    handleInputChange=(e) => {
      this.setState({ [e.target.name]: e.target
        .value,
      [`valid_${e.target.name}`]: true });
    }

    register=() => {
      if (this.props.mode === 'create' && !this.state.valid_location) {
        this.setState({ valid_location: false });
        this.props.showError('Please correct the highlighted errors.');
      } else if (!this.props.lesion) this.props.showError('You must indicate a lesion.');
      else {
        this.setState({ busy: true });
        this.props.onGettingBusy();
        if (this.props.mode === 'create') {
          PatientsApi.addLesion(this.props.id, this.props.lesion, this.state.location, this.state.evaluation, this.state.device, this.state.diagnosis, this.state.clinical ? this.state.clinical.file : '', this.state.dermatoscopic ? this.state.dermatoscopic.file : '', this.state.notes).then((id) => {
            this.setState({ busy: false });
            this.props.showMessage('The lesion has been registered successfully!');
            this.props.onNoLongerBusy();
            this.props.onLesionRegistered(id);
          }, (error) => {
            this.setState({ busy: false });
            this.props.showError();
            this.props.onNoLongerBusy();
          }).catch((error) => {
            this.setState({ busy: false });
            this.props.showError();
            this.props.onNoLongerBusy();
          });
        } else if (this.props.mode === 'record') {
          PatientsApi.addRecord(this.props.lesion.id, this.state.location, this.state.evaluation, this.state.device, this.state.diagnosis, this.state.clinical ? this.state.clinical.file : '', this.state.dermatoscopic ? this.state.dermatoscopic.file : '', this.state.notes).then((id) => {
            this.setState({ busy: false });
            this.props.showMessage('The new record has been registered successfully!');
            this.props.onNoLongerBusy();
            this.props.onRecordRegistered(id);
          }, (error) => {
            this.setState({ busy: false });
            this.props.showError();
            this.props.onNoLongerBusy();
          }).catch((error) => {
            this.setState({ busy: false });
            this.props.showError();
            this.props.onNoLongerBusy();
          });
        }
      }
    }

    render() {
      return <div className="row">
            <div className="col-12">
            <div className="row">
            {this.props.mode === 'create' && <div className="col-6 text-left">
                    <div className="form-group nice">
                        <label>Lesion Location:</label>
                        <input disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} type="text" value={this.state.location} name="location" className="form-control" placeholder="Ex: Left Shoulder" />
                        {!this.state.valid_location && <div className="input-error animated zoomIn">Enter the Lesion Location</div>}
                    </div>
                </div>}
                <div className="col-6">
                            <div className="form-group nice">
                                <label className="text-left">Initial Impression</label>
                                <input disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="diagnosis" value={this.state.diagnosis} type="text" className="form-control" placeholder="Initial Impression" />
                            </div>
                        </div>
            </div>
            <div className="row">
                        <div className="col-6">
                            <div className="form-group nice">
                                <label className="text-left">Where was patient evaluated?</label>
                                <select disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="evaluation" value={this.state.evaluation} className="form-control">
                                    <option value="other">Select...</option>
                                    <option value="clinic">Clinic</option>
                                    <option value="tele-consult">Tele-Consult</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group nice">
                                <label className="text-left">DermaSensor Device Results</label>
                                <select disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="device" value={this.state.device} className="form-control">
                                    <option value="low-risk">Low Risk</option>
                                    <option value="high-risk">High Risk</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <h6 className="text-left">Lesion Photos</h6>
                    <div className="row">
                        <div className="col-6">
                            <h7 className="text-left">Clinical</h7>
                            <ImageUpload disabled={this.props.disabled || this.state.busy} onError={() => this.props.showError()} onUpload={PatientsApi.uploadImage} onChange={(image) => {
                              this.setState({ clinical: image });
                            }} name="clinical" upload_name="image" value={this.state.clinical} multiple={false} />
                        </div>
                        <div className="col-6">
                        <h7 className="text-left">Dermatoscopic</h7>
                        <ImageUpload disabled={this.props.disabled || this.state.busy} onError={() => this.props.showError()} onUpload={PatientsApi.uploadImage} onChange={(image) => {
                          this.setState({ dermatoscopic: image });
                        }} name="dermatoscopic" upload_name="image" value={this.state.dermatoscopic} multiple={false} />
                        </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group nice">
                                <label className="text-left">Notes/Comments:</label>
                                <textarea disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="notes" value={this.state.notes} className="form-control" placeholder="Notes/Comments" rows={2}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                    {this.props.lesion && !this.props.disabled && <IndicatorButton className="btn-material btn-primary" onClick={this.register} disabled={this.state.busy}>{this.props.mode === 'create' ? 'Register Lesion' : 'Add Record'}</IndicatorButton>}
                    <div className="line-break"></div>
                    </div>
            </div>
        </div>;
    }
}
const mapStateToProps = (state) => {
  return state;
};
LesionRecord.propTypes =
    {
      showMessage: PropTypes.func.isRequired,
      showError: PropTypes.func.isRequired,
    };
export default connect(mapStateToProps, { showMessage, showError })(LesionRecord);
