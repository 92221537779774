import { LOGOUT, AUTHENTICATION_SUCCESS, AUTHENTICATE, AUTHENTICATION_FAILED } from './types';
import Auth from '../api/auth';
import AppConfig from '../config/env';

export function logout() {
  return function (dispatch) {
    Auth.logout().then((success) => {
      document.location.href = `${AppConfig.get('accounts_app_url')}/logout`;
      Auth.logout();
      dispatch(
        {
          type: LOGOUT,
          payload: null,
        },
      );
    }, (error) => {
      dispatch(
        {
          type: LOGOUT,
          payload: null,
        },
      );
    }).catch((error) => {
      dispatch(
        {
          type: LOGOUT,
          payload: null,
        },
      );
    });
  };
}
export function authenticating() {
  return function (dispatch) {
    dispatch(
      {
        type: AUTHENTICATE,
        payload: null,
      },
    );
  };
}

export function authenticate(token) {
  return function (dispatch) {
    Auth.login(token).then((account) => {
      dispatch(
        {
          type: AUTHENTICATION_SUCCESS,
          payload: account,
        },
      );
      // document.location.href=AppConfig.APP_URL;
    }, (error) => {
      dispatch(
        {
          type: LOGOUT,
          payload: null,
        },
      );
      localStorage.clear();
      document.location.href = `${AppConfig.get('accounts_app_url')}/auth/${AppConfig.get('app_public_key')}`;
    }).catch((error) => {
      dispatch(
        {
          type: LOGOUT,
          payload: null,
        },
      );
      localStorage.clear();
      document.location.href = `${AppConfig.get('accounts_app_url')}/auth/${AppConfig.get('app_public_key')}`;
    });
  };
}

export function verifyAuthentication() {
  return function (dispatch) {
    Auth.authenticate().then((account) => dispatch(
      {
        type: AUTHENTICATION_SUCCESS,
        payload: account,
      },
    ), (error) => {
      localStorage.clear();
      document.location.href = `${AppConfig.get('accounts_app_url')}/auth/${AppConfig.get('app_public_key')}`;
      dispatch(
        {
          type: LOGOUT,
          payload: null,
        },
      );
    }).catch((error) => {
      localStorage.clear();
      document.location.href = `${AppConfig.get('accounts_app_url')}/auth/${AppConfig.get('app_public_key')}`;
      dispatch(
        {
          type: LOGOUT,
          payload: null,
        },
      );
    });
  };
}
