import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import Cancel from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
import { showMessage, showError } from '../../actions/uiActions';
import WysiwygEditor from '../../components/WysiwygEditor';

class HTMLTextBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: props.html || '',
    };
  }

    save=() => {
      if (!this.state.html) {
        return this.props.showError('Please enter the HTML text.');
      }
      return this.props.onConfirm(this.state.html);
    }

    onChange=(html) => {
      this.setState({ html });
    }

    render() {
      return <Dialog maxWidth="lg" fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" open={true}
        >
        <DialogTitle id="alert-dialog-title">HTML Text Block
        <IconButton onClick={this.props.onClose} className="pull-right">
          <Cancel />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                <div className="form-group nice">
                    <WysiwygEditor value={this.state.html} onChange={this.onChange} />
                </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.save} color="primary" autoFocus>
            Save
          </Button>
          <Button onClick={this.props.onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
        </Dialog>;
    }
}

export default connect(null, { showMessage, showError })(HTMLTextBlock);
