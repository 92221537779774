import { CircularProgress, Button } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showError } from '../../actions/uiActions';
import PatientsApi from '../../api/patients';

class PandadocDownload extends Component {
    state={
      downloading: false,
    }

    download=() => {
      this.setState({ downloading: true }, async () => {
        try {
          await PatientsApi.downloadRecordEsign(this.props.recordId, this.props.id, this.props.name);
        } catch (error) {
          console.log(error);
          this.props.showError('There has been an error downloading the document, please try again.');
        } finally {
          return this.setState({ downloading: false });
        }
      });
    }

    render() {
      return this.state.downloading ? <span><CircularProgress size={16} /> Downloading...</span> : <Button onClick={this.download} variant="outlined" color="primary">Download Signed Document</Button>;
    }
}
export default connect(null, { showError })(PandadocDownload);
