import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';

import ArrowBack from '@material-ui/icons/ArrowBack';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton, Paper, Table, TableHead, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Visibility } from '@material-ui/icons';
import { TEMPLATE_FIELD_TYPES, RULES_ELIGIBLE_FIELD_TYPES } from '../../utils/data';
import PatientsApi from '../../api/patients';
import { showMessage, showError } from '../../actions/uiActions';
import Validators from '../../utils/Validators';
import IndicatorButton from '../../components/IndicatorButton';
import PandadocSettings from '../PandadocSettings';
import TemplateFieldRules from '../../components/TemplateFieldRules';
import HTMLTextBlock from '../HTMLTextBlock';

class PatientFields extends Component {
  state = {
    defaultFields: {},
    fields: [{
      id: Date.now(),
      name: '',
      type: '',
      mandatory: false,
      new: true,
      options: '',
      settings: {
        id: Date.now(),
      },
    }],
    busy: true,
    pandadocField: -1,
    htmlField: -1,
    rules: null,
  }

  handleDefaultFieldChange = (field, e) => {
    const fieldValue = this.state.defaultFields.hasOwnProperty(field) ? this.state.defaultFields[field] : {
      mandatory: true,
      enabled: true,
    };
    fieldValue[e.target.name] = e.target.checked;

    this.setState({
      defaultFields: { ...this.state.defaultFields, [field]: fieldValue },
    });
  }

  async componentDidMount() {
    try {
      const settings = await PatientsApi.getProfileTemplate();
      this.setState({
        ...settings,
        fields: [...settings.fields.map((field) => {
          field.settings = field.settings || { id: field.id };
          return field;
        }), {
          id: Date.now(),
          name: '',
          type: '',
          mandatory: false,
          new: true,
          options: '',
          settings: {
            id: Date.now(),
          },
        }],
      });
    } catch (error) {

    } finally {
      this.setState({ busy: false });
    }
  }

  submit = () => {
    let hasErrors = false;

    this.state.fields.forEach((field, index) => {
      if (!hasErrors && index < (this.state.fields.length - 1)) {
        if (!field.name) {
          this.props.showError(`Please enter the name of the custom field #${index + 1}`);
          hasErrors = true;
        } else if (!field.type) {
          this.props.showError(`Please select the type of the custom field #${index + 1}`);
          hasErrors = true;
        } else if ((field.type === 'single-choice' || field.type === 'multiple-choice') && field.options.split(',').filter((val) => (!!val)).length === 0) {
          this.props.showError(`Please enter comma-separated options for custom field #${index + 1}`);
          hasErrors = true;
        } else if (field.type === 'scale' && (isNaN(field.settings.min) || isNaN(field.settings.max) || field.settings.min == field.settings.max)) {
          this.props.showError(`Please enter minimum and maximum values for the number scale field #${index + 1}`);
          hasErrors = true;
        }
      }
    });
    if (!hasErrors) {
      this.setState({ busy: true }, async () => {
        try {
          await PatientsApi.setProfileTemplate(this.state.defaultFields, this.state.fields.slice(0, this.state.fields.length - 1));
          this.props.showMessage('Patient profile template updated successfully!');
        } catch (error) {
          this.props.showError();
        } finally {
          this.setState({ busy: false });
        }
      });
    }
  }

  handleFieldInputChange = (index, fieldName, value) => {
    if (value === 'pandadoc') {
      return this.setState({ pandadocField: index });
    } if (value === 'html') {
      return this.setState({ htmlField: index });
    }
    const fields = this.state.fields.map((field, i) => {
      if (i === index) {
        field[fieldName] = value;
      }
      return field;
    });
    if (index === (this.state.fields.length - 1)) {
      fields.push({
        id: Date.now(),
        name: '',
        type: '',
        mandatory: false,
        new: true,
        options: '',
        settings: {
          id: Date.now(),
        },
      });
    }
    this.setState({ fields });
  }

  handleHtmlSettings = (html) => {
    const fields = this.state.fields.map((field, i) => {
      if (i === this.state.htmlField) {
        return {
          ...field,
          type: 'html',
          settings: {
            ...field.settings,
            html,
          },
        };
      }
      return field;
    });
    if (this.state.htmlField === (this.state.fields.length - 1)) {
      fields.push({
        id: Date.now(),
        name: '',
        type: '',
        mandatory: false,
        new: true,
        options: '',
        settings: {
          id: Date.now(),
        },
      });
    }
    this.setState({ fields, htmlField: -1 });
  }

  handleFieldSettingsChange = (index, setting, value) => {
    this.setState({
      fields: this.state.fields.map((field, i) => {
        if (i === index) {
          field.settings[setting] = value;
        }
        return field;
      }),
    });
  }

  handlePandadocSettings = (settings) => {
    const fields = this.state.fields.map((field, i) => {
      if (i === this.state.pandadocField) {
        return {
          ...field,
          type: 'pandadoc',
          ...settings,
          settings: {
            ...field.settings,
            ...settings,
          },
        };
      }
      return field;
    });
    if (this.state.pandadocField === (this.state.fields.length - 1)) {
      fields.push({
        id: Date.now(),
        name: '',
        type: '',
        mandatory: false,
        new: true,
        options: '',
        settings: {
          id: Date.now(),
        },
      });
    }
    this.setState({ fields, pandadocField: -1 });
  }

  swapUp = (index) => {
    const tmp = { ...this.state.fields[index - 1] };
    this.setState({
      fields: this.state.fields.map((field, i) => {
        if (i === (index - 1)) {
          return { ...this.state.fields[index] };
        } if (i === index) {
          return tmp;
        }
        return field;
      }),
    });
  }

  swapDown = (index) => {
    const tmp = { ...this.state.fields[index] };
    this.setState({
      fields: this.state.fields.map((field, i) => {
        if (i === (index + 1)) {
          return tmp;
        } if (i === index) {
          return { ...this.state.fields[index + 1] };
        }
        return field;
      }),
    });
  }

  setRules = (field) => {
    if (!field.name) {
      return this.props.showError('Please enter the name of the field.');
    } if (this.getValidFields(field.id).length === 0) {
      return this.props.showError('You must have at least another valid named field.');
    }
    return this.setState({ rules: field });
  }

  getValidFields = (id) => {
    const result = this.state.fields.filter((field) => field.id !== id && field.name && RULES_ELIGIBLE_FIELD_TYPES.includes(field.type) && (field.type !== 'single-choice' || field.type !== 'multiple-choice' || Boolean(field.options)));
    return result;
  };

  render() {
    return <><Dialog fullScreen={true} fullWidth={true} open={true}>
      <DialogTitle id="alert-dialog-title">{!this.state.busy && <IconButton className="white-content" onClick={this.props.onClose}><ArrowBack /></IconButton>} Patient Profile Template

        </DialogTitle>
      <DialogContent>
        {this.state.pandadocField !== -1 && <PandadocSettings onCancel={() => this.setState({ pandadocField: -1 })} onClose={() => this.setState({ pandadocField: -1 })} onConfirm={this.handlePandadocSettings} {...this.state.fields[this.state.pandadocField].settings} {...this.state.fields[this.state.pandadocField]} />}
        {this.state.htmlField !== -1 && <HTMLTextBlock onCancel={() => this.setState({ htmlField: -1 })} onClose={() => this.setState({ htmlField: -1 })} {...this.state.fields[this.state.htmlField].settings} onConfirm={this.handleHtmlSettings} />}
        <div className="line-break"></div>
        <div className="row">
          <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-10 offset-1">
            {this.props.auth.account.settings.defaultFields && <>
              {/* <h5>Default Fields</h5>
                    <Paper>
                            <Table className="light">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Field Name
                                        </TableCell>
                                        <TableCell>
                                           Enabled
                                        </TableCell>
                                        <TableCell>
                                            Mandatory
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            First Name
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={true}
                                            onChange={(e)=>this.handleDefaultFieldChange("firstname",e)}
                                            value={"firstname"}
                                            color="primary"
                                            name="enabled"
                                            disabled={true}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={true}
                                            onChange={(e)=>this.handleDefaultFieldChange("firstname",e)}
                                            value={"firstname"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={true}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Last Name
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={true}
                                            onChange={(e)=>this.handleDefaultFieldChange("lastname",e)}
                                            value={"lastname"}
                                            color="primary"
                                            name="enabled"
                                            disabled={true}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={true}
                                            onChange={(e)=>this.handleDefaultFieldChange("lastname",e)}
                                            value={"lastname"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={true}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Email Address
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("email")||this.state.defaultFields.email.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("email",e)}
                                            value={"email"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("email")||(this.state.defaultFields.email.enabled&&this.state.defaultFields.email.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("email",e)}
                                            value={"email"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Phone Number
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("phone")||this.state.defaultFields.phone.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("phone",e)}
                                            value={"phone"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("phone")||(this.state.defaultFields.phone.enabled&&this.state.defaultFields.phone.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("phone",e)}
                                            value={"phone"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        EMR/EHR ID
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("emr")||this.state.defaultFields.emr.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("emr",e)}
                                            value={"emr"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("emr")||(this.state.defaultFields.emr.enabled&&this.state.defaultFields.emr.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("emr",e)}
                                            value={"emr"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        Date of birth
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("dob")||this.state.defaultFields.dob.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("dob",e)}
                                            value={"dob"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("dob")||(this.state.defaultFields.dob.enabled&&this.state.defaultFields.dob.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("dob",e)}
                                            value={"dob"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        Gender
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("gender")||this.state.defaultFields.gender.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("gender",e)}
                                            value={"gender"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("gender")||(this.state.defaultFields.gender.enabled&&this.state.defaultFields.gender.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("gender",e)}
                                            value={"gender"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        Skin type
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("skin")||this.state.defaultFields.skin.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("skin",e)}
                                            value={"skin"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("skin")||(this.state.defaultFields.skin.enabled&&this.state.defaultFields.skin.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("skin",e)}
                                            value={"skin"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        Ethnicity
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("ethnicity")||this.state.defaultFields.ethnicity.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("ethnicity",e)}
                                            value={"ethnicity"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("ethnicity")||(this.state.defaultFields.ethnicity.enabled&&this.state.defaultFields.ethnicity.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("ethnicity",e)}
                                            value={"ethnicity"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                        Skin Cancer History
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("history")||this.state.defaultFields.history.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("history",e)}
                                            value={"history"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("personal")||(this.state.defaultFields.history.enabled&&this.state.defaultFields.personal.mandatory)}
                                            onChange={(e)=>this.handleDefaultFieldChange("personal",e)}
                                            value={"personal"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={true}
                                        />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                        Patient source
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("source")||this.state.defaultFields.source.enabled}
                                            onChange={(e)=>this.handleDefaultFieldChange("source",e)}
                                            value={"source"}
                                            color="primary"
                                            name="enabled"
                                            disabled={this.state.busy}
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                            checked={!this.state.defaultFields.hasOwnProperty("source")||this.state.defaultFields.source.mandatory}
                                            onChange={(e)=>this.handleDefaultFieldChange("source",e)}
                                            value={"source"}
                                            color="primary"
                                            name="mandatory"
                                            disabled={true}
                                        />
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                                </Table>
                    </Paper></>}
                    {this.props.auth.account.settings.patientTemplates&&<> */}
              <h5 style={{ marginTop: '40px' }}>Custom Fields</h5>
              <Paper>
                <Table className="light">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '35%' }}>
                        Field Name
                                        </TableCell>
                      <TableCell style={{ width: '35%' }}>
                        Field Type
                                        </TableCell>
                      <TableCell>
                        Mandatory
                                        </TableCell>
                      <TableCell>
                        &nbsp;
                                        </TableCell>
                      <TableCell>
                        &nbsp;
                                        </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.fields.map((field, index) => <TableRow key={`template-field-${index}`}>
                      <TableCell>
                        <input value={field.name} autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="Field Name" name="name" onChange={(e) => this.handleFieldInputChange(index, 'name', e.target.value)} />
                      </TableCell>
                      <TableCell>
                        <select disabled={this.state.busy} value={field.type} className="form-control" onChange={(e) => this.handleFieldInputChange(index, 'type', e.target.value)}>
                          <option value="">Select...</option>
                          {Object.keys(TEMPLATE_FIELD_TYPES).map((key, index) => <option value={key} key={`new-field-type-${index}`}>{TEMPLATE_FIELD_TYPES[key]}</option>)}
                        </select>
                        {(field.type === 'single-choice' || field.type === 'multiple-choice') && <input value={field.options} autoComplete="off" disabled={this.state.busy} type="text" className="form-control inline" placeholder="Comma-separated options" name="options" onChange={(e) => this.handleFieldInputChange(index, 'options', e.target.value)} />}
                        {field.type === 'scale' && <div className="row" style={{ marginTop: '10px' }}>
                          <div className="col-6">
                            <input type="number" value={field.settings.min} className="form-control" placeholder="Min. Value" onChange={(e) => this.handleFieldSettingsChange(index, 'min', e.target.value)} />
                          </div>
                          <div className="col-6">
                            <input type="number" value={field.settings.max} className="form-control" placeholder="Max. Value" onChange={(e) => this.handleFieldSettingsChange(index, 'max', e.target.value)} />
                          </div>
                        </div>}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={field.mandatory}
                          onChange={(e) => this.handleFieldInputChange(index, 'mandatory', e.target.checked)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        {index !== (this.state.fields.length - 1) && <Tooltip title="Set visibility rules"><IconButton onClick={() => this.setRules(field)}><Visibility /></IconButton></Tooltip>}
                        {index !== (this.state.fields.length - 1) && <IconButton className="basic-button" onClick={() => this.setState({ fields: this.state.fields.filter((f, i) => i !== index) })}><Delete /></IconButton>}
                        {field.type === 'pandadoc' && <Tooltip title="Edit Pandadoc Settings"><IconButton onClick={() => this.setState({ pandadocField: index })}><Edit /></IconButton></Tooltip>}
                        {field.type === 'html' && <Tooltip title="Edit HTML Text block"><IconButton onClick={() => this.setState({ htmlField: index })}><Edit /></IconButton></Tooltip>}
                      </TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {index !== (this.state.fields.length - 1) && index > 0 && <Tooltip placement="top" title="Move up">
                            <IconButton onClick={() => this.swapUp(index)} className="basic-button" disableFocusRipple={true} disableRipple={true}>
                              <KeyboardArrowUp />
                            </IconButton>
                          </Tooltip>}
                          {index < (this.state.fields.length - 2) && this.state.fields.length >= 3 && <Tooltip title="Move down">
                            <IconButton onClick={() => this.swapDown(index)} className="basic-button" disableFocusRipple={true} disableRipple={true}>
                              <KeyboardArrowDown />
                            </IconButton>
                          </Tooltip>}
                        </div>
                      </TableCell>
                    </TableRow>)}
                  </TableBody>
                </Table>
              </Paper></>}
            <div className="line-break"></div>
            <div className="form-group text-center">
              <IndicatorButton className="btn-material btn-primary" onClick={this.submit} disabled={this.state.busy}>Update Patient Profile Template</IndicatorButton>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>{this.state.rules && <TemplateFieldRules onCancel={() => this.setState({ rules: null })} field={this.state.rules} fields={this.getValidFields(this.state.rules.id)} onSubmit={(rules) => {
      this.setState({
        fields: this.state.fields.map((field) => {
          if (field.id === this.state.rules.id) {
            field.settings = {
              ...field.settings,
              rules,
            };
          }
          return field;
        }),
        rules: null,
      });
    }} />}</>;
  }
}

const mapStateToProps = (state) => {
  return state;
};
PatientFields.propTypes =
{
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { showMessage, showError })(PatientFields);
