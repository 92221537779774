import FileDownload from 'js-file-download';
import Http from '../utils/Http';
import Auth from './auth';

export default
{
  downloadTemplateRecords: async (templateId, templateName, start, end, patientId = '') => {
    try {
      const result = await Http.download(`/records/${templateId}/${start}/${end}${patientId ? `/${patientId}` : ''}`);
      FileDownload(
        result.data,
        `${templateName}-${start.replaceAll('-', '.')}-${end.replaceAll('-', '.')}.csv`,
      );
      return true;
    } catch (error) {
      throw error;
    }
  },
  load(page = 0, search = '') {
    return new Promise((resolve, reject) => {
      Http.get(`/templates/${page}?search=${search}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  createTemplate(name, fields) {
    return new Promise((resolve, reject) => {
      Http.post('/templates', { name, fields }).then((result) => resolve(result.data.data), (error) => {
        if (error.response) {
          reject(error.response.status);
        } else {
          reject(500);
        }
      }).catch((error) => reject(500));
    });
  },
  updateTemplate(id, name, fields) {
    return new Promise((resolve, reject) => {
      Http.put(`/template/${id}`, { name, fields }).then((result) => resolve(result.data.data), (error) => {
        if (error.response) {
          reject(error.response.status);
        } else {
          reject(500);
        }
      }).catch((error) => reject(500));
    });
  },
  get(id) {
    return new Promise((resolve, reject) => {
      Http.get(`/template/${id}`).then((result) => {
        if (result && !result.data.error) resolve(result.data.data);
        else reject('Error');
      }, (error) => reject(error)).catch((error) => reject(error));
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      Http.delete(`/template/${id}`).then((result) => resolve(true), (error) => reject(error)).catch((error) => reject(error));
    });
  },
  getList: async () => {
    try {
      const response = await Http.get('/templates');
      return response.data.data;
    } catch (error) {
      return [];
    }
  },
};
