import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ReactDOM from 'react-dom';
import Delete from '@material-ui/icons/Delete';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';

class ImageUpload extends Component {
    state=
    {
      uploading: false,
      files: [],
      file: '',
      fileName: '',
      image: '',
    };

    componentWillMount() {
      if (this.props.value && ((this.props.value instanceof Array && this.props.value.length > 0) || this.props.value.hasOwnProperty('file'))) this.setState({ files: this.props.value instanceof Array ? this.props.value : [this.props.value], file: !this.props.multiple ? (this.props.value instanceof Array ? this.props.value[0].file : this.props.value.file) : '', image: !this.props.multiple ? (this.props.value instanceof Array ? this.props.value[0].image : this.props.value.image) : '', fileName: !this.props.multiple ? (this.props.value instanceof Array ? this.props.value[0].fileName : this.props.value.fileName) : '' });
    }

    componentWillReceiveProps(newProps) {
      if (newProps.value && ((newProps.value instanceof Array && newProps.value.length > 0) || newProps.value.hasOwnProperty('file'))) this.setState({ files: newProps.value instanceof Array ? newProps.value : [newProps.value], file: !newProps.multiple ? (newProps.value instanceof Array ? newProps.value[0].file : newProps.value.file) : '', image: !newProps.multiple ? (newProps.value instanceof Array ? newProps.value[0].image : newProps.value.image) : '', fileName: !newProps.multiple ? (newProps.value instanceof Array ? newProps.value[0].fileName : newProps.value.fileName) : '' });
    }

    openFileDialog =() => {
      if (!this.state.uploading) {
        const fileInputDom = ReactDOM.findDOMNode(this.refs.fileUpload);
        fileInputDom.click();
      }
    }

      handleFile=(event) => {
        if (event.target.files && event.target.files.length) {
          const imageId = Date.now();
          this.setState({ fileName: event.target.files[0].name, uploading: true, id: imageId });
          const formData = new FormData();
          formData.append(this.props.upload_name ? this.props.upload_name : this.props.name, event.target.files[0], event.target.files[0].name);
          this.props.onUpload(formData).then((uploadedFile) => {
            this.setState({ uploading: false, image: '', file: '', fileName: '', files: [...this.state.files, { file: uploadedFile, fileName: this.state.fileName, image: this.state.image, id: imageId }] });
            this.props.onChange(this.props.multi ? this.state.files : this.state.files[0]);
          }, (error) => {
            this.setState({ file: '', fileName: '', uploading: false });
            this.props.onError();
          }).catch((error) => {
            this.setState({ file: '', fileName: '', uploading: false });
            this.props.onError();
          });
          const reader = new FileReader();
          reader.onload = (e) => {
            this.setState({ image: e.target.result }, () => {
              if (this.state.files.find((image) => image.id === this.state.id)) {
                this.setState({ files: this.state.files.map((image) => {
                  if (image.id === this.state.id) image.image = this.state.image;
                  return image;
                }) });
              }
            });
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      }

      handleDelete=() => {
        this.setState({ file: '', fileName: '' });
      }

      render() {
        return (
            <div>
                {this.state.files.map((image) => <div className="imageupload-uploaded-image-container" key={image.id} style={{ width: `${this.props.width ? this.props.width : 200}px`, height: `${this.props.height ? this.props.width : 200}px` }}>
                <img style={{ width: `${this.props.width ? this.props.width : 200}px`, height: `${this.props.height ? this.props.width : 200}px` }} src={image.image} alt="Preview" />
                <Button color="primary" className="delete" variant="fab" aria-label="Add" onClick={() => {
                  this.setState({ files: this.state.files.filter((file) => file.id != image.id) }, () => this.props.onChange(this.state.files));
                }} >
                    <Delete />
                </Button>
                </div>)}
                {(this.props.multiple || this.state.files.length == 0) && <div className={`imageupload-select-container ${this.props.containerClass ? this.props.containerClass : ''} ${this.state.uploading ? 'uploading' : 'select'}`} onClick={this.openFileDialog}>
                {!this.state.uploading && <div className="placeholder" style={{ width: `${this.props.width ? this.props.width : 200}px`, height: `${this.props.height ? this.props.height : 200}px` }}>
                <div className="icon"><AddAPhoto /></div>
                {this.props.placeholder || 'Select image...'}
                </div>}
                {this.state.uploading && this.state.image && <div className="image-preview">
                <img style={{ width: `${this.props.width ? this.props.width : 200}px`, height: `${this.props.height ? this.props.height : 200}px` }} src={this.state.image} alt="Preview" />
                <div className="cover" style={{ width: `${this.props.width ? this.props.width : 200}px`, height: `${this.props.height ? this.props.height : 200}px` }}>
                <CircularProgress className="progressbar" />
                </div>
                </div>}
                <input
                type='file'
                multiple={this.props.multi}
                ref='fileUpload'
                style={{ display: 'none' }}
                accept={this.props.accept}
                onChange={this.handleFile} />
                </div>}
            </div>
        );
      }
}

export default ImageUpload;
