import React, { Component } from 'react';
import './style.css';
import { ArrowUpward, ArrowDownward, Visibility, VisibilityOff } from '@material-ui/icons';
import MiniBody from './MiniBody';

export default class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: '',
      position: 'front',
      showLesions: true,
    };
  }

  getEventObject = (event) => {
    return event.touches && event.touches.length > 0 ? { ...event, clientX: event.touches[0].clientX, clientY: event.touches[0].clientY } : event;
  }

  onLesion = (e) => {
    if (!this.props.disabled) {
      const event = this.getEventObject(e);
      const boundingRectangle = event.target.getBoundingClientRect();
      const lesion = {
        section: this.state.section,
        x: event.clientX - boundingRectangle.left,
        y: event.clientY - boundingRectangle.top,
        position: this.state.position,
      };
      this.setState({
        lesions: this.props.multi ? [...this.state.lesions, lesion] : [lesion],
      }, () => this.props.onLesionAdded(lesion));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeDot !== this.props.activeDot) {
      const dot = this.props.lesions.find((lesion) => lesion.id == this.props.activeDot);
      if (dot) {
        this.setState({
          section: dot.section,
          position: dot.position,
        });
      }
    }
  }

  selectSection = (section) => {
    this.setState({ section });
  }

  getLesionClass = (dot) => {
    if (!dot.id || (dot.id == this.props.activeDot && !this.props.lesions.find((dot) => (!dot.id)))) {
      return 'active';
    } if (dot.id) {
      return 'inactive';
    }
  }

  renderSectionLesions = () => {
    return this.props.lesions.filter((lesion) => lesion.section === this.state.section && lesion.position === this.state.position && (this.state.showLesions || !lesion.id)).map((lesion, index) => <div onClick={(e) => {
      e.stopPropagation();
      if (lesion.id) {
        this.props.onLesionSelected(lesion);
      }
    }} key={`lesion-${index}`} style={{ left: `${lesion.x - 7.5}px`, top: `${lesion.y - 7.5}px` }} className={`dot ${this.getLesionClass(lesion)}`}></div>);
  }

  renderSection = () => {
    return <div className={'body-part-selected'}>
      <MiniBody section={this.state.section} onClose={() => this.setState({ section: '' })} />
      <div className={this.state.section} onClick={this.onLesion}>
        {this.renderSectionLesions()}
      </div>
    </div>;
  }

  renderBodyPartLesions = (section, sectionWidth, partWidth) => {
    const percent = ((partWidth * 100) / sectionWidth) / 100;
    return this.props.lesions.filter((lesion) => lesion.section === section && lesion.position === this.state.position && (this.state.showLesions || !lesion.id)).map((lesion, index) => <div key={`lesion-${index}`} style={{ left: `${Math.floor(lesion.x * percent) - 2.5}px`, top: `${Math.floor(lesion.y * percent) - 2.5}px` }} className={`dot ${this.getLesionClass(lesion)}`}></div>);
  }

  renderBodyParts = () => {
    return <div className="body-parts-container"><div className={'head body-part'} onClick={() => this.selectSection('head')}>
      {this.renderBodyPartLesions('head', 251, 53.6)}
    </div>
      <div className="neck body-part" onClick={() => this.selectSection('neck')}>
        {this.renderBodyPartLesions('neck', 300, 36.5)}
      </div>
      <div className="chest body-part" onClick={() => this.selectSection('chest')}>
        {this.renderBodyPartLesions('chest', 350, 148)}
      </div>
      <div className="left-bicep body-part" onClick={() => this.selectSection('left-bicep')}>
        {this.renderBodyPartLesions('left-bicep', 233, 35)}
      </div>
      <div className="right-bicep body-part" onClick={() => this.selectSection('right-bicep')}>
        {this.renderBodyPartLesions('right-bicep', 233, 35)}
      </div>
      <div className="belly body-part" onClick={() => this.selectSection('belly')}>
        {this.renderBodyPartLesions('belly', 256, 80)}
      </div>
      <div className="left-forearm body-part" onClick={() => this.selectSection('left-forearm')}>
        {this.renderBodyPartLesions('left-forearm', 175, 27.5)}
      </div>
      <div className="left-hand body-part" onClick={() => this.selectSection('left-hand')}>
        {this.renderBodyPartLesions('left-hand', 238, 24.3)}
      </div>
      <div className="right-forearm body-part" onClick={() => this.selectSection('right-forearm')}>{this.renderBodyPartLesions('right-forearm', 175, 27.5)}</div>
      <div className="right-hand body-part" onClick={() => this.selectSection('right-hand')}>
        {this.renderBodyPartLesions('right-hand', 238, 24.3)}
      </div>
      <div className="waist body-part" onClick={() => this.selectSection('waist')}>
        {this.renderBodyPartLesions('waist', 300, 92.8)}
      </div>
      <div className="upper-left-leg body-part" onClick={() => this.selectSection('upper-left-leg')}>
        {this.renderBodyPartLesions('upper-left-leg', 243, 45.2)}
      </div>
      <div className="bottom-left-leg body-part" onClick={() => this.selectSection('bottom-left-leg')}>
        {this.renderBodyPartLesions('bottom-left-leg', 159, 37.5)}
      </div>
      <div className="left-foot body-part" onClick={() => this.selectSection('left-foot')}>
        {this.renderBodyPartLesions('left-foot', 275, 28)}
      </div>
      <div className="upper-right-leg body-part" onClick={() => this.selectSection('upper-right-leg')}>
        {this.renderBodyPartLesions('upper-right-leg', 243, 45.2)}
      </div>
      <div className="bottom-right-leg body-part" onClick={() => this.selectSection('bottom-right-leg')}>
        {this.renderBodyPartLesions('bottom-right-leg', 159, 37.5)}
      </div>
      <div className="right-foot body-part" onClick={() => this.selectSection('right-foot')}>
        {this.renderBodyPartLesions('right-foot', 275, 28)}</div></div>;
  }

  getCurrentLesionIndex = () => {
    return this.props.lesions.reduce((index, lesion, currentIndex) => (lesion.id == this.props.activeDot ? currentIndex : index), -1);
  }

  next = () => {
    const lesion = this.props.lesions.filter((dot) => (!!dot.id))[this.getCurrentLesionIndex() + 1];
    if (lesion) {
      this.props.onLesionSelected(lesion);
    }
  }

  prev = () => {
    const lesion = this.props.lesions.filter((dot) => (!!dot.id))[this.getCurrentLesionIndex() - 1];
    if (lesion) {
      this.props.onLesionSelected(lesion);
    }
  }

  renderControls = () => {
    const hasPrev = this.getCurrentLesionIndex() > 0;
    const hasNext = this.getCurrentLesionIndex() < (this.props.lesions.length - 1);
    return <div className="controls">
      {this.props.lesions.length > 1 && [<div className={`button-container ${!hasPrev ? 'disabled' : ''}`}>
        <button onClick={this.prev} disabled={!hasPrev}>
          <ArrowUpward />
        </button>
                Prev. Lesion
            </div>, <div className={`button-container ${!hasNext ? 'disabled' : ''}`}>
        <button onClick={this.next} disabled={!hasNext}>
          <ArrowDownward />
        </button>
                Next Lesion
            </div>]}
      {this.state.showLesions && <div className={'button-container'}>
        <button onClick={() => this.setState({ showLesions: false })}>
          <VisibilityOff />
        </button>
                Hide Lesions
            </div>}
      {!this.state.showLesions && <div className={'button-container'}>
        <button onClick={() => this.setState({ showLesions: true })}>
          <Visibility />
        </button>
                Show Lesions
            </div>}
    </div>;
  }

  render() {
    return <div className={`body-container ${this.state.position} ${this.props.controls ? 'with-controls' : ''}`}>
      <div className={`body-side-switcher ${this.state.position}`} onClick={() => this.setState({
        position: this.state.position === 'back' ? 'front' : 'back',
      })}></div>
      {this.props.controls && this.renderControls()}
      {this.state.section ? this.renderSection() : this.renderBodyParts()}
    </div>;
  }
}
