import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IndicatorButton from '../IndicatorButton';
import Validators from '../../utils/Validators';
import { showMessage, showError } from '../../actions/uiActions';
import GalleryApi from '../../api/gallery';
import LinkImageUploader from '../LinkImageUploader';
import ImageZoom from '../ImageZoom';

class ImageGallery extends Component {
  state = {
    images: [],
    from: '',
    to: '',
    searching: false,
    page: 0,
    image: null,
    selection: [],
  }

  componentWillMount() {
    this.load();
  }

  load = (page = 0) => {
    this.setState({ searching: true }, () => GalleryApi.load(page, this.state.from, this.state.to).then((result) => {
      this.setState({ page: result.page, images: result.page === 0 ? result.images : [...this.state.images, ...result.images] });
      if (result.from == '0') result.from = '';
      if (result.to) result.to = '';
      if (result.hasMore && result.from == this.state.from && result.to == this.state.to) this.load(result.page + 1);
      else if (result.from != this.state.from || result.to != this.state.to) this.load();
      else this.setState({ searching: false });
    }, (error) => {
      this.props.showError();
      this.setState({ searching: false });
    }).catch((error) => {
      this.props.showError();
      this.setState({ searching: false });
    }));
  }

  render() {
    return this.state.image ? <ImageZoom create={true} title="Selected Image" image={this.state.image.url} data={this.state.image.data} onClose={(save, data) => {
      if (this.props.multiple) {
        this.setState({
          selection: [...this.state.selection, { ...this.state.image, data: save ? data : null }],
          image: null,
        });
      } else {
        this.props.onImageSelected(this.state.image, data);
      }
    }} /> : <Dialog fullWidth={true} open={true}>
      <DialogTitle>Select Image
        <button className="dialog-close" onClick={this.props.onClose}>Cancel</button>
      </DialogTitle>
      <DialogContent>
        <p className="details">
          Choose a date range to see the images taken with the App within that period. Click on the image to select it.
        </p>
        <div className="row">
          <div className="col-6">
            <label className="little">Date from</label>
            <input type="date" value={this.state.from} onChange={(e) => {
              this.setState({ from: e.target.value }, () => {
                if (!this.state.searching) this.load();
              });
            }} className={'form-control'} placeholder="Date from..." />
          </div>
          <div className="col-6">
            <label className="little">Date to</label>

            <input type="date" value={this.state.query} onChange={(e) => {
              this.setState({ query: e.target.value, searching: true }, () => this.load());
            }} className={'form-control'} placeholder="Date to..." />

          </div>
        </div>
        <div className="row">
          <div className="col-12">

            <div className="search-results large">
              <ul>
                {this.state.images.length === 0 && !this.state.searching && <li className="no-results">No images found</li>}

                {this.state.images.length === 0 && this.state.searching && <li className="no-results">Loading images...</li>}
                {this.state.images.map((image) => <li onClick={() => {
                  if (this.props.multiple && this.state.selection.find((img) => img.id === image.id)) {
                    this.setState({
                      selection: this.state.selection.filter((img) => img.id !== image.id),
                    });
                  } else {
                    this.setState({ image });
                  }
                }} key={image.id} className={`image ${(this.props.multiple && this.state.selection.find((img) => img.id === image.id)) ? 'selected' : ''}`}>
                  <img src={image.thumbnail || image.url} />
                  <div className="date">{image.date}</div>
                </li>)}
              </ul>
              {this.state.images.length > 0 && this.state.searching && <div className="spinner-border text-primary spinner-border-sm loading-indicator" role="status"></div>}
            </div>
            <div className="text-right alt-link">
              <button className="link-button float-left" onClick={() => this.load()}>Refresh</button>
              <LinkImageUploader upload_name="image"
                onUpload={GalleryApi.uploadImage} onChange={(image) => {
                  if (this.props.multiple) {
                    this.setState({
                      images: [...this.state.images, image],
                      image,
                    });
                  } else {
                    this.props.onImageSelected(image);
                    this.setState({ image });
                  }
                }} onError={this.props.showError}>Capture/Upload photo</LinkImageUploader>
            </div>
            {this.props.multiple && this.state.selection.length > 0 && <div className="text-center">
              <button onClick={() => this.props.onImagesSelected(this.state.selection)} className="btn btn-primary">{this.props.actionText || 'Attach Selected Images'}</button>
            </div>}

          </div>
        </div>
      </DialogContent>
    </Dialog>;
  }
}

const mapStateToProps = (state) => {
  return state;
};
ImageGallery.propTypes =
{
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { showMessage, showError })(ImageGallery);
