import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IndicatorButton from '../IndicatorButton';
import Validators from '../../utils/Validators';
import { showMessage, showError } from '../../actions/uiActions';
import PatientsApi from '../../api/patients';

import PatientForm from '../../forms/Patient';

class PatientSelection extends Component {
    state={
      patients: [],
      query: '',
      searching: false,
      mode: 'default',
    }

    search=() => {
      if (!this.state.query) this.setState({ query: '', searching: false, patients: [] });
      else {
        this.setState({ searching: true }, () => PatientsApi.find(this.state.query, this.props.lesions).then((result) => {
          this.setState({ patients: result.patients });
          if (this.state.query && this.state.query != result.query) this.search();
          else this.setState({ searching: false });
        }, (error) => {
          this.props.showError();
          this.setState({ searching: false });
        }).catch((error) => {
          this.props.showError();
          this.setState({ searching: false });
        }));
      }
    }

    render() {
      return ((this.state.mode === 'default' && <Dialog fullWidth={true} open={true}>
        <DialogTitle>Select Patient
        <button className="dialog-close" onClick={this.props.onClose}>Cancel</button>

        </DialogTitle>
        <DialogContent>
        <div className="row">
                    <div className="col-12">
                    <div className="input-group">
                    <input value={this.state.query} onChange={(e) => {
                      this.setState({ query: e.target.value }, () => {
                        if (!this.state.searching) this.setState({ searching: true }, () => this.search());
                      });
                    }} type="text" className={`form-control ${this.state.searching && 'input-borderless'}`} placeholder="Enter patient name..." />
                    {this.state.searching && <div className="input-group-append white">
    <span className="input-group-text"><div className="spinner-border text-primary spinner-border-sm" role="status">
</div></span>
  </div>}
                    </div>
                        <div className="search-results">
                            <ul>

                                {this.state.patients.length === 0 && !this.state.query && !this.state.searching && <li className="no-results">Enter the name of a patient</li>}
                                {this.state.patients.length === 0 && this.state.query && !this.state.searching && <li className="no-results">No patients found</li>}
                                {this.state.patients.length === 0 && this.state.query && this.state.searching && <li className="no-results">Searching...</li>}
                                {this.state.patients.map((patient) => <li key={patient.id} onClick={() => this.props.onPatientSelected(patient)} className="result">
                                    <div className="row">
                                        <div className="col-8">
                                            <label>Patient Name</label>
                                            {patient.name}
                                        </div>
                                        <div className="col-4">
                                            <label>Phone Number</label>
                                            {patient.phone}
                                        </div>
                                    </div>
                                </li>)}

                            </ul>
                        </div>
                        <div className="text-right alt-link">
                            Patient doesn't exist? <button onClick={() => this.setState({ mode: 'register' })} className="link-button">Register a new patient</button>
                        </div>

                    </div>
                </div>
        </DialogContent>
                </Dialog>) || <PatientForm onClose={() => this.setState({ mode: 'default' })} onNewPatient={this.props.onPatientSelected}/>);
    }
}

const mapStateToProps = (state) => {
  return state;
};
PatientSelection.propTypes =
    {
      showMessage: PropTypes.func.isRequired,
      showError: PropTypes.func.isRequired,
    };
export default connect(mapStateToProps, { showMessage, showError })(PatientSelection);
