import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

// import IndicatorButton from '../IndicatorButton';
// import Validators from '../../utils/Validators';
import { showMessage, showError } from '../../actions/uiActions';
import DevicesApi from '../../api/devices';
// import LinkImageUploader from '../LinkImageUploader';
import PaginatedTable from '../PaginatedTable';

class ScansSelection extends Component {
  state = {
    devices: [],
    from: '',
    to: '',
    device: '',
    loading: false,
    page: 0,
    count: 0,
    scans: [],
    searchingString: '',
  }

  componentWillMount() {
    DevicesApi.all().then((devices) => {
      this.setState({ devices });
    }, (error) => { }).catch((error) => { });
    this.load();
  }

  load = (page = 0) => {
    this.setState({ loading: true });
    DevicesApi.load(
      page,
      this.state.from,
      this.state.to,
      this.state.device,
      this.state.searchingString,
    )
      .then(
        (result) => this.setState({
          loading: false,
          scans: result.scans,
          page: result.page,
          count: result.count,
        }),
        (error) => {
          this.setState({
            loading: false,
            scans: [],
            page: 0,
            count: 0,
          });
        }
      )
      .catch((error) => this.setState({
        loading: false,
        scans: [],
        page: 0,
        count: 0,
      }));
  }

  handleSearchInoutKeyDown = ({ key }) => {
    if (key === 'Enter') {
      this.load(this.state.page);
    }
  }

  render() {
    return <Dialog fullWidth={true} maxWidth="md" open={true}>
      <DialogTitle>Select DRN
        <button className="dialog-close" onClick={this.props.onClose}>Cancel</button>
      </DialogTitle>
      <DialogContent>
        <p className="details">
          Select the DRN of the result that your want to associate to the lesion.
        </p>
        <div className="row">
          <div className="col-3 no-padding">
            <label className="little">Date from</label>
            <input type="date" value={this.state.from} onChange={(e) => {
              this.setState({ from: e.target.value }, () => {
                if (!this.state.searching) this.load();
              });
            }} className={'form-control no-padding tiny'} placeholder="Date from..." />
          </div>
          <div className="col-3 no-padding">
            <label className="little">Date to</label>

            <input type="date" value={this.state.query} onChange={(e) => {
              this.setState({ query: e.target.value, searching: true }, () => this.load());
            }} className={'form-control  no-padding tiny'} placeholder="Date to..." />

          </div>
          <StyledTextFieldContainer className="col-3 no-padding">
            <label className="little">Search by DRN</label>

            <input
              value={this.state.searchingString}
              onChange={(ev) => this.setState({ searchingString: ev.target.value })}
              className="form-control"
              onKeyDown={this.handleSearchInoutKeyDown}
            />
          </StyledTextFieldContainer>
          <div className="col-3">
            <label className="little">Device</label>
            <select value={this.state.device} onChange={(e) => this.setState({ device: e.target.value }, () => this.load())} className="form-control">
              <option value="0">All devices...</option>
              {this.state.devices.map((device) => <option key={device.id} value={device.id}>{device.name || device.serial}</option>)}
            </select>
          </div>
        </div>
        <div className="line-break"></div>
        <Paper>
          <PaginatedTable
            loading={this.state.loading}
            columns={[
              { name: 'Date', properties: { key: 1 } },
              { name: 'Device', properties: { key: 2 } },
              { name: 'Result', properties: { key: 3 } },
              { name: 'DRN', properties: { key: 4 } },
            ]}
            rows={this.state.scans.map((scan) => {
              return {
                properties:
                {
                  className: 'clickable-row',
                  key: scan.id,
                  onClick: () => this.props.onScanSelected(scan),
                },
                columns: [
                  {
                    value: scan.date,
                    properties: { key: `${scan.id}-1` },
                  },
                  {
                    value: scan.device_name || scan.device_serial,
                    properties: { key: `${scan.id}-2` },
                  },
                  {
                    value: scan.result,
                    properties: { key: `${scan.id}-3` },
                  },
                  {
                    value: scan.drn,
                    properties: { key: `${scan.id}-4` },
                  },
                ],
              };
            })}
            goToPage={this.load}
            emptyMessage="No scans found"
            count={this.state.count}
            page={this.state.page}
          />
        </Paper>
        <div className="line-break"></div>
        <button className="link-button" onClick={() => this.load()}>Refresh</button>
      </DialogContent>
    </Dialog>;
  }
}

const StyledTextFieldContainer = styled.div`
  input {
    /* width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem; */
  }
`;

const mapStateToProps = (state) => {
  return state;
};
ScansSelection.propTypes =
{
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { showMessage, showError })(ScansSelection);
