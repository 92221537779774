import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AttachFile from '@material-ui/icons/AttachFile';
import moment from 'moment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import IndicatorButton from '../../components/IndicatorButton';
import Validators from '../../utils/Validators';
import { showMessage, showError } from '../../actions/uiActions';
import PatientsApi from '../../api/patients';
import LesionRecord from '../LesionRecord';
import FileUpload from '../../components/FileUpload';
import ScansSelection from '../../components/ScansSelection';
import ImageGallery from '../../components/ImageGallery';
import AutoComplete from '../../components/AutoComplete';
import { printPhotoType } from '../../utils/Formatter';
import TemplateField from '../../components/TemplateField';

class CustomRecord extends Component {
  constructor(props) {
    super(props);
    console.log(props.template.fields);
    this.state =
        {
          busy: false,
          fields: props.template.fields.map((field) => {
            field.value = field.value || '';
            return field;
          }),
        };
  }

  componentWillMount() {
    if (this.props.mode === 'edit' && this.props.record) {
      this.setState({
        evaluation: this.props.record.location,
        diagnosis: this.props.record.diagnosis,
        scan: this.props.record.result,
        photos: this.props.record.photos,
        notes: this.props.record.notes,
        treatment: this.props.record.treatment,
        action: this.props.record.action,
        final_diagnosis: this.props.record.final_diagnosis,
        attachments: this.props.record.attachments,
        biopsy_date: this.props.record.biopsy_date,
        results_date: this.props.record.results > 0 ? moment(this.props.record.results, 'X').format('YYYY-MM-DD') : '',
        tags: this.props.record.tags ? this.props.record.tags.split(',') : [],
        biospy_date: this.props.record.biospy_date,

      });
    }
  }

    displayValue=(display, condition, match, value, fieldValue) => {
      switch (match) {
        case 'eq':
          if (typeof fieldValue === 'object' && typeof value === 'object') {
            return condition === 'and' ? display && !value.find((fValue) => (!fieldValue.find((val) => val == fValue))) : display || !value.find((fValue) => !fieldValue.find((val) => val == fValue));
          }
          return condition === 'and' ? display && value == fieldValue : display || value == fieldValue;
        case 'neq':
          if (typeof fieldValue === 'object' && typeof value === 'object') {
            return condition === 'and' ? display && value.find((fValue) => (!fieldValue.find((val) => val == fValue))) : display || value.find((fValue) => (!fieldValue.find((val) => val == fValue)));
          }
          return condition === 'and' ? display && value != fieldValue : display || value != fieldValue;
        case 'gt':
          return condition === 'and' ? display && value < fieldValue : display || value < fieldValue;
        case 'lt':
          return condition === 'and' ? display && value > fieldValue : display || value > fieldValue;
        case 'contains':
          return condition === 'and' ? display && fieldValue.includes(value) : display || fieldValue.includes(value);
        case 'ncontains':
          return condition === 'and' ? display && !fieldValue.includes(value) : display || !fieldValue.includes(value);
        default:
          return display;
      }
    }

    canShow=(field) => {
      if (!field.settings || !field.settings.rules || field.settings.rules.length === 0) {
        return true;
      }
      let display = true;
      field.settings.rules.forEach((rule) => {
        const ruleField = this.state.fields.find((f) => f.settings.id == rule.field);

        switch (ruleField.type) {
          case 'alpha':
          case 'email':
          case 'phone':
          case 'single-choice':
          case 'multiple-choice':
          case 'fitzpatrick':
          case 'tags':
          case 'number':
          case 'scale':
            display = this.displayValue(display, rule.condition, rule.match, rule.value, ruleField.value);
            break;
          case 'boolean':
            display = this.displayValue(display, rule.condition, rule.match, rule.value.toString().toLowerCase() !== 'no' && rule.value, ruleField.value);
            break;
          case 'date':
            if (ruleField.value) {
              display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'YYYY-MM-DD').format('X'), moment(ruleField.value, 'YYYY-MM-DD').format('X'));
            } else {
              display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'YYYY-MM-DD').format('X'), 0);
            }

            break;
          case 'time':
            if (ruleField.value) {
              display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'HH:mm').format('h:mm A'), moment(ruleField.value, 'HH:mm').format('h:mm A'));
            } else {
              display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'HH:mm').format('h:mm A'), 0);
            }

            break;
          case 'result':
            if (ruleField.value) {
              display = this.displayValue(display, rule.condition, rule.match, rule.value, (ruleField.value && (ruleField.value.result == 1 || ruleField.value.result.toLowerCase().includes('high'))) ? 1 : 0);
            }

            break;
        }
      });
      return display;
    }

    handleInputChange=(e, fieldId) => {
      this.setState({
        fields: this.state.fields.map((field, i) => {
          if (field.id === fieldId) {
            field.value = e.target.value;
          }
          return field;
        }),
      });
    }

    submit=() => {
      let hasErrors = false;
      this.state.fields.filter((field) => this.canShow(field)).forEach((field) => {
        if (hasErrors) {
          return;
        }
        if (field.mandatory && (!field.value || (field.type === 'attachment' || field.type === 'image' || field.type === 'tag' || field.type === 'multiple-choice') && field.value.length === 0)) {
          hasErrors = true;
          this.props.showError(`${field.name} is required`);
        }
      });
      if (!hasErrors) {
        this.setState({ busy: true }, async () => {
          if (this.props.mode === 'create') {
            try {
              const id = await PatientsApi.addCustomLesion(this.props.id, this.props.lesion, this.state.location, this.state.fields);
              this.props.showMessage('The lesion has been registered successfully!');
              this.props.onLesionAdded(id, false);
              this.props.onClose();
            } catch (error) {
              this.setState({ busy: false });
              this.props.showError();
            } finally {
              this.setState({ busy: false });
            }
          } else if (this.props.mode === 'record') {
            try {
              const id = await PatientsApi.addCustomRecord(this.props.lesion.id, this.state.location, this.state.fields);
              this.props.showMessage('The new record has been registered successfully!');
              this.props.onRecordAdded(id, false);
              this.props.onClose();
            } catch (error) {
              this.setState({ busy: false });
              this.props.showError();
            } finally {
              this.setState({ busy: false });
            }
          } else if (this.props.mode === 'edit') {
            try {
              const newRecord = await PatientsApi.updateCustomRecord(this.props.record.id, this.state.fields, this.props.template);
              this.props.showMessage('The clinical record has been updated successfully!');
              this.props.onRecordUpdated({ ...this.props.record, ...newRecord }, false);
              this.props.onClose();
            } catch (error) {
              this.setState({ busy: false });
              this.props.showError();
            } finally {
              this.setState({ busy: false });
            }
          }
        });
      }
    }

    renderESign=(field) => {
      if (!field.link) {
        return this.renderBasicInfoForm(field);
      }
      return <Dialog fullScreen={true} fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">{!this.state.busy && <IconButton className="white-content" onClick={this.props.onClose}><ArrowBack /></IconButton>}
            eSign {field.name}
        </DialogTitle>
        <DialogContent>
            <div className="iframe-container">
                <iframe src={field.link} />
            </div>
        </DialogContent>
        {<DialogActions style={{ display: 'flex', paddingBottom: '30px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <IndicatorButton disabled={this.state.busy} variant="contained" onClick={() => this.verifyEsign(field)} className="btn-material btn-primary" autoFocus>
            Submit Document
          </IndicatorButton>
          {!this.state.busy && <Button variant="outlined" onClick={this.props.onClose} color="primary">
            Cancel
          </Button>}

        </DialogActions>}
        </Dialog>;
    }

    verifyEsign=(field) => {
      this.setState({ busy: true }, async () => {
        try {
          const documentSigned = await PatientsApi.verifyRecordEsignature(field.id, this.state.email, field.document);
          if (!documentSigned) {
            this.props.showError('We could not verify your the patient signature, please make sure the patient has completed and finalized the document, and try again.');
          } else {
            this.setState({
              fields: this.state.fields.map((f) => {
                if (f.id === field.id) {
                  return {
                    ...f,
                    value: field.document,
                  };
                }
                return f;
              }),
            });
          }
        } catch (error) {
          this.props.showError('There has been an error verifying the signature, please try again.');
        } finally {
          return this.setState({ busy: false });
        }
      });
    }

    eSign=(field) => {
      if (!this.state.firstname) {
        return this.props.showError("Please enter the patient's first name");
      } if (!this.state.lastname) {
        return this.props.showError("Please enter the patient's last name");
      } if (!Validators.isValidEmail(this.state.email)) {
        return this.props.showError("Please enter the patient's email address");
      }
      this.setState({ busy: true }, async () => {
        try {
          const data = await PatientsApi.getRecordEsignUrl(field.id, this.state.email, this.state.firstname, this.state.lastname);
          this.setState({
            fields: this.state.fields.map((f) => {
              if (f.id === field.id) {
                return {
                  ...f,
                  ...data,
                };
              }
              return f;
            }),
          });
        } catch (error) {
          this.props.showError('There has been an error retrieving the Document from Pandadoc, please refresh the page and try again.');
        } finally {
          return this.setState({ busy: false });
        }
      });
    }

    renderBasicInfoForm=(field) => {
      return <Dialog fullScreen={true} fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">{!this.state.busy && <IconButton className="white-content" onClick={this.props.onClose}><ArrowBack /></IconButton>} Enter Patient Information

        </DialogTitle>
        <DialogContent>
            <div className="line-break"></div>
        <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-10 offset-1">
                    <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group nice">

                    <label>First Name:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="First Name" name="firstname" value={this.state.firstname} onChange={(e) => this.setState({ firstname: e.target.value })} />

                        </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group nice">
                    <label>Last Name:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="Last Name" name="lastname" value={this.state.lastname} onChange={(e) => this.setState({ lastname: e.target.value })} />

                        </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group nice">
                    <label>Email Address:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="email" className="form-control" placeholder="Email Address" name="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />

                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line-break"></div>
            <div className="text-center">
            <IndicatorButton disabled={this.state.busy} variant="contained" onClick={() => this.eSign(field)} className="btn-material btn-primary" autoFocus>
            eSign {field.name}
          </IndicatorButton>
            </div>
        </DialogContent>
        </Dialog>;
    }

    render() {
      const field = this.state.fields.find((field) => field.type === 'pandadoc' && !field.value);

      if (field) {
        return this.renderESign(field);
      }
      return <Dialog fullWidth={true} open={true}>
        <DialogTitle>{this.props.mode == 'edit' ? `Edit ${this.props.template.name}` : `Add New ${this.props.template.name}`}
        {!this.state.busy && <button className="dialog-close" onClick={this.props.onClose}>Cancel</button>}

        </DialogTitle>
        <div className="row">
                    <div className="col-10 offset-1">
                    <div className="row">
            <div className="col-12">

            {this.state.fields.filter((field) => field.type !== 'pandadoc' && this.canShow(field)).map((field, index) => <div className="row">
                <div className={`col-${(field.type === 'number' || field.type === 'date' || field.type === 'phone') ? '6' : '12'}`}>
                <TemplateField key={`template-field-${index}`} {...field} onChange={(e) => this.handleInputChange(e, field.id)} disabled={this.state.busy} />
                </div>
                </div>)}

                    <div className="line-break"></div>

                    <div className="text-center">
                    {this.props.lesion && !this.props.disabled && <IndicatorButton className="btn-material btn-secondary btn-block" onClick={this.submit} disabled={this.state.busy}>{this.props.mode === 'create' ? 'Register Lesion' : (this.props.mode == 'edit' ? 'Update Record' : 'Add Record')}</IndicatorButton>}
                    <div className="line-break"></div>
                    </div>
            </div>
        </div>

                    </div>
                </div>
                </Dialog>;
    }
}

const mapStateToProps = (state) => {
  return state;
};
CustomRecord.propTypes =
    {
      showMessage: PropTypes.func.isRequired,
      showError: PropTypes.func.isRequired,
    };
export default connect(mapStateToProps, { showMessage, showError })(CustomRecord);
