import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IndicatorButton from '../../components/IndicatorButton';
import Validators from '../../utils/Validators';
import { showMessage, showError } from '../../actions/uiActions';
import PatientsApi from '../../api/patients';
import TemplateField from '../../components/TemplateField';

class Patient extends Component {
    state={
      firstname: '',
      lastname: '',
      phone: '',
      busy: true,
      valid_firstname: true,
      valid_lastname: true,
      valid_phone: true,
      id: null,
      email: '',
      valid_email: true,
      emr: '',
      dob: '',
      valid_dob: true,
      gender: '',
      ethnicity: '',
      skin: '',
      history: false,
      family: false,
      clinic: true,
      defaultFields: {},
      fields: [],
    }

    handleInputChange=(e) => {
      this.setState({ [e.target.name]: e.target
        .value,
      [`valid_${e.target.name}`]: true });
    }

    handleCustomInputChange=(e, fieldId) => {
      this.setState({
        fields: this.state.fields.map((field, i) => {
          if (field.id === fieldId) {
            field.value = e.target.value;
          }
          return field;
        }),
      });
    }

    async componentDidMount() {
      if (this.props.edit && this.props.patient) this.setState({ ...this.props.patient });
      try {
        const settings = await PatientsApi.getProfileTemplate();
        this.setState({ ...settings,
          fields: settings.fields.map((field) => {
            if (!this.state.fields) {
              return field;
            }
            const current = this.state.fields.find((f) => f.id == field.id);
            field.value = current ? current.value : '';
            return field;
          }) }, () => console.log(this.state.fields));
      } catch (error) {

      } finally {
        this.setState({ busy: false });
      }
    }

    displayValue=(display, condition, match, value, fieldValue) => {
      switch (match) {
        case 'eq':
          if (typeof fieldValue === 'object' && typeof value === 'object') {
            return condition === 'and' ? display && !value.find((fValue) => (!fieldValue.find((val) => val == fValue))) : display || !value.find((fValue) => (!fieldValue.find((val) => val == fValue)));
          }
          return condition === 'and' ? display && value == fieldValue : display || value == fieldValue;
        case 'neq':
          if (typeof fieldValue === 'object' && typeof value === 'object') {
            return condition === 'and' ? display && value.find((fValue) => (!fieldValue.find((val) => val == fValue))) : display || value.find((fValue) => (!fieldValue.find((val) => val == fValue)));
          }
          return condition === 'and' ? display && value != fieldValue : display || value != fieldValue;
        case 'gt':
          return condition === 'and' ? display && value < fieldValue : display || value < fieldValue;
        case 'lt':
          return condition === 'and' ? display && value > fieldValue : display || value > fieldValue;
        case 'contains':
          return condition === 'and' ? display && fieldValue.includes(value) : display || fieldValue.includes(value);
        case 'ncontains':
          return condition === 'and' ? display && !fieldValue.includes(value) : display || !fieldValue.includes(value);
        default:
          return display;
      }
    }

    canShow=(field) => {
      if (!field.settings || !field.settings.rules || field.settings.rules.length === 0) {
        return true;
      }
      let display = true;
      field.settings.rules.forEach((rule) => {
        const ruleField = this.state.fields.find((f) => f.settings.id == rule.field);

        switch (ruleField.type) {
          case 'alpha':
          case 'email':
          case 'phone':
          case 'single-choice':
          case 'multiple-choice':
          case 'fitzpatrick':
          case 'tags':
          case 'number':
            display = this.displayValue(display, rule.condition, rule.match, rule.value, ruleField.value);
            break;
          case 'boolean':
            display = this.displayValue(display, rule.condition, rule.match, rule.value.toString().toLowerCase() !== 'no' && rule.value, ruleField.value);
            break;
          case 'date':
            if (ruleField.value) {
              display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'YYYY-MM-DD').format('X'), moment(ruleField.value, 'YYYY-MM-DD').format('X'));
            } else {
              display = this.displayValue(display, rule.condition, rule.match, moment(rule.value, 'YYYY-MM-DD').format('X'), 0);
            }

            break;
          case 'result':
            if (ruleField.value) {
              display = this.displayValue(display, rule.condition, rule.match, rule.value, (ruleField.value && (ruleField.value.result == 1 || ruleField.value.result.toLowerCase().includes('high'))) ? 1 : 0);
            }

            break;
        }
      });
      return display;
    }

    register=() => {
      if (!this.state.firstname) {
        this.setState({ valid_firstname: false });
        this.props.showError('Plase correct the highlighted errors');
      } else if (!this.state.lastname) {
        this.setState({ valid_lastname: false });
        this.props.showError('Plase correct the highlighted errors');
      }
      // else if(this.state.phone&&!Validators.isValidPhone(this.state.phone))
      // {
      //     this.setState({valid_phone:false});
      //     this.props.showError("Plase correct the highlighted errors");
      // }
      else if (this.state.email && !Validators.isValidEmail(this.state.email)) {
        this.setState({ valid_email: false });
        this.props.showError('Plase correct the highlighted errors');
      }
      // else if(this.state.dob&&!Validators.isDateInput(this.state.dob))
      // {
      //     this.setState({valid_dob:false});
      //     this.props.showError("Plase correct the highlighted errors");
      // }
      // ADD validation for custom fields
      else if (this.props.edit) {
        this.setState({ busy: true });
        PatientsApi.update(this.state.id, { fields: this.state.fields, firstname: this.state.firstname, lastname: this.state.lastname, phone: this.state.phone, email: this.state.email, emr: this.state.emr, dob: this.state.dob, skin: this.state.skin, ethnicity: this.state.ethnicity, gender: this.state.gender, history: this.state.history, family: this.state.family, clinic: this.state.clinic }).then((success) => {
          this.props.showMessage('The patient information has been updated successfully.');
          this.props.onUpdate({ id: this.state.id,
            name: `${this.state.firstname} ${this.state.lastname}`,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            phone: this.state.phone,
            email: this.state.email,
            emr: this.state.emr,
            dob: this.state.dob,
            skin: this.state.skin,
            ethnicity: this.state.ethnicity,
            gender: this.state.gender,
            history: this.state.history,
            family: this.state.family,
            clinic: this.state.clinic });
          this.props.onClose();
        }, (error) => {
          console.log(error);
          this.props.showError();
          this.setState({ busy: false });
        }).catch((error) => {
          this.props.showError();
          this.setState({ busy: false });
        });
      } else {
        this.setState({ busy: true });
        PatientsApi.register({ fields: this.state.fields, firstname: this.state.firstname, lastname: this.state.lastname, phone: this.state.phone, email: this.state.email, emr: this.state.emr, dob: this.state.dob, skin: this.state.skin, ethnicity: this.state.ethnicity, gender: this.state.gender, history: this.state.history, family: this.state.family, clinic: this.state.clinic }).then((patientId) => {
          this.props.showMessage('The new patient has been registered.');
          this.props.onNewPatient({ id: patientId, name: `${this.state.firstname} ${this.state.lastname}`, phone: this.state.phone, firstname: this.state.firstname, lastname: this.state.lastname, email: this.state.email, emr: this.state.emr, dob: this.state.dob, skin: this.state.skin, ethnicity: this.state.ethnicity, gender: this.state.gender, history: this.state.history, family: this.state.family, clinic: this.state.clinic });

          this.props.onClose();
        }, (error) => {
          this.props.showMessage('There is a patient registered with that email address already.');
          this.setState({ busy: false });
        }).catch((error) => {
          this.props.showError();
          this.setState({ busy: false });
        });
      }
    }

    renderESign=(field) => {
      if (!field.link) {
        return this.renderBasicInfoForm(field);
      }
      return <Dialog fullScreen={true} fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">{!this.state.busy && <IconButton className="white-content" onClick={this.props.onClose}><ArrowBack /></IconButton>}
            eSign {field.name}
        </DialogTitle>
        <DialogContent>
            <div className="iframe-container">
                <iframe src={field.link} />
            </div>
        </DialogContent>
        {<DialogActions style={{ display: 'flex', paddingBottom: '30px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <IndicatorButton disabled={this.state.busy} variant="contained" onClick={() => this.verifyEsign(field)} className="btn-material btn-primary" autoFocus>
            Submit Document
          </IndicatorButton>
          {!this.state.busy && <Button variant="outlined" onClick={this.props.onClose} color="primary">
            Cancel
          </Button>}

        </DialogActions>}
        </Dialog>;
    }

    verifyEsign=(field) => {
      this.setState({ busy: true }, async () => {
        try {
          const documentSigned = await PatientsApi.verifyEsignature(field.id, this.state.email, field.document);
          if (!documentSigned) {
            this.props.showError('We could not verify your the patient signature, please make sure the patient has completed and finalized the document, and try again.');
          } else {
            this.setState({
              fields: this.state.fields.map((f) => {
                if (f.id === field.id) {
                  return {
                    ...f,
                    value: field.document,
                  };
                }
                return f;
              }),
            });
          }
        } catch (error) {
          this.props.showError('There has been an error verifying the signature, please try again.');
        } finally {
          return this.setState({ busy: false });
        }
      });
    }

    eSign=(field) => {
      this.setState({ busy: true }, async () => {
        try {
          const data = await PatientsApi.getEsignUrl(field.id, this.state.email, this.state.firstname, this.state.lastname);
          this.setState({
            fields: this.state.fields.map((f) => {
              if (f.id === field.id) {
                return {
                  ...f,
                  ...data,
                };
              }
              return f;
            }),
          });
        } catch (error) {
          this.props.showError('There has been an error retrieving the Document from Pandadoc, please refresh the page and try again.');
        } finally {
          return this.setState({ busy: false });
        }
      });
    }

    renderBasicInfoForm=(field) => {
      return <Dialog fullScreen={true} fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">{!this.state.busy && <IconButton className="white-content" onClick={this.props.onClose}><ArrowBack /></IconButton>} eSign {field.name}

        </DialogTitle>
        <DialogContent>
            <div className="line-break"></div>

            <div className="line-break"></div>
            <div className="text-center">
            <IndicatorButton disabled={this.state.busy} variant="contained" onClick={() => this.eSign(field)} className="btn-material btn-primary" autoFocus>
            eSign {field.name}
          </IndicatorButton>
            </div>
        </DialogContent>
        </Dialog>;
    }

    render() {
      const field = this.state.fields.find((field) => field.type === 'pandadoc' && !field.value);

      if (field) {
        return this.renderESign(field);
      }
      return <Dialog fullScreen={true} fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">{!this.state.busy && <IconButton className="white-content" onClick={this.props.onClose}><ArrowBack /></IconButton>} {this.props.edit ? 'Edit Patient' : 'Basic Information Form'}

        </DialogTitle>
        <DialogContent>
            <div className="line-break"></div>
        <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-10 offset-1">
                    <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group nice">

                    <label>First Name:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="First Name" name="firstname" value={this.state.firstname} onChange={this.handleInputChange} />
                            {!this.state.valid_firstname && <div className="input-error animated zoomIn">Enter the patient's first name</div>}
                        </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group nice">
                    <label>Last Name:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="Last Name" name="lastname" value={this.state.lastname} onChange={this.handleInputChange} />
                            {!this.state.valid_lastname && <div className="input-error animated zoomIn">Enter the patient's last name</div>}
                        </div>
                        </div>
                         <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group nice">
                     <label>Email Address:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="email" className="form-control" placeholder="Email Address" name="email" value={this.state.email} onChange={this.handleInputChange} />
                            {!this.state.valid_email && <div className="input-error animated zoomIn">Enter a valid Email Address</div>}
                        </div>
                        </div>
                        {/* {(!this.state.defaultFields.hasOwnProperty("phone")||this.state.defaultFields.phone.enabled)&&<div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group nice">
                    <label>Phone Number:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="tel" className="form-control" placeholder="Phone Number" name="phone" value={this.state.phone} onChange={this.handleInputChange} />
                            {!this.state.valid_phone&&<div className="input-error animated zoomIn">Enter the patient's phone number</div>}
                        </div>
                        </div>} */}
                        </div>
                        {/* <div className="row">

                        {(!this.state.defaultFields.hasOwnProperty("emr")||this.state.defaultFields.emr.enabled)&&<div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="form-group nice">
                    <label>EMR/EHR ID:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="EMR/EHR ID" name="emr" value={this.state.emr} onChange={this.handleInputChange} />

                        </div>
                        </div>}
                        {(!this.state.defaultFields.hasOwnProperty("dob")||this.state.defaultFields.dob.enabled)&&<div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="form-group nice">
                    <label>Date of Birth:</label>
                            <input autoComplete="off" disabled={this.state.busy} type="date" className="form-control" placeholder="Date of Birth" name="dob" value={this.state.dob} onChange={this.handleInputChange} />
                            {!this.state.valid_dob&&<div className="input-error animated zoomIn">Please enter a valid date of birth</div>}
                        </div>
                        </div>}
                        {(!this.state.defaultFields.hasOwnProperty("gender")||this.state.defaultFields.gender.enabled)&&<div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="form-group nice">
                    <label>Gender:</label>
                    <select className="form-control" name="gender" value={this.state.gender} onChange={this.handleInputChange} disabled={this.state.busy}>
                        <option value="">Select...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                        </div>
                        </div>}
                        {(!this.state.defaultFields.hasOwnProperty("skin")||this.state.defaultFields.skin.enabled)&&<div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="form-group nice">
                    <label>Skin Type:</label>
                    <select className="form-control" name="skin" value={this.state.skin} onChange={this.handleInputChange} disabled={this.state.busy}>
                        <option value="">Select...</option>
                        <option value="normal">Normal Skin</option>
                        <option value="dry">Dry Skin</option>
                        <option value="oily">Oily Skin</option>
                        <option value="combination">Combination Skin</option>
                    </select>
                        </div>
                        </div>}
                        {(!this.state.defaultFields.hasOwnProperty("ethnicity")||this.state.defaultFields.ethnicity.enabled)&&<div className="col-xl-8 col-lg-8 col-md-8 col-12">
                        <div className="form-group nice">
                    <label>Ethnicity:</label>
                    <select className="form-control" name="ethnicity" value={this.state.ethnicity} onChange={this.handleInputChange} disabled={this.state.busy}>
                        <option value="">Select...</option>
                        <option value="american">American Indian or Alaska Native</option>
                        <option value="asian">Asian</option>
                        <option value="black">Black or African American</option>
                        <option value="hawaiian">Native Hawaiian or Other Pacific Islander</option>
                        <option value="hispanic">Hispanic or Latino</option>
                        <option value="white">White</option>
                    </select>
                        </div>
                        </div>}
                        </div> */}
                        <div className="row">
                        {/* {(!this.state.defaultFields.hasOwnProperty("personal")||this.state.defaultFields.personal.enabled)&&<div className="col-xl-4 col-lg-4 col-md-6 col-12">
                            <div className="form-group nice">
                                <label>&nbsp;</label>
                                <FormControlLabel
                            control={
                            <Switch
                                checked={this.state.history}
                                onChange={(e)=>this.setState({history:e.target.checked})}
                                value="history"
                                color="primary"
                            />
                            }
                            label="Personal Skin Cancer History"
                        />
                            </div>
                        </div>} */}

                        {/* {(!this.state.defaultFields.hasOwnProperty("family")||this.state.defaultFields.family.enabled)&&<div className="col-xl-4 col-lg-4 col-md-6 col-12">
                            <div className="form-group nice">
                                <label>&nbsp;</label>
                                <FormControlLabel
                            control={
                            <Switch
                                checked={this.state.family}
                                onChange={(e)=>this.setState({family:e.target.checked})}
                                value="family"
                                color="primary"
                            />
                            }
                            label="Family Skin Cancer History"
                        />
                            </div>
                        </div>} */}
                        {/* {(!this.state.defaultFields.hasOwnProperty("source")||this.state.defaultFields.source.enabled)&&<div className="col-xl-4 col-lg-4 col-md-6 col-12">
                            <div className="form-group nice">
                                <label>&nbsp;</label>
                                <FormControlLabel
                            control={
                            <Switch
                                checked={!this.state.clinic}
                                onChange={(e)=>this.setState({clinic:!e.target.checked})}
                                value="clinic"
                                color="primary"
                            />
                            }
                            label="Telemedicine/Online Patient"
                        />
                            </div>
                        </div>} */}
                        </div>

                        <div className="row">
                        {this.state.fields.filter((field) => field.type !== 'pandadoc' && this.canShow(field)).map((field, index) => <div key={`field-${field.id}`} className={`col-${(field.type === 'number' || field.type === 'date' || field.type === 'phone') ? '4' : (field.type === 'result' || field.type === 'image' || field.type === 'attachment' || field.type === 'html') ? '12' : '6'}`}>
                <TemplateField key={`template-field-${field.id}`} {...field} onChange={(e) => this.handleCustomInputChange(e, field.id)} disabled={this.state.busy} />
                </div>)}
                        </div>
                        <div className="line-break"></div>
                        <div className="form-group text-center">
                            <IndicatorButton className="btn-material btn-primary" onClick={this.register} disabled={this.state.busy}>{this.props.edit ? 'Update Patient Info' : 'Submit'}</IndicatorButton>
                        </div>
                    </div>
                </div>
                </DialogContent>
      </Dialog>;
    }
}

const mapStateToProps = (state) => {
  return state;
};
Patient.propTypes =
    {
      showMessage: PropTypes.func.isRequired,
      showError: PropTypes.func.isRequired,
    };
export default connect(mapStateToProps, { showMessage, showError })(Patient);
