import React from 'react';
import './style.css';

const MarkersToggle = (props) => {
  return <div onClick={props.onChange} className={`marker-toggle ${props.checked ? 'checked' : 'unchecked'}`}>
        <span>{props.checked ? props.checkedLabel : props.unCheckedLabel}</span>
    </div>;
};

export default MarkersToggle;
