import React from 'react';

const MiniBody = (props) => <div className="mini-body" onClick={props.onClose}>
    <div className={`head ${props.section === 'head' ? 'active' : ''}`}></div>
    <div className={`neck ${props.section === 'neck' ? 'active' : ''}`}></div>
    <div className={`chest ${props.section === 'chest' ? 'active' : ''}`}></div>
    <div className={`belly ${props.section === 'belly' ? 'active' : ''}`}></div>
    <div className={`waist ${props.section === 'waist' ? 'active' : ''}`}></div>
    <div className={`left-bicep ${props.section === 'left-bicep' ? 'active' : ''}`}></div>
    <div className={`right-bicep ${props.section === 'right-bicep' ? 'active' : ''}`}></div>
    <div className={`left-forearm ${props.section === 'left-forearm' ? 'active' : ''}`}></div>
    <div className={`right-forearm ${props.section === 'right-forearm' ? 'active' : ''}`}></div>
    <div className={`upper-left-leg ${props.section === 'upper-left-leg' ? 'active' : ''}`}></div>
    <div className={`upper-right-leg ${props.section === 'upper-right-leg' ? 'active' : ''}`}></div>
    <div className={`bottom-left-leg ${props.section === 'bottom-left-leg' ? 'active' : ''}`}></div>
    <div className={`bottom-right-leg ${props.section === 'bottom-right-leg' ? 'active' : ''}`}></div>
    <div className={`left-hand ${props.section === 'left-hand' ? 'active' : ''}`}></div>
    <div className={`right-hand ${props.section === 'right-hand' ? 'active' : ''}`}></div>
    <div className={`left-foot ${props.section === 'left-foot' ? 'active' : ''}`}></div>
    <div className={`right-foot ${props.section === 'right-foot' ? 'active' : ''}`}></div>
</div>;
export default MiniBody;
