import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AttachFile from '@material-ui/icons/AttachFile';
import moment from 'moment';
import IndicatorButton from '../../components/IndicatorButton';
import Validators from '../../utils/Validators';
import { showMessage, showError } from '../../actions/uiActions';
import PatientsApi from '../../api/patients';
import LesionRecord from '../LesionRecord';
import FileUpload from '../../components/FileUpload';
import ScansSelection from '../../components/ScansSelection';
import ImageGallery from '../../components/ImageGallery';
import AutoComplete from '../../components/AutoComplete';
import { printPhotoType } from '../../utils/Formatter';
import CustomRecord from '../CustomRecord';

class Lesion extends Component {
  state =
    {
      busy: false,
      location: '',
      valid_location: true,
      evaluation: 'other',
      scan: '',
      diagnosis: '',
      notes: '',
      photos: [],
      mode: 'default',
      attachments: [],
      results_date: '',
      treatment: '',
      action: '',
      final_diagnosis: '',
      tags: [],
      biopsy_date: moment().format('YYYY-MM-DD'),
    }

  componentWillMount() {
    if (this.props.mode === 'edit' && this.props.record) {
      this.setState({
        evaluation: this.props.record.location,
        diagnosis: this.props.record.diagnosis,
        scan: this.props.record.result,
        photos: this.props.record.photos,
        notes: this.props.record.notes,
        treatment: this.props.record.treatment,
        action: this.props.record.action,
        final_diagnosis: this.props.record.final_diagnosis,
        attachments: this.props.record.attachments,
        biopsy_date: this.props.record.biopsy_date,
        results_date: this.props.record.results > 0 ? moment(this.props.record.results, 'X').format('YYYY-MM-DD') : '',
        tags: this.props.record.tags ? this.props.record.tags.split(',') : [],
        biospy_date: this.props.record.biospy_date,

      });
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target
        .value,
      [`valid_${e.target.name}`]: true,
    });
  }

  register = () => {
    if (this.props.mode === 'create' && !this.state.valid_location) {
      this.setState({ valid_location: false });
      this.props.showError('Please correct the highlighted errors.');
    } else if (!this.props.lesion) this.props.showError('You must indicate a lesion.');
    else if (this.props.variant == 'pathology' && !Validators.isDateInput(this.state.biopsy_date)) this.props.showError('Please enter a valid Date of Extraction.');
    else if (this.props.variant == 'pathology' && !Validators.isDateInput(this.state.results_date)) this.props.showError('Please enter a valid results date.');
    else {
      this.setState({ busy: true });
      if (this.props.mode === 'create') {
        PatientsApi.addLesion(this.props.id, this.props.lesion, this.state.location, this.state.evaluation, this.state.scan ? this.state.scan : '', this.state.diagnosis, this.state.photos, this.state.notes, this.state.clinical ? this.state.clinical.data : '', this.state.dermatoscopic ? this.state.dermatoscopic.data : '', this.state.final_diagnosis, this.state.biopsy_date, this.state.results_date, this.state.treatment, this.state.action, this.state.attachments, this.props.variant, this.state.tags).then((id) => {
          this.setState({ busy: false });
          this.props.showMessage('The lesion has been registered successfully!');
          this.props.onLesionAdded(id, this.state.action == 'biopsy' || this.state.action == 'excise');
          this.props.onClose();
        }, (error) => {
          this.setState({ busy: false });
          this.props.showError();
        }).catch((error) => {
          this.setState({ busy: false });
          this.props.showError();
        });
      } else if (this.props.mode === 'record') {
        PatientsApi.addRecord(this.props.lesion.id, this.state.location, this.state.evaluation, this.state.scan ? this.state.scan : '', this.state.diagnosis, this.state.photos, this.state.notes, this.state.final_diagnosis, this.state.biopsy_date, this.state.results_date, this.state.treatment, this.state.action, this.state.attachments, this.props.variant, this.state.tags).then((id) => {
          this.setState({ busy: false });
          this.props.showMessage('The new record has been registered successfully!');
          this.props.onRecordAdded(id, this.state.action == 'biopsy' || this.state.action == 'excise');
          this.props.onClose();
        }, (error) => {
          this.setState({ busy: false });
          this.props.showError();
        }).catch((error) => {
          this.setState({ busy: false });
          this.props.showError();
        });
      } else if (this.props.mode === 'edit') {
        PatientsApi.updateRecord(this.props.record.id, this.state.evaluation, this.state.scan ? this.state.scan : '', this.state.diagnosis, this.state.photos, this.state.notes, this.state.final_diagnosis, this.state.biopsy_date, this.state.results_date, this.state.treatment, this.state.action, this.state.attachments, this.props.variant, this.state.tags).then((newRecord) => {
          this.setState({ busy: false });
          this.props.showMessage('The clinical record has been updated successfully!');
          this.props.onRecordUpdated({ ...this.props.record, ...newRecord }, this.state.action == 'biopsy' || this.state.action == 'excise');
          this.props.onClose();
        }, (error) => {
          this.setState({ busy: false });
          this.props.showError();
        }).catch((error) => {
          this.setState({ busy: false });
          this.props.showError();
        });
      }
    }
  }

  render() {
    if ((this.props.variant === 'custom' && this.props.template) || (this.props.mode === 'edit' && this.props.record.template)) {
      return <CustomRecord {...this.props} />;
    }
    return ((this.state.mode === 'default' && <Dialog fullWidth={true} open={true}>
      <DialogTitle>{this.props.mode == 'create' ? `Register New ${this.props.variant == 'clinic' ? 'Clinical Record' : 'Pathology Record'}` : (this.props.mode == 'edit' ? `Edit ${this.props.variant == 'clinic' ? 'Clinical Record' : (this.props.variant == 'pathology' ? 'Pathology Record' : 'Record')}` : `Add new ${this.props.variant == 'clinic' ? 'Clinical Record' : 'Pathology Record'}`)}
        {!this.state.busy && <button className="dialog-close" onClick={this.props.onClose}>Cancel</button>}

      </DialogTitle>
      <div className="row">
        <div className="col-10 offset-1">
          <div className="row">
            <div className="col-12">
              <div className="row">
                {this.props.mode === 'create' && false && <div className="col-12">
                  <div className="form-group nice">
                    <label>Lesion Location:</label>
                    <input disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} type="text" value={this.state.location} name="location" className="form-control" placeholder="Ex: Left Shoulder" />
                    {!this.state.valid_location && <div className="input-error animated zoomIn">Enter the Lesion Location</div>}
                  </div>
                  <div className="form-group nice">
                    <label className="text-left">Where was patient evaluated?</label>
                    <select disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="evaluation" value={this.state.evaluation} className="form-control">
                      <option value="other">Select...</option>
                      <option value="clinic">Clinic</option>
                      <option value="tele-consult">Tele-Consult</option>
                    </select>
                  </div>
                </div>}
                <div className="col-12">
                  {(this.props.variant == 'all' || this.props.variant == 'clinic') && <div className="form-group nice">
                    <label className="text-left">Initial Impression</label>
                    <input disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="diagnosis" value={this.state.diagnosis} type="text" className="form-control" placeholder="Initial Impression" />
                  </div>}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group nice">
                        <label className="text-left">
                          {(this.props.variant == 'all' || this.props.variant == 'pathology') ? 'Lesion extraction method' : 'Clinical Action/Next Step'}:</label>
                        <select disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="action" value={this.state.action} className="form-control">
                          <option value="other">Select...</option>

                          <option value="biopsy">Biopsy</option>
                          <option value="followup">MOHS</option>
                        </select>
                      </div>
                    </div>
                    {(this.props.variant == 'all' || this.props.variant == 'pathology') && <div className="col-6">
                      <div className="form-group nice">
                        <label className="text-left">Date of extraction:</label>
                        <input type="date" name="biopsy_date" placeholder="Extraction Date" className="form-control" value={this.state.biopsy_date} onChange={this.handleInputChange} />
                      </div>
                    </div>}</div>
                </div>
                <div className="col-12">
                  {(this.props.variant == 'all' || this.props.variant == 'clinic') && <div className="form-group nice">
                    <label className="text-left">Notes/Comments:</label>
                    <textarea disabled={this.props.disabled || this.state.busy} onChange={this.handleInputChange} name="notes" value={this.state.notes} className="form-control" placeholder="Notes/Comments" rows={2}></textarea>
                  </div>}

                </div>
              </div>
              <div className="row">
                {(this.props.variant == 'all' || this.props.variant == 'pathology') && [
                  <div className="col-6">
                    <div className="form-group nice">
                      <label>Results received date:</label>
                      <input onChange={this.handleInputChange} className="form-control" type="date" name="results_date" value={this.state.results_date} disabled={this.state.busy} />
                    </div>
                  </div>,
                  <div className="col-12">
                    <div className="form-group nice">
                      <label>Diagnosis:</label>
                      <textarea onChange={this.handleInputChange} className="form-control" name="final_diagnosis" value={this.state.final_diagnosis} disabled={this.state.busy} placeholder="Diagnosis" rows={2} />
                    </div>
                  </div>, <div className="col-12"><div className="form-group nice">
                    <AutoComplete editable={true} label="Record Tags" placeholder="Enter the Record Tags (optional)" onSelection={(tag) => this.setState({ tags: [...this.state.tags, tag] })} autocomplete={PatientsApi.getRecordsTags} onDelete={(tag) => this.setState({ tags: this.state.tags.filter((item) => item != tag) })} value={this.state.tags} />
                  </div></div>]}
              </div>
              {(this.props.variant == 'all' || this.props.variant == 'clinic') && <div className="row">
                <div className="col-12">
                  <div className="form-group nice">
                    <h6 className="text-left">DermaSensor Device Results</h6>
                    {!this.state.scan && <button disabled={this.state.busy} onClick={() => this.setState({ mode: 'scan' })} className="btn btn-block btn-branded">Use device</button>}
                    {this.state.scan && <div className="dotted-container">
                      <div className="row">
                        <div className="col-6">
                          <label>Date taken</label>
                          {this.state.scan.date}
                        </div>
                        <div className="col-3">
                          <label>Result</label>
                          {this.state.scan.result}
                        </div>
                        <div className="col-3">
                          <label>DRN</label>
                          {this.state.scan.drn}
                          {!this.state.busy && <button className="delete float-right" onClick={() => this.setState({ scan: null })}>x</button>}
                        </div>

                      </div>
                    </div>}
                  </div>
                </div>

                <div className="col-12">
                  <h6 className="text-left">Lesion Photos</h6>
                  {this.state.photos.map((photo) => <div key={`photo-${photo.id}`} className="dotted-container" style={{ marginBottom: '10px' }}>
                    <div className="row">
                      <div className="col-3">

                        <img className="btn-image-preview" src={photo.thumbnail || photo.url} alt="Preview" />
                      </div>
                      <div className="col-6">{printPhotoType(photo.data.type)} <b>({(photo.exists && photo.date) || 'Today'})</b>
                      </div>
                      <div className="col-3 text-center">
                        {!this.state.busy && <button className="delete" onClick={() => {
                          this.setState({
                            photos: this.state.photos.filter((p) => p.id !== photo.id),
                          });
                        }}>x</button>}
                      </div>
                    </div>
                  </div>)}
                  <button disabled={this.state.busy} onClick={() => this.setState({ mode: 'image' })} className="btn btn-block btn-branded">Select Photos</button>

                </div>
              </div>}
              {(this.props.variant == 'all' || this.props.variant == 'pathology') && <div className="row">
                <div className="col-12">
                  <h6 className="text-left">Attachments</h6>
                  <div className="row">
                    {this.state.attachments.map((attachment, index) => <div key={`attachment-${index}`} className="col-6">
                      <div className="fileupload-button disabled file">
                        <div className="row">
                          <div className="col-2">
                            <AttachFile />
                          </div>
                          <div className="col-8 file-name">
                            {attachment.file_name}
                          </div>
                          <div className="col-2">
                            <button onClick={() => this.setState({ attachments: this.state.attachments.filter((a) => a.file != attachment.file) })}>X</button>
                          </div>
                        </div>

                      </div>
                    </div>)}
                    <div className=" col-6">
                      <FileUpload name="file" onError={this.props.showError} onUpload={PatientsApi.uploadAttachments} onChange={(file) => this.setState({ attachments: [...this.state.attachments, file] })} label="Select file..." />
                    </div>
                  </div>

                </div>
              </div>}

              <div className="line-break"></div>

              <div className="text-center">
                {this.props.lesion && !this.props.disabled && <IndicatorButton className="btn-material btn-secondary btn-block" onClick={this.register} disabled={this.state.busy}>{this.props.mode === 'create' ? 'Register Lesion' : (this.props.mode == 'edit' ? 'Update Record' : 'Add Record')}</IndicatorButton>}
                <div className="line-break"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Dialog>) || ((this.state.mode === 'image' || this.state.mode === 'imaged') && <ImageGallery multiple={true} onImagesSelected={(images) => {
      this.setState({
        photos: [...this.state.photos, ...images],
        mode: 'default',
      });
    }} onClose={() => this.setState({ mode: 'default' })} />) || (this.state.mode === 'scan' && <ScansSelection onClose={() => this.setState({ mode: 'default' })} onScanSelected={(scan) => this.setState({ mode: 'default', scan })} />));
  }
}

const mapStateToProps = (state) => {
  return state;
};
Lesion.propTypes =
{
  showMessage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { showMessage, showError })(Lesion);
