import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ReactDOM from 'react-dom';
import Delete from '@material-ui/icons/Delete';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';

class LinkImageUploader extends Component {
    state=
    {
      uploading: false,
    };

    openFileDialog =() => {
      if (!this.state.uploading) {
        const fileInputDom = ReactDOM.findDOMNode(this.refs.fileUpload);
        fileInputDom.click();
      }
    }

      handleFile=(event) => {
        if (event.target.files && event.target.files.length) {
          this.setState({ uploading: true });

          const formData = new FormData();
          formData.append(this.props.upload_name ? this.props.upload_name : this.props.name, event.target.files[0], event.target.files[0].name);

          this.props.onUpload(formData).then((uploadedFile) => {
            this.setState({ uploading: false });
            this.props.onChange(uploadedFile);
          }, (error) => {
            this.setState({ uploading: false });
            this.props.onError();
          }).catch((error) => {
            this.setState({ uploading: false });
            this.props.onError();
          });
        }
      }

      render() {
        return (
            <div>
                {!this.state.uploading && <div><button onClick={this.openFileDialog} className="link-button">{this.props.children || 'Select image...'}</button><input
                type='file'
                accept="image/*"
                multiple={this.props.multi}
                ref='fileUpload'
                style={{ display: 'none' }}
                onChange={this.handleFile} /></div>}
                {this.state.uploading && <div className="spinner-border text-primary spinner-border-sm loading-indicator" role="status"></div>}
            </div>
        );
      }
}

export default LinkImageUploader;
