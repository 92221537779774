import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';

import ArrowBack from '@material-ui/icons/ArrowBack';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { IconButton, Paper, Table, TableHead, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Visibility } from '@material-ui/icons';
import IndicatorButton from '../../components/IndicatorButton';
import Validators from '../../utils/Validators';
import { showMessage, showError, confirmAction } from '../../actions/uiActions';
import PatientsApi from '../../api/patients';
import TemplatsAPI from '../../api/templates';
import { TEMPLATE_FIELD_TYPES, RULES_ELIGIBLE_FIELD_TYPES } from '../../utils/data';
import PandadocSettings from '../PandadocSettings';
import TemplateFieldRules from '../../components/TemplateFieldRules';

import HTMLTextBlock from '../HTMLTextBlock';

class Template extends Component {
    state={
      template: '',
      busy: false,
      fields: [
        {
          id: Date.now(),
          name: '',
          type: '',
          mandatory: false,
          new: true,
          options: '',
          settings: {
            id: Date.now(),
          },
        },
      ],
      pandadocField: -1,
      htmlField: -1,
      rules: null,
    }

    handleInputChange=(e) => {
      this.setState({ [e.target.name]: e.target
        .value });
    }

    componentDidMount() {
      if (this.props.edit) {
        this.setState({ busy: true }, async () => {
          try {
            const template = await TemplatsAPI.get(this.props.template.id);
            this.setState({
              template: template.name,
              fields: [...template.fields.map((field) => {
                field.settings = field.settings || { id: field.id };
                return field;
              }), {
                id: Date.now(),
                name: '',
                type: '',
                mandatory: false,
                new: true,
                options: '',
                settings: {
                  id: Date.now(),
                },
              }],
              busy: false,
            });
          } catch (error) {
            this.props.showError();
            return this.props.onClose();
          }
        });
      } else if (this.props.copy) {
        this.setState({ busy: true }, async () => {
          try {
            const template = await TemplatsAPI.get(this.props.copy.id);
            this.setState({
              template: `${template.name} (Copy)`,
              fields: [...template.fields.map((field) => {
                field.settings = field.settings || { id: field.id };
                return field;
              }), {
                id: Date.now(),
                name: '',
                type: '',
                mandatory: false,
                new: true,
                options: '',
                settings: {
                  id: Date.now(),
                },
              }],
              busy: false,
            });
          } catch (error) {
            this.props.showError();
            return this.props.onClose();
          }
        });
      }
    }

    submit=(confirm = true) => {
      if (!this.state.template) {
        return this.props.showError('Plase enter the name of the template');
      } if (this.state.fields.length === 1) {
        return this.props.showError('A record template must have at least one field.');
      }
      let hasErrors = false;

      this.state.fields.forEach((field, index) => {
        if (!hasErrors && index < (this.state.fields.length - 1)) {
          if (!field.name) {
            this.props.showError(`Please enter the name of the field #${index + 1}`);
            hasErrors = true;
          } else if (!field.type) {
            this.props.showError(`Please select the type of the field #${index + 1}`);
            hasErrors = true;
          } else if ((field.type === 'single-choice' || field.type === 'multiple-choice') && field.options.split(',').filter((val) => !!val).length === 0) {
            this.props.showError(`Please enter comma-separated options for field #${index + 1}`);
            hasErrors = true;
          } else if (field.type === 'scale' && (isNaN(field.settings.min) || isNaN(field.settings.max) || field.settings.min == field.settings.max)) {
            this.props.showError(`Please enter minimum and maximum values for the number scale field #${index + 1}`);
            hasErrors = true;
          }
        }
      });

      if (!hasErrors && this.props.edit) {
        if (confirm && this.props.template.records > 0) {
          return this.props.confirmAction('You are editing a template that has records already, this might lead to data loss or corruption of existing patient records. Do you want to proceed?', () => this.submit(false), 'Confirm Template Update', 'Confirm');
        }
        this.setState({ busy: true }, async () => {
          try {
            await TemplatsAPI.updateTemplate(this.props.template.id, this.state.template, this.state.fields.slice(0, this.state.fields.length - 1));
            this.props.showMessage('The record template has been updated successfully.');
            this.props.onNewTemplate(this.props.template.id);
          } catch (error) {
            this.setState({ busy: false }, () => {
              switch (error.toString()) {
                case '409':
                  return this.props.showError('There is already a record template with the same name.');
                default:
                  return this.props.showError();
              }
            });
          }
        });
      } else if (!hasErrors) {
        this.setState({ busy: true }, async () => {
          try {
            const newId = await TemplatsAPI.createTemplate(this.state.template, this.state.fields.slice(0, this.state.fields.length - 1));
            this.props.showMessage('The new record template has been created successfully.');
            this.props.onNewTemplate(newId);
          } catch (error) {
            this.setState({ busy: false }, () => {
              switch (error.toString()) {
                case '409':
                  return this.props.showError('There is already a record template with the same name.');
                default:
                  return this.props.showError();
              }
            });
          }
        });
      }
    }

    handleFieldInputChange=(index, fieldName, value) => {
      if (value === 'pandadoc') {
        return this.setState({ pandadocField: index });
      } if (value === 'html') {
        return this.setState({ htmlField: index });
      }
      const fields = this.state.fields.map((field, i) => {
        if (i === index) {
          field[fieldName] = value;
        }
        return field;
      });
      if (index === (this.state.fields.length - 1)) {
        fields.push({
          id: Date.now(),
          name: '',
          type: '',
          mandatory: false,
          new: true,
          options: '',
          settings: {
            id: Date.now(),
          },
        });
      }
      this.setState({ fields });
    }

    handleHtmlSettings=(html) => {
      const fields = this.state.fields.map((field, i) => {
        if (i === this.state.htmlField) {
          return {
            ...field,
            type: 'html',
            settings: {
              ...field.settings,
              html,
            },
          };
        }
        return field;
      });
      if (this.state.htmlField === (this.state.fields.length - 1)) {
        fields.push({
          id: Date.now(),
          name: '',
          type: '',
          mandatory: false,
          new: true,
          options: '',
          settings: {
            id: Date.now(),
          },
        });
      }
      this.setState({ fields, htmlField: -1 });
    }

    handlePandadocSettings=(settings) => {
      const fields = this.state.fields.map((field, i) => {
        if (i === this.state.pandadocField) {
          return {
            ...field,
            type: 'pandadoc',
            ...settings,
            settings: {
              ...field.settings,
              ...settings,
            },
          };
        }
        return field;
      });
      if (this.state.pandadocField === (this.state.fields.length - 1)) {
        fields.push({
          id: Date.now(),
          name: '',
          type: '',
          mandatory: false,
          new: true,
          options: '',
          settings: {
            id: Date.now(),
          },
        });
      }
      this.setState({ fields, pandadocField: -1 });
    }

    handleFieldSettingsChange=(index, setting, value) => {
      this.setState({
        fields: this.state.fields.map((field, i) => {
          if (i === index) {
            field.settings[setting] = value;
          }
          return field;
        }),
      });
    }

    swapUp=(index) => {
      const tmp = { ...this.state.fields[index - 1] };
      this.setState({
        fields: this.state.fields.map((field, i) => {
          if (i === (index - 1)) {
            return { ...this.state.fields[index] };
          } if (i === index) {
            return tmp;
          }
          return field;
        }),
      });
    }

    swapDown=(index) => {
      const tmp = { ...this.state.fields[index] };
      this.setState({
        fields: this.state.fields.map((field, i) => {
          if (i === (index + 1)) {
            return tmp;
          } if (i === index) {
            return { ...this.state.fields[index + 1] };
          }
          return field;
        }),
      });
    }

    setRules=(field) => {
      if (!field.name) {
        return this.props.showError('Please enter the name of the field.');
      } if (this.getValidFields(field.id).length === 0) {
        return this.props.showError('You must have at least another valid named field.');
      }
      return this.setState({ rules: field });
    }

    getValidFields=(id) => {
      const result = this.state.fields.filter((field) => field.id !== id && field.name && RULES_ELIGIBLE_FIELD_TYPES.includes(field.type) && (field.type !== 'single-choice' || field.type !== 'multiple-choice' || Boolean(field.options)));
      return result;
    };

    render() {
      return <><Dialog fullScreen={true} fullWidth={true} open={true}>
        <DialogTitle id="alert-dialog-title">{!this.state.busy && <IconButton className="white-content" onClick={this.props.onClose}><ArrowBack /></IconButton>} {this.props.edit ? 'Edit Record Template' : 'Create new Record Template'}

        </DialogTitle>
        <DialogContent>
        {this.state.pandadocField !== -1 && <PandadocSettings onCancel={() => this.setState({ pandadocField: -1 })} onClose={() => this.setState({ pandadocField: -1 })} onConfirm={this.handlePandadocSettings} {...this.state.fields[this.state.pandadocField].settings} {...this.state.fields[this.state.pandadocField]} />}
        {this.state.htmlField !== -1 && <HTMLTextBlock onCancel={() => this.setState({ htmlField: -1 })} onClose={() => this.setState({ htmlField: -1 })} {...this.state.fields[this.state.htmlField].settings} onConfirm={this.handleHtmlSettings} />}
            <div className="line-break"></div>
        <div className="row">
                    <div className="col-8 offset-2">
                    <div className="form-group nice">
                    <label>Template Name:</label>
                            <input style={{ textTransform: 'capitalize' }} autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="Template Name" name="template" value={this.state.template} onChange={this.handleInputChange} />

                        </div>
                        </div>
                        <div className="col-8 offset-2">
                            <h5>Template Fields</h5>
                        <Paper>
                            <Table className="light">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Field Name
                                        </TableCell>
                                        <TableCell>
                                            Field Type
                                        </TableCell>
                                        <TableCell>
                                            Mandatory
                                        </TableCell>
                                        <TableCell>
                                           &nbsp;
                                        </TableCell>
                                        <TableCell>
                                           &nbsp;
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.fields.map((field, index) => <TableRow key={`template-field-${index}`}>
                                        <TableCell>
                                        <input value={field.name} autoComplete="off" disabled={this.state.busy} type="text" className="form-control" placeholder="Field Name" name="name" onChange={(e) => this.handleFieldInputChange(index, 'name', e.target.value)} />
                                        </TableCell>
                                        <TableCell>
                                        <select disabled={this.state.busy} value={field.type} className="form-control" onChange={(e) => this.handleFieldInputChange(index, 'type', e.target.value)}>
                                        <option value="">Select...</option>
                                        {Object.keys(TEMPLATE_FIELD_TYPES).map((key, index) => <option value={key} key={`new-field-type-${index}`}>{TEMPLATE_FIELD_TYPES[key]}</option>)}
                                        </select>
                                        {(field.type === 'single-choice' || field.type === 'multiple-choice') && <input value={field.options} autoComplete="off" disabled={this.state.busy} type="text" className="form-control inline" placeholder="Comma-separated options" name="options" onChange={(e) => this.handleFieldInputChange(index, 'options', e.target.value)} />}
                                        {field.type === 'scale' && <div className="row" style={{ marginTop: '10px' }}>
                                            <div className="col-6">
                                                <input type="number" value={field.settings.min} className="form-control" placeholder="Min. Value" onChange={(e) => this.handleFieldSettingsChange(index, 'min', e.target.value)} />
                                            </div>
                                            <div className="col-6">
                                                <input type="number" value={field.settings.max} className="form-control" placeholder="Max. Value" onChange={(e) => this.handleFieldSettingsChange(index, 'max', e.target.value)} />
                                            </div>
                                        </div>}
                                        </TableCell>
                                        <TableCell>
                                        <Switch
                                checked={field.mandatory}
                                onChange={(e) => this.handleFieldInputChange(index, 'mandatory', e.target.checked)}
                                color="primary"
                            />
                                        </TableCell>
                                        <TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        {index !== (this.state.fields.length - 1) && <Tooltip title="Set visibility rules"><IconButton onClick={() => this.setRules(field)}><Visibility /></IconButton></Tooltip>}
                                            {index !== (this.state.fields.length - 1) && <IconButton className="basic-button" onClick={() => this.setState({ fields: this.state.fields.filter((f, i) => i !== index) })}><Delete /></IconButton>}
                                            {field.type === 'pandadoc' && <Tooltip title="Edit Pandadoc Settings"><IconButton className="basic-button" onClick={() => this.setState({ pandadocField: index })}><Edit /></IconButton></Tooltip>}
                                            {field.type === 'html' && <Tooltip title="Edit HTML Text block"><IconButton onClick={() => this.setState({ htmlField: index })}><Edit /></IconButton></Tooltip>}
                                        </TableCell>
                                        <TableCell>
                                           <div style={{ display: 'flex', flexDirection: 'column' }}>
                                           {index !== (this.state.fields.length - 1) && index > 0 && <Tooltip placement="top" title="Move up">
                                           <IconButton onClick={() => this.swapUp(index)} className="basic-button" disableFocusRipple={true} disableRipple={true}>
                                               <KeyboardArrowUp />
                                           </IconButton>
                                           </Tooltip>}
                                           {index < (this.state.fields.length - 2) && this.state.fields.length >= 3 && <Tooltip title="Move down">
                                           <IconButton onClick={() => this.swapDown(index)} className="basic-button" disableFocusRipple={true} disableRipple={true}>
                                               <KeyboardArrowDown />
                                           </IconButton>
                                           </Tooltip>}
                                           </div>
                                        </TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </Paper>

                        <div className="line-break"></div>
                        <div className="form-group text-center">
                            <IndicatorButton className="btn-material btn-primary" onClick={this.submit} disabled={this.state.busy}>{this.props.edit ? 'Update Template' : 'Save Template'}</IndicatorButton>
                        </div>
                    </div>
                </div>
                </DialogContent>
      </Dialog>{this.state.rules && <TemplateFieldRules onCancel={() => this.setState({ rules: null })} field={this.state.rules} fields={this.getValidFields(this.state.rules.id)} onSubmit={(rules) => {
        this.setState({
          fields: this.state.fields.map((field) => {
            if (field.id === this.state.rules.id) {
              field.settings = {
                ...field.settings,
                rules,
              };
            }
            return field;
          }),
          rules: null,
        });
      }} />}</>;
    }
}

const mapStateToProps = (state) => {
  return state;
};
Template.propTypes =
    {
      showMessage: PropTypes.func.isRequired,
      showError: PropTypes.func.isRequired,
    };
export default connect(mapStateToProps, { showMessage, showError, confirmAction })(Template);
