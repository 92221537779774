import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Auth from '../../api/auth';
import { authenticate, logout, verifyAuthentication } from '../../actions/authActions';
import ApplicationLoaderComponent from '../../components/ApplicationLoader';

class UsersComponent extends Component {
  state =
    {
      authenticated: false,
      error: false,
    }

  componentWillMount() {
    const settings = this.props.location.pathname.split('/');
    if (settings.length != 3 || !settings[2]) {
      this.props.logout();
    } else {
      Auth.signApp().then((success) => this.props.authenticate(settings[2]), (error) => { }).catch((error) => { });
    }
  }

  render() {
    return <div>{(this.state.authenticated && <Redirect to='/dashboard' />) || (!this.state.authenticated && <ApplicationLoaderComponent />)}</div>;
  }
}
const mapStateToProps = (state) => {
  return state;
};
UsersComponent.propTypes =
{
  authenticate: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  verifyAuthentication: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { authenticate, logout, verifyAuthentication })(UsersComponent);
