import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store, { history } from './store';
import './assets/css/bootstrap.min.css';
import RootContainer from './containers/RootContainer';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer />
      </Provider>
    );
  }
}

export default App;
