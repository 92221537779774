import React, { Component } from 'react';
import Slider from '@material-ui/lab/Slider';
import { isMobile } from 'react-device-detect';

const half = (size, percent = 25) => {
  return Math.floor(size / 2) - Math.floor((size * (percent / 100)) / 2);
};
const zoomDot = (dot, zoom, backgroundPosition, backgroundSize) => {
  let xDistance = dot.originX - dot.backgroundPosition.left;
  if (dot.zoom > 0) xDistance = (xDistance * 100) / (100 + (dot.zoom * 10));
  xDistance = xDistance * (100 + (zoom * 10)) / 100;
  dot.x = backgroundPosition.left + xDistance;
  let yDistance = dot.originY - dot.backgroundPosition.top;
  if (dot.zoom > 0) yDistance = (yDistance * 100) / (100 + (dot.zoom * 10));
  yDistance = yDistance * (100 + (zoom * 10)) / 100;
  dot.y = backgroundPosition.top + yDistance;
  return dot;
};
export default class LesionMap extends Component {
    moving=false;

    prevPosition={
      x: 0,
      y: 0,
    }

    state={
      zoom: 0,
      position: 'front',
      mapSize: {
        width: Math.floor(this.props.size * 0.30),
        height: this.props.size,
      },
      backgroundPosition: {
        left: half(this.props.size, 30),
        top: 0,
      },
      dot: {
        top: 0,
        left: 0,
      },
      down: false,
      dots: [],
      lastTouch: 0,
    }

    getEventObject=(event) => {
      return event.touches && event.touches.length > 0 ? { ...event, clientX: event.touches[0].clientX, clientY: event.touches[0].clientY } : event;
    }

    zoom=(event, value) => {
      if (this.props.disabled) return;
      const newPercent = (100 + (value * 10)) / 100;
      const newSize = {
        width: Math.floor(Math.floor(this.props.size * 0.30) * newPercent),
        height: Math.floor(this.props.size * newPercent),
      };

      const changeX = Math.floor(((this.state.mapSize.width - newSize.width) / 2));
      const changeY = this.state.mapSize.height - newSize.height;

      const newPosition = {
        left: this.state.backgroundPosition.left + changeX,
        top: this.state.backgroundPosition.top + Math.floor(changeY / 2),
      };
      this.setState({
        zoom: value,
        mapSize: newSize,
        backgroundPosition: newPosition,
        dots: this.state.dots.map((dot) => zoomDot(dot, value, newPosition)),

      });
    }

    down=(e) => {
      this.moving = true;
      const event = this.getEventObject(e);
      this.prevPosition = {
        x: event.clientX,
        y: event.clientY,
      };
    }

    up=(event) => {
      this.moving = false;
    }

    onPointerMove=(e) => {
      const event = this.getEventObject(e);
      if (this.moving && !this.props.disabled) {
        const x = event.clientX - this.prevPosition.x;
        const y = event.clientY - this.prevPosition.y;
        this.prevPosition = { x: event.clientX, y: event.clientY };

        this.setState({
          backgroundPosition: {
            left: this.state.backgroundPosition.left + x,
            top: this.state.backgroundPosition.top + y,
          },
          dots: this.state.dots.map((dot) => {
            dot.x += x;
            dot.y += y;
            return dot;
          }) });
      }
    }

    dot=(e) => {
      if (!this.props.disabled) {
        const event = this.getEventObject(e);
        const boundingRectangle = event.target.getBoundingClientRect();
        const dot = { x: event.clientX - boundingRectangle.left,
          y: event.clientY - boundingRectangle.top,
          boundaries: {
            left: boundingRectangle.left,
            top: boundingRectangle.top,
          },
          originX: event.clientX - boundingRectangle.left,
          originY: event.clientY - boundingRectangle.top,
          backgroundPosition: this.state.backgroundPosition,
          zoom: this.state.zoom,
          backgroundSize: this.state.mapSize,
          position: this.state.position,
          id: Date.now() };
        if (!this.props.viewOnly) this.setState({ dots: this.props.multiple ? [...this.state.dots, dot] : [dot] });
        this.props.onLesionAdded(dot);
      }
    }

    stopPropagation=(event) => {
      event.stopPropagation();
    }

    translateDot=(dot) => {
      const zoomedDot = zoomDot(dot, this.state.zoom, this.state.backgroundPosition, this.state.backgroundSize);
      return zoomedDot;
    }

    showDot=(dot) => {
      this.setState({
        mapSize: dot.backgroundSize,
        backgroundPosition: dot.backgroundPosition,
        zoom: dot.zoom,
      });
      this.props.onLesionSelected(dot);
    }

    getDot=() => {
      if (this.props.lesion) {
        const dot = this.translateDot(this.props.lesion);
        return dot.x >= 0 && dot.x <= this.props.size && dot.y >= 0 && dot.y <= this.props.size ? <div onClick={() => this.showDot(dot)} key={`${dot.x}-${dot.y}`} className={'lesion active'} style={{ left: dot.x - 5, top: dot.y - 5 }}>{(this.state.dots.length + (this.props.lesions ? this.props.lesions.length : 0)) + 1}</div> : '';
      }
      return '';
    }

    touchStart=(e) => {
      if (isMobile) {
        this.down(e);
        if ((Date.now() - this.state.lastTouch) < 300) {
          this.setState({ lastTouch: Date.now(), lastTouchEvent: e });
          this.dot(this.state.lastTouchEvent);
        } else this.setState({ lastTouch: Date.now(), lastTouchEvent: e });
      }
    }

    render() {
      return <div className="lesion-map" style={{ width: this.props.size, height: this.props.size }}>
        <div className="links">
            <button onClick={() => this.setState({ position: 'front' })} className={this.state.position === 'front' ? 'active' : ''}>Front</button>
            <button onClick={() => this.setState({ position: 'back' })} className={this.state.position === 'back' ? 'active' : ''}>Back</button>
        </div>
            <div ref="siluette" onTouchStart={this.touchStart} onDoubleClick={(e) => {
              if (!isMobile) this.dot(e);
            }} onPointerDown={(e) => {
              if (!isMobile) this.down(e);
            }} onPointerLeave={(e) => {
              if (!isMobile) this.up(e);
            }} onTouchEnd={(e) => {
              if (isMobile) this.up(e);
            }} onTouchCancel={(e) => {
              if (isMobile) this.up(e);
            }} onPointerUp={(e) => {
              if (!isMobile) this.up(e);
            }} onPointerMove={(e) => {
              if (!isMobile) this.onPointerMove(e);
            }} onTouchMove={(e) => {
              if (isMobile) this.onPointerMove(e);
            }} className="map" style={{ backgroundSize: `${this.state.mapSize.width}px ${this.state.mapSize.height}px`, backgroundPosition: `${this.state.backgroundPosition.left}px ${this.state.backgroundPosition.top}px` }}>
            {this.getDot()}
                {this.props.lesions && this.props.lesions.filter((dot) => dot.position === this.state.position).map((dot, index) => {
                  dot = this.translateDot(dot);
                  return dot.x >= 0 && dot.x <= this.props.size && dot.y >= 0 && dot.y <= this.props.size ? <div onClick={() => this.showDot(dot)} key={`${dot.x}-${dot.y}`} className={`lesion ${dot.id == this.props.activeDot ? 'active' : ''}`} style={{ left: dot.x - 5, top: dot.y - 5 }}>{index + 1}</div> : '';
                })}

                {this.state.dots.filter((dot) => dot.position === this.state.position).map((dot, index) => {
                  return dot.x >= 0 && dot.x <= this.props.size && dot.y >= 0 && dot.y <= this.props.size ? <div key={`${dot.x}-${dot.y}`} className={'lesion active'} style={{ left: dot.x - 5, top: dot.y - 5 }}>{(this.props.lesions ? this.props.lesions.length : 0) + index + 1}</div> : '';
                })}
            <div className="zoom" onDoubleClick={this.stopPropagation} onPointerDown={this.stopPropagation} onPointerLeave={this.stopPropagation} onPointerUp={this.stopPropagation} onPointerMove={this.stopPropagation}>
            <Slider disabled={this.props.disabled} onChange={this.zoom} step={1} value={this.state.zoom} />
            </div>
            </div>
        </div>;
    }
}
