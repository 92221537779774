import axios from 'axios';
import Config from '../config/env';

const _API = Config.get('api_endpoint');

const getHeaders = () => {
  const headers = {};
  if (localStorage.getItem('dscore-signature')) headers.Signature = localStorage.getItem('dscore-signature');
  if (localStorage.getItem('dscore-token')) headers.Authorization = localStorage.getItem('dscore-token');
  if (localStorage.getItem('dscore-id')) headers.Account = localStorage.getItem('dscore-id');
  return { headers };
};

export default
{
  download(endpoint) {
    return axios.get(`${_API + endpoint}`, { ...getHeaders(), responseType: 'blob' });
  },
  get(endpoint) {
    return axios.get(`${_API + endpoint}`, getHeaders());
  },
  post(endpoint, params = {}) {
    return axios.post(`${_API + endpoint}`, params, getHeaders());
  },
  put(endpoint, params = {}) {
    return axios.put(`${_API + endpoint}`, params, getHeaders());
  },
  delete(endpoint) {
    return axios.delete(`${_API + endpoint}`, getHeaders());
  },
  patch(endpoint, params = {}) {
    return axios.patch(`${_API + endpoint}`, params, getHeaders());
  },
  uploadFormData(endpoint, formData) {
    return axios.post(`${_API + endpoint}`, formData, getHeaders({ 'Content-Type': 'multipart/form-data' }));
  },
};
