import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import Flag from '@material-ui/icons/Flag';
import LocationOn from '@material-ui/icons/LocationOn';
import CheckCircle from '@material-ui/icons/CheckCircle';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Place from '@material-ui/icons/Place';
import Cancel from '@material-ui/icons/Cancel';
import Navigation from '@material-ui/icons/Navigation';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { confirmAction, showError } from '../../actions/uiActions';
import MarkersToggler from '../MarkersToggle';
import ImageViewer from '../ImageViewer';

const zoomContainer = React.createRef();
const imageContainer = React.createRef();
class ImageZoom extends Component {
    state={
      controls: true,
      tool: 'flag',
      color: 'red',
      markers: [],
      x: 0,
      y: 0,
      time: Date.now(),
      activeMarker: 0,
      note: '',
      write: false,
      type: 'clinical',
    }

    componentDidMount() {
      if (this.props.data) {
        this.setState({ markers: this.props.data.markers || [], type: this.props.data.type || 'clinical' });
      }
    }

    dropMarker=(e) => {
      if (this.state.controls) {
        const xPercent = Math.floor(((e.nativeEvent.offsetX - 30) * 100) / zoomContainer.current.clientWidth);
        const yPercent = Math.floor(((e.nativeEvent.offsetY - 30) * 100) / zoomContainer.current.clientHeight);
        this.setState({ markers: [...this.state.markers, {
          x: xPercent,
          y: yPercent,
          id: Date.now(),
          tool: this.state.tool,
          color: this.state.color,
          note: '',
        }],
        activeMarker: this.state.markers.length,
        write: false,
        note: '' });
      }
    }

    addNote=() => {
      if (this.state.note.length === 0) this.props.showError('Please write a note');
      else {
        this.setState({ write: false,
          note: '',
          markers: this.state.markers.map((marker, index) => {
            if (index === this.state.activeMarker) marker.note = this.state.note;
            return marker;
          }) });
      }
    }

    render() {
      console.log(this.props);
      return <Dialog
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description" open={true}
    fullScreen
    >
    <DialogTitle id="alert-dialog-title"><IconButton className="white-content" onClick={() => {
      if ((this.state.markers.length > 0 && !this.props.data) || JSON.stringify(this.state.markers) !== JSON.stringify(this.props.data)) this.props.confirmAction('Do you want to save the changes made to the image?', () => this.props.onClose(true, this.state.markers), 'Confirm Changes', 'Save Changes', 'Discard Changes', () => this.props.onClose(false));
      else this.props.onClose(false);
    }}><ArrowBack /></IconButton>

    {!this.props.create && this.props.title}</DialogTitle>
    <DialogContent className="text-center">
        {this.state.write && <div className="note-taker-container">
            <div className="box animated fadeIn">
                <h1>Add a Note for the new marker</h1>
                <textarea className="form-control" rows={4} placeholder="Write a note for the new marker (Max. 200 characters)" onChange={(e) => {
                  if (e.target.value.length <= 200) this.setState({ note: e.target.value });
                }}>
                </textarea>
                <div className="actions-container">
                <Button onClick={() => this.setState({ write: false, note: '' })} className="left-button">Cancel</Button>
                <Button className="right-button" variant="outlined" onClick={this.addNote} color="primary">Save Note</Button>
                </div>
            </div>
        </div>}
        <div ref={zoomContainer} onClick={this.dropMarker} className={`zoom-image-container ${this.state.time} ${this.state.controls ? 'active' : ''}`}>

            {this.state.controls && this.state.markers.map((marker, i) => [<div onClick={(e) => {
              e.stopPropagation();
              this.setState({ markers: this.state.markers.filter((m) => m.id != marker.id), activeMarker: this.state.activeMarker > i ? this.state.activeMarker - 1 : this.state.activeMarker });
            }} key={`marker-${i}`} className={`marker ${marker.color} ${this.state.activeMarker === i ? 'active' : 'active'}`} style={{ position: 'absolute', left: `${marker.x}%`, top: `${marker.y}%`, zIndex: 1 }}>

            {marker.tool == 'flag' && <Flag />}
            {marker.tool == 'place' && <Place />}
            {marker.tool == 'arrow' && <ArrowForward />}
            {marker.tool == 'navigation' && <Navigation />}

            </div>, <div className="note" style={{ position: 'absolute', display: this.state.activeMarker === i && marker.note ? 'block' : 'none', left: `${marker.x - 25}%`, top: `${marker.y + 15}%`, zIndex: 1 }}>{marker.note}</div>])}
            <ImageViewer disabled={this.state.controls} ref={imageContainer} src={this.props.image} />
        </div>
        <div className="image-toolbar">
            <ul>
                <li className="control" style={{ paddingTop: '15px' }}>
                <MarkersToggler checked={this.state.controls} onChange={(e) => this.setState({ controls: !this.state.controls })} checkedLabel="Markers Enabled" unCheckedLabel="Markers Disabled" />
                </li>
        {/* <li className={this.state.controls&&this.state.tool=='flag'?"active":"inactive"}>
                <IconButton onClick={()=>{
                    if(this.state.controls)
                        this.setState({tool:"flag"});
                }}><Flag /></IconButton>
                </li>
                <li className={this.state.controls&&this.state.tool=='arrow'?"active":"inactive"}>
                <IconButton onClick={()=>{
                    if(this.state.controls)
                        this.setState({tool:"arrow"});
                }}><ArrowForward /></IconButton>
            </li> */}
                {/* <li className={this.state.controls&&this.state.tool=='place'?"active":"inactive"}>
                <IconButton onClick={()=>{
                    if(this.state.controls)
                        this.setState({tool:"place"});
                }}><Place /></IconButton>
            </li> */}
                {/* <li className={this.state.controls&&this.state.tool=='navigation'?"active":"inactive"}>
                <IconButton onClick={()=>{
                    if(this.state.controls)
                        this.setState({tool:"navigation"});
                }}><Navigation /></IconButton>
            </li> */}
                {/* <li className={`first-color color-container ${this.state.controls&&this.state.color=='red'?'selected':''}`} onClick={()=>{
                    if(this.state.controls)
                        this.setState({color:'red'});
                }}>
                    <div className="color red"></div>
            </li> */}
                {/* <li className={`color-container ${this.state.controls&&this.state.color=='blue'?'selected':''}`} onClick={()=>{
                    if(this.state.controls)
                        this.setState({color:'blue'});
                }}>
                    <div className="color blue"></div>
            </li> */}
                {/* <li className={`color-container ${this.state.controls&&this.state.color=='green'?'selected':''}`} onClick={()=>{
                    if(this.state.controls)
                        this.setState({color:'green'});
                }}>
                    <div className="color green"></div>
            </li>
            {this.state.markers.length>1&&[<li className="control right">
            <IconButton onClick={()=>{
                    this.setState({activeMarker:this.state.activeMarker<(this.state.markers.length-1)?this.state.activeMarker+1:0});
                }}><KeyboardArrowRight /></IconButton>
            </li>,
            <li className="control right">
            <IconButton onClick={()=>{
                    this.setState({activeMarker:this.state.activeMarker>0?this.state.activeMarker-1:this.state.markers.length-1});
                }}><KeyboardArrowLeft /></IconButton>
            </li>]} */}
            <li className="tool-description">

            {/* <select className="form-control" value={this.state.type} onChange={e=>this.setState({type:e.target.value})}>
            <option value="clinical">Clinical Photo</option>
            <option value="dermatoscopic">Dermatoscopic Photo</option>
            <option value="distance">Distance Photo</option>
        </select> */}
            </li>
            <li className="controls right">
        <button className="btn btn-green" onClick={() => this.props.onClose(true, { markers: this.state.markers, type: this.state.type })}>{this.props.create ? 'Select Image' : 'Update Image'}</button>
    {this.props.create && <IconButton className="white-content" onClick={() => this.props.onClose(false)}><Cancel /></IconButton>}
            </li>
            </ul>
            <div className="disclaimer">
    When markers are enabled, you will view existing markers and can click anywhere on the image to place a marker. You remove existing markers by clicking on them. Use the Toggle button to Enable/Disable markers.
    </div>
        </div>

    </DialogContent>
    </Dialog>;
    }
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, { confirmAction, showError })(ImageZoom);
